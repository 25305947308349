import loadable from '@loadable/component';
import { toast } from 'react-toastify';
// @ts-ignore
import { clearCache } from 'clear-cache';
import i18n from 'i18next';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import i18next from 'i18next';

import { FEATURES } from '@finmap/core-constants';
import { timeout } from '@finmap/core-utils';
import { Typography } from '@material-ui/core';

import { AnalyticRoutes, Routes, SettingsRoutes } from '../../constants/routes';
import useTimePeriods from '../../hooks/useTimePeriods';
import useUnleash from '../../hooks/useUnleash';
import IndexScene from '../../scenes/IndexScene';
import SuccessPayment from '../../scenes/SuccessPayment';
import { getLanguage } from '../../selectors/main';
import Http from '../../services/Http';
import Storages, { StorageKey } from '../../services/Storages';
import historyActions from '../../store/analytic/history.actions';
import authActions from '../../store/auth/action';
import { getUser } from '../../store/auth/selectors';
import commonActions from '../../store/common/actions';
import {
  getCompletedIntegrationSync,
  getSyncFirstIntegration,
  getSyncIntegrations,
  getSyncIntegrationsCount,
  getUserGeoData,
  getVersionUI,
} from '../../store/common/selectors';
import companyActions from '../../store/company/actions';
import { getCompany } from '../../store/company/selectors';
import filtersActions from '../../store/filters/actions';
import { initialJournalFilters } from '../../store/filters/initial';
import invoicingActions from '../../store/invoicing/actions';
import { checkAllowLanguage } from '../../utils/getCurrentLanguage';
import componentLoader from '../../utils/lazyLoad';
import { useStyles } from './styles';
import belvoApi from '../../services/bankIntegrations/belvo';
import nordigenApi from '../../services/bankIntegrations/nordigen';
import { COMPLETE_INTEGRATION_TOAST_ID } from '../../constants';
import { IntegrationTypeId } from '../../store/integrations/types';
import BelvoAccountsDialog from '../../foundation/AccountsDialog/BelvoAccountsDialog';

const RouteComponent = Route as any;
const RedirectComponent = Redirect as any;
const SwitchComponent = Switch as any;

const AuthLayout: any = loadable(() =>
  // @ts-ignore
  componentLoader(() => import('./auth'), 3),
);

const MainLayout: any = loadable(() =>
  // @ts-ignore
  componentLoader(() => import('./main'), 3),
);

function Auth(props: Record<string, any>) {
  return <AuthLayout {...props} />;
}

function Main() {
  return <MainLayout />;
}
function SuccessPaymentPage() {
  return <SuccessPayment />;
}

function Layout() {
  const classes = useStyles();
  const { search } = useLocation();
  const history = useHistory();

  const useChronosdecision = useUnleash(FEATURES.USE_CHRONOS_DECISION);

  const [getUserGeoDataInProgress, setGetUserGeoDataInProgress] =
    useState(true);

  const user = useSelector(getUser);
  const company = useSelector(getCompany);
  const versionUI = useSelector(getVersionUI);
  const language = useSelector(getLanguage);
  const userGeoData = useSelector(getUserGeoData);
  const syncIntegrationsCount = useSelector(getSyncIntegrationsCount);
  const syncIntegrations = useSelector(getSyncIntegrations);
  const syncFirstIntegration = useSelector(getSyncFirstIntegration);
  const completedBelvoIntegrations = useSelector(getCompletedIntegrationSync);

  const [showBelvoDialog, setShowBelvoDialog] = useState(false);

  const dispatch = useDispatch();
  const { initialDefaultPeriod } = useTimePeriods();

  const localVersion = Storages.get(StorageKey.version);

  if (versionUI && localVersion !== versionUI) {
    Storages.put(StorageKey.version, versionUI);

    clearCache();
  }

  const isDemoCompany = window.location.hostname.includes('preview');

  const getDemoData = useCallback(async () => {
    const currentLangIso =
      window.location.hostname.split('.').shift()?.split('-').pop() ?? 'UA';

    const { data } = await Http.makeInstance().post('registration/demo', {
      currentLangIso,
    });

    const payload = {
      data: { _id: data.data._id },
      token: data.token,
    };

    dispatch(authActions.updateDemoProfile(payload));

    history.push(AnalyticRoutes.CASH_FLOW);
  }, [dispatch, history]);

  const handleConfirmIntegration = useCallback(async () => {
    dispatch(commonActions.updateSyncFirstIntegration(false));
    const integrationStatus = completedBelvoIntegrations[0];
    if (!integrationStatus) return;
    if (integrationStatus.integrationTypeId === IntegrationTypeId.Belvo) {
      await belvoApi.confirmIntegration(integrationStatus.integrationId);
      dispatch(
        commonActions.updateCompletedIntegrations({
          data: completedBelvoIntegrations.filter(
            (el: { integrationId: string }) =>
              el.integrationId !== integrationStatus.integrationId,
          ),
          integrationTypeId: IntegrationTypeId.Belvo,
        }),
      );
      const allSavedIntegrations = Storages.get(StorageKey.belvoContinue);
      if (!allSavedIntegrations) return;
      const currentIntegration = allSavedIntegrations.find(
        (el: { integrationId: string }) =>
          el.integrationId === integrationStatus.integrationId,
      );
      if (!currentIntegration) return;
      if (allSavedIntegrations.length === 1) {
        Storages.remove(StorageKey.belvoContinue);
        dispatch(commonActions.setSyncIntegrations(false));
      } else {
        Storages.put(
          StorageKey.belvoContinue,
          allSavedIntegrations.filter(
            (el: { integrationId: string }) =>
              el.integrationId !== integrationStatus.integrationId,
          ),
        );
      }
      if (integrationStatus.status === 'failed') return;

      Storages.put(StorageKey.belvo, { ...currentIntegration, continue: true });
      setShowBelvoDialog(true);
    }
  }, [completedBelvoIntegrations, dispatch]);

  const handleCloseBelvoDialog = useCallback(() => {
    Storages.remove(StorageKey.belvo);
    setShowBelvoDialog(false);
  }, []);

  useEffect(() => {
    if (useChronosdecision) {
      (function () {
        const scriptTag = document.createElement('script');

        scriptTag.src =
          'https://plugin.chronosdecision.store/assets/chat-plugin.js';

        scriptTag.onload = function () {
          window.chatPlugin.init({
            clientId: '64086438687a50ba66b3909e',
            personId: `${user?._id}_${company?._id}`,
            personConfig: {
              companyId: company?._id,
            },
          });
        };

        document.body.appendChild(scriptTag);
      })();
    }
  }, [company?._id, user?._id, useChronosdecision]);

  useEffect(() => {
    if (userGeoData) {
      setGetUserGeoDataInProgress(false);

      return;
    }

    const checkUserGeoData = async () => {
      // @ts-ignore
      const { value } = await dispatch(commonActions.getUserLocationData());

      dispatch(commonActions.setUserGeoData(value));

      setGetUserGeoDataInProgress(false);
    };

    try {
      checkUserGeoData();
    } catch (e) {
      console.log(e);

      setGetUserGeoDataInProgress(false);
    }
  }, [dispatch, userGeoData]);

  useEffect(() => {
    dispatch(invoicingActions.setPeriod(initialDefaultPeriod));
    dispatch(
      filtersActions.setPeriod({
        ...initialJournalFilters,
        selector: 'journal',
      }),
    );
    dispatch(
      filtersActions.setPeriod({
        ...initialDefaultPeriod,
        selector: 'cashFlow',
      }),
    );
    dispatch(
      filtersActions.setPeriod({
        ...initialDefaultPeriod,
        selector: 'tableCashFlow',
      }),
    );
    dispatch(
      filtersActions.setPeriod({
        ...initialDefaultPeriod,
        selector: 'profitAndLoss',
      }),
    );
    dispatch(
      filtersActions.setPeriod({
        ...initialDefaultPeriod,
        selector: 'tableProfitAndLoss',
      }),
    );
    dispatch(
      filtersActions.setPeriod({
        ...initialDefaultPeriod,
        selector: 'projects',
      }),
    );
    dispatch(
      filtersActions.setPeriod({
        ...initialDefaultPeriod,
        selector: 'statement',
      }),
    );
    dispatch(historyActions.setPeriod(initialDefaultPeriod));
  }, [initialDefaultPeriod, dispatch]);

  useEffect(() => {
    if (search) {
      const params = new URLSearchParams(search);
      const lng = params.get('lng');

      if (lng) {
        const lang = checkAllowLanguage(lng);

        Storages.put(StorageKey.language, lang);

        i18n.changeLanguage(lang);

        dispatch(companyActions.setLanguage({ lng: lang }));
      }
    }
  }, [search, dispatch]);

  useEffect(() => {
    (async () => {
      const syncNordigenIntegrations = Storages.get(
        StorageKey.nordigenContinue,
      );
      if (!syncNordigenIntegrations || !syncNordigenIntegrations.length) return;
      try {
        const {
          data: { statuses },
        } = await nordigenApi.getIntegrationStatuses();
        const completedIntegrations = statuses.filter(
          (el) => el.status !== 'in progress',
        );
        if (completedIntegrations.length) {
          dispatch(
            commonActions.updateCompletedIntegrations({
              data: completedIntegrations,
              integrationTypeId: IntegrationTypeId.Nordigen,
            }),
          );
        }
      } catch (e) {
        console.log(e);
        Storages.remove(StorageKey.nordigenContinue);
        return;
      }
    })();
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      if (!syncIntegrations) return;
      try {
        const {
          data: { statuses },
        } = await belvoApi.getIntegrationStatuses();
        const completedIntegrations = statuses.filter(
          (el) => el.status !== 'in progress',
        );
        if (completedIntegrations.length) {
          dispatch(
            commonActions.updateCompletedIntegrations({
              data: completedIntegrations,
              integrationTypeId: IntegrationTypeId.Belvo,
            }),
          );
        }
        if (completedIntegrations.length === statuses.length) {
          dispatch(commonActions.setSyncIntegrations(false));
          return;
        }
      } catch (e) {
        console.log(e);
        Storages.remove(StorageKey.belvoContinue);
        return;
      }
      await timeout(1000 * 120); // 2m
      dispatch(
        commonActions.setSyncIntegrationsCount(syncIntegrationsCount + 1),
      ); // recursive
    })();
  }, [syncIntegrations, syncIntegrationsCount, dispatch]);

  useEffect(() => {
    if (syncFirstIntegration && completedBelvoIntegrations.length) {
      toast(
        () => (
          <Typography onClick={handleConfirmIntegration}>
            {i18next.t('integrations.belvo.continueIntegrationToaster')}
          </Typography>
        ),
        {
          toastId: COMPLETE_INTEGRATION_TOAST_ID,
          autoClose: false,
          hideProgressBar: true,
        },
      );
    }
  }, [
    syncFirstIntegration,
    completedBelvoIntegrations,
    handleConfirmIntegration,
  ]);

  if (isDemoCompany && !user) {
    getDemoData();

    return null;
  }

  if (getUserGeoDataInProgress) {
    return null;
  }

  return (
    <div className={classes.root}>
      <SwitchComponent>
        <RouteComponent exact path="/" component={IndexScene} />
        <RouteComponent path="/auth" component={Auth} />
        <RouteComponent path={Routes.LOG} component={Main} />
        <RouteComponent path={AnalyticRoutes.ANALYTIC} component={Main} />
        <RouteComponent path={Routes.EMPLOYEE} component={Main} />
        <RouteComponent path={Routes.CALENDAR} component={Main} />
        <RouteComponent path={Routes.ACCESS_DENIED} component={Main} />
        <RouteComponent path={Routes.FAQ} component={Main} />
        <RouteComponent path={Routes.ON_BOARDING} component={Main} />
        <RouteComponent path={Routes.INVOICING} component={Main} />
        <RouteComponent path={Routes.AUTO_RULES} component={Main} />
        <RouteComponent path={SettingsRoutes.SETTINGS} component={Main} />
        <RouteComponent
          path={Routes.SUCCESS_PAYMENT}
          component={SuccessPaymentPage}
        />
        <RouteComponent
          exact
          path="/public-offer"
          // @ts-ignore
          render={() => {
            window.location.href = 'finmap-public-offers-v1.html';
          }}
        />
        <RouteComponent
          exact
          path="/privacy-notice"
          // @ts-ignore
          render={() => {
            window.location.href = 'finmap-privacy-notice-v1.html';
          }}
        />
        <RouteComponent
          exact
          path="/public-offer-cis"
          // @ts-ignore
          render={() => {
            window.location.href = 'finmap-public-offers-cis-v1.html';
          }}
        />

        {/* REDIRECTS */}
        <RedirectComponent
          from="/login"
          to={{ pathname: `/auth/login/${language}`, search }}
        />
        <RedirectComponent
          from="/registration"
          to={{ pathname: `/auth/register/${language}`, search }}
        />
        <RedirectComponent
          from="/forgot_password"
          to={{ pathname: `${Routes.FORGOT}/${language}`, search }}
        />
        <RedirectComponent to={{ pathname: '/', search }} />
      </SwitchComponent>
      {showBelvoDialog && (
        <BelvoAccountsDialog
          onCloseAccountsListDialog={handleCloseBelvoDialog}
        />
      )}
    </div>
  );
}

export default React.memo(Layout);
