import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  Config,
} from '@finmap/import-parsers/base-import-parser-v3';

export class FakturowniaParser extends BaseImportParserV3 {
  // public debug: boolean = true;
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.PDF]: [
      {
        caseOptions: {
          defaultCurrency: 'EUR',
          isCurCase: [
            [
              0,
              {
                eq: 'Cena netto',
              },
            ],
          ],
          preParserConfigs: {
            verticalAlign: 'top',
            prepareRawConfig: {
              findHeader: {
                from: [
                  {
                    word: {
                      eq: 'LP',
                    },
                    nextWord: {
                      eq: 'numer',
                    },
                  },
                ],
                to: [
                  {
                    word: {
                      eq: 'P',
                    },
                    prevWord: {
                      eq: 'Waluta',
                    },
                  },
                ],
              },
              delete: [
                {
                  to: [
                    {
                      word: {
                        eq: 'LP',
                      },
                      nextWord: {
                        eq: 'numer',
                      },
                    },
                  ],
                  count: 1,
                },
                {
                  from: [
                    {
                      word: {
                        eq: 'faktury online - www.fakturownia.pl',
                      },
                    },
                  ],
                  count: 1,
                },
              ],
              define: [
                {
                  dateFormat: 'YYYY-MM-DD',
                },
              ],
            },
          },
          proceedCaseConfig: {
            fields: {
              date: {
                column: ['Termin\npłatności', 6],
                dateFormat: 'YYYY-MM-DD',
              },
              dateOfPayment: {
                column: ['Data\nsprzedaż\ny', 5],
                dateFormat: 'YYYY-MM-DD',
              },
              comment: {
                column: ['numer', 1],
                add: [
                  ' ',
                  {
                    column: ['Nabywca', 8],
                  },
                ],
              },
              sum: {
                column: ['Cena\nbrutto', 11],
              },
              counterparty: {
                column: ['Nabywca', 7]
              }

            },
          },
        },
      },
      {
        caseOptions: {
          defaultCurrency: 'EUR',
          preParserConfigs: {
            verticalAlign: 'top',
            prepareRawConfig: {
              findHeader: {
                from: [
                  {
                    word: {
                      eq: 'LP',
                    },
                    nextWord: {
                      eq: 'numer',
                    },
                  },
                ],
                to: [
                  {
                    word: {
                      eq: 'ć',
                    },
                    prevWord: {
                      eq: 'brutto',
                    },
                  },
                ],
              },
              delete: [
                {
                  to: [
                    {
                      word: {
                        eq: 'LP',
                      },
                      nextWord: {
                        eq: 'numer',
                      },
                    },
                  ],
                  count: 1,
                },
                {
                  from: [
                    {
                      word: {
                        eq: 'faktury online - www.fakturownia.pl',
                      },
                    },
                  ],
                  count: 1,
                },
              ],
              define: [
                {
                  dateFormat: 'YYYY-MM-DD',
                },
              ],
            },
          },
          proceedCaseConfig: {
            fields: {
              date: {
                column: ['Termin\npłatności', 6],
                dateFormat: 'YYYY-MM-DD',
              },
              dateOfPayment: {
                column: ['Data\nsprzedaż\ny', 5],
                dateFormat: 'YYYY-MM-DD',
              },
              comment: {
                column: ['numer', 1],
                add: [
                  ' ',
                  {
                    column: ['Nabywca', 8],
                  },
                ],
              },
              sum: {
                column: ['Cena\nbrutto', 11],
              },
            counterparty: {
                column: ['Nabywca', 7]
              }
            },
          },
        },
      },
    ],
  };
}
