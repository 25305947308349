import { AxiosResponse } from 'axios';
import {
  IntegrationTypeId,
  NordigenAccount,
  NordigenCredentials,
  NordigenCredentialsPayload,
} from '../../store/integrations/types';
import Http from '../Http';

const getAllBanks = (countryCode: string) =>
  Http.makeInstance().get(
    `integrations/nordigenApiCredentials/banks/${countryCode}`,
  );

const getIntegrationAccounts = (integrationId: string) =>
  Http.makeInstance().get(
    `integrations/nordigenApiCredentials/accounts/${integrationId}`,
  );

const getCredentials = (data: NordigenCredentials) =>
  Http.makeInstance().post('integrations/nordigenApiCredentials', { ...data });

const updateCredentials = (data: NordigenCredentialsPayload) =>
  Http.makeInstance().patch('integrations/nordigenApiCredentials', { ...data });

const confirmIntegration = (
  integrationId: string,
): Promise<
  AxiosResponse<{
    statuses: {
      integrationId: string;
      status: string;
      savedData?: NordigenAccount[];
      integrationTypeId: IntegrationTypeId;
    }[];
  }>
> =>
  Http.makeInstance().patch(`integrations/nordigenApiCredentials/confirm`, {
    integrationId,
  });

const getIntegrationStatuses = (): Promise<
  AxiosResponse<{
    statuses: {
      integrationId: string;
      status: string;
      savedData?: NordigenAccount[];
      integrationTypeId: IntegrationTypeId;
    }[];
  }>
> => Http.makeInstance().get(`integrations/nordigenApiCredentials/status`);

export default {
  getAllBanks,
  getCredentials,
  updateCredentials,
  getIntegrationAccounts,
  getIntegrationStatuses,
  confirmIntegration,
};
