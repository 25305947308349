import { AxiosResponse } from 'axios';
import {
  BelvoAccount,
  BelvoCredentials,
  BelvoCredentialsPayload,
  BelvoIntegrationId,
  BelvoLinkPayload,
  BelvoRedirectData,
  BelvoRedirectDataResp,
  IntegrationTypeId,
} from '../../store/integrations/types';
import Http from '../Http';

const getAllBanks = (countryCode: string) =>
  Http.makeInstance().get(
    `integrations/belvoApiCredentials/banks/${countryCode}`,
  );

const getIntegrationAccounts = (
  integrationId: string,
): Promise<AxiosResponse<BelvoAccount[]>> =>
  Http.makeInstance().get(
    `integrations/belvoApiCredentials/accounts/${integrationId}`,
  );

const getRedirectLink = (
  data: BelvoRedirectData,
): Promise<AxiosResponse<BelvoRedirectDataResp>> =>
  Http.makeInstance().post('integrations/belvoApiCredentials/getlink', {
    ...data,
  });

const getCredentials = (
  data: BelvoCredentials,
): Promise<AxiosResponse<BelvoIntegrationId>> =>
  Http.makeInstance().post('integrations/belvoApiCredentials', { ...data });

const updateLink = (data: BelvoLinkPayload) =>
  Http.makeInstance().patch('integrations/belvoApiCredentials/link', {
    ...data,
  });

const updateCredentials = (data: BelvoCredentialsPayload) =>
  Http.makeInstance().patch('integrations/belvoApiCredentials', { ...data });

const getIntegrationStatuses = (): Promise<
  AxiosResponse<{
    statuses: {
      integrationId: string;
      status: string;
      integrationTypeId: IntegrationTypeId;
    }[];
  }>
> => Http.makeInstance().get(`integrations/belvoApiCredentials/status`);

const confirmIntegration = (
  integrationId: string,
): Promise<
  AxiosResponse<{
    statuses: {
      integrationId: string;
      status: string;
      integrationTypeId: IntegrationTypeId;
    }[];
  }>
> =>
  Http.makeInstance().patch(`integrations/belvoApiCredentials/confirm`, {
    integrationId,
  });

export default {
  getAllBanks,
  getCredentials,
  updateCredentials,
  getIntegrationAccounts,
  getRedirectLink,
  updateLink,
  getIntegrationStatuses,
  confirmIntegration,
};
