import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  Config,
  ImportResultItemMask,
} from '@finmap/import-parsers/base-import-parser-v3';

export class WiseBusinessImportParser extends BaseImportParserV3 {
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.CSV]: [
      {
        caseOptions: {
          isCurCase: [[0, { in: 'Date started (UTC)' }]],
          defaultCurrency: 'USD',
          withoutEmpty: true,
          preParserConfigs: {
            CSVDelimiter: ',',
            simpleParse: true,
          },
          proceedCaseConfig: {
            fields: {
              date: {
                column: ['Date completed (UTC)', 1],
                dateFormat: 'YYYY-MM-DD',
              },
              comment: {
                column: ['Description', 5],
              },
              sum: {
                column: ['Total amount', 15],
              },
              counterparty: {
                column: ['Payer', 7],
              },
            },
          },
        },
      },
      {
        caseOptions: {
          defaultCurrency: 'USD',
          preParserConfigs: { CSVDelimiter: ',' },
          isCurCase: [[0, { eq: 'TransferWise ID' }]],
          proceedCaseConfig: {
            fields: {
              date: { column: ['Date', 1] },
              sum: { column: ['Amount', 2] },
              currency: { column: ['Currency', 3] },
              comment: { column: ['Description', 4] },
              counterparty: {
                or: [
                  { column: ['Payer Name', 10] },
                  { column: ['Payee Name', 11] },
                ],
              },
            },
          },
        },
      },
    ],
  };
}
