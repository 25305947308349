import iconv from 'iconv-lite';
import _, { isArray } from 'lodash';

import { TranslationService } from '@finmap/core-translations';
import { isEmptyImportField } from '@finmap/core-utils';

import { AVAILABLE_IMPORT_TYPES } from './base-import-parser-v3.const';
import {
  AccountsOrAccount,
  CaseConfig,
  CaseOptions,
  Config,
  searchFunc,
} from './base-import-parser-v3.dto';
import { ImportOperation, ImportResultItem } from './import-operation';
import {
  ImportOperationMask,
  ImportResultItemMask,
} from './import-operation-mask';
import { BaseCSVPreParser } from './preparsers/base-csv-preparser';
import { BaseImportPreParser } from './preparsers/base-import-preparser';
import { BasePDFPreParser } from './preparsers/base-pdf-preparser';
import { BaseXLSXPreParser } from './preparsers/base-xlsx-preparser';
import { isNotEmpty } from 'class-validator';
import moment from 'moment';

export const PREPARSERS_MAP: {
  [key in AVAILABLE_IMPORT_TYPES]: BaseImportPreParser;
} = {
  [AVAILABLE_IMPORT_TYPES.CSV]: new BaseCSVPreParser(),
  [AVAILABLE_IMPORT_TYPES.XLS]: new BaseXLSXPreParser(),
  [AVAILABLE_IMPORT_TYPES.XLSX]: new BaseXLSXPreParser(),
  [AVAILABLE_IMPORT_TYPES.PDF]: new BasePDFPreParser(),
};

type ValueOf<T> = T[keyof T];

export class BaseImportParserV3 {
  protected readonly config: Config;
  public readonly debug: boolean = false;
  public readonly useMetadata: boolean = false;

  private line: number;
  private importDocument = [];
  private store = {};
  private documentHeader = [];
  private documentBody = [];
  private readonly translationsService = new TranslationService();
  private errorTranslations;
  public metadata: any = {};

  public async parse(
    file: File,
    language: string,
    accounts: AccountsOrAccount,
  ): Promise<ImportResultItem[]> {
    try {
      const START_DATE = new Date();

      this.setErrorsTranslations(language);

      const [typeParser, chosenTypeParserCase, OPTIONS] =
        await this.matchWithTypes(file);

      if (this.debug) console.log(OPTIONS);

      let buffer: ArrayBuffer = await file.arrayBuffer();
      if (OPTIONS.encoding === 'win1251') {
        const decodeCSVString = iconv.decode(Buffer.from(buffer), 'win1251');
        buffer = Buffer.from(decodeCSVString) as any;
      }

      this.importDocument = await typeParser.preParse(buffer, {
        ...(OPTIONS.preParserConfigs as CaseOptions),
        debug: this.debug,
        useMetadata: this.useMetadata,
      });
      if (this.useMetadata && (typeParser as any).metadata) {
        this.metadata.preParse = { ...(typeParser as any).metadata };
      }
      if (this.useMetadata) {
        this.metadata.importDocument = [...this.importDocument];
      }
      this.store = typeParser.store;
      typeParser.store = [];

      if (this.debug) console.log({ importDocument: this.importDocument });
      let parseOperation;
      if (!OPTIONS.proceedCaseConfig) {
        parseOperation = chosenTypeParserCase.proceedCase(this.importDocument);
      } else {
        let body = this.importDocument;
        if (OPTIONS.proceedCaseConfig.withoutEmpty) {
          body = body
            .map((el) => (el.length ? el.filter(Boolean) : []))
            .filter((el) => Boolean(el.length));
          if (this.debug) console.log({ bodyWithoutEmpty: body });
        }
        if (OPTIONS.proceedCaseConfig.removeOnlyEmpty) {
          body = body.filter((el) => el.some((str) => str !== ''));
        }
        if (OPTIONS.proceedCaseConfig.delete) {
          this.deleteFromTo(body, OPTIONS.proceedCaseConfig.delete);
        }
        const header = body[0];
        body = body.slice(1);

        if (this.useMetadata) {
          this.metadata.header = header;
          this.metadata.body = body;
        }

        if (this.debug) console.log({ header, body });

        this.setDocumentHeader(header);
        this.setDocumentBody(body);
        parseOperation = (): ImportResultItemMask => {
          const result = {};
          Object.keys(OPTIONS.proceedCaseConfig.fields).forEach((key) => {
            this.getParseOperationField(
              result,
              OPTIONS.proceedCaseConfig.fields,
              key,
            );
          });
          return result;
        };
      }

      const result: ImportResultItem[] = [];

      let i = OPTIONS.isDESCOpOrder ? this.documentBody.length - 1 : 0;
      const check = () =>
        OPTIONS.isDESCOpOrder ? i >= 0 : i < this.documentBody.length;
      const inc = () => (OPTIONS.isDESCOpOrder ? i-- : i++);

      for (; check(); inc()) {
        this.line = i;

        let operationMask: ImportResultItemMask = parseOperation();
        operationMask.index = OPTIONS.isDESCOpOrder
          ? this.documentBody.length - this.line - 1
          : this.line;
        operationMask = new ImportOperationMask(operationMask).prepareOperation(
          accounts,
          OPTIONS,
          START_DATE,
        );
        const operation = new ImportOperation(
          operationMask,
          OPTIONS,
          this.documentBody.length,
        ).toObject(this.errorTranslations);

        result.push(operation);
      }
      if (!result.length)
        this.throwError(this.errorTranslations?.wrongExtension);

      if (this.debug) console.log(result);
      if (this.useMetadata) {
        this.metadata.result = result;
      }

      return result;
    } catch (error) {
      if (this.debug) console.log(error);
      if (error?.message) throw error;
      this.throwError(this.errorTranslations?.wrongExtension);
    }
  }

  private setErrorsTranslations(language: string) {
    this.errorTranslations =
      this.translationsService.getResources()[
        language
      ]?.translation?.import?.errors;
  }

  private async matchWithTypes(
    file: File,
  ): Promise<[BaseImportPreParser, CaseConfig, CaseOptions]> {
    const matchType = file.name.match(
      new RegExp(
        Object.values(AVAILABLE_IMPORT_TYPES)
          .map((k) => `\\${k}$`)
          .join('|'),
        'i',
      ),
    );
    if (!matchType?.length)
      this.throwError(this.errorTranslations?.wrongExtension);

    const [fileType] = matchType.map((t) => t?.toLowerCase());
    const typeParser: ValueOf<typeof PREPARSERS_MAP> = PREPARSERS_MAP[fileType];
    if (!typeParser) this.throwError(this.errorTranslations?.wrongExtension);

    if (this.useMetadata) {
      this.metadata.fileType = fileType;
    }

    const typeParserCases = this.config[fileType];

    if (!typeParserCases?.length) {
      if (this.useMetadata) {
        this.metadata.errorMessage = `Not a single case was suitable`;
      }
      this.throwError(this.errorTranslations?.wrongExtension);
    }
    let chosenTypeParserCase: CaseConfig;
    if (this.useMetadata) {
      this.metadata.allOptions = typeParserCases.map((el) => el.caseOptions);
    }
    for (const typeParserCase of typeParserCases) {
      if (!typeParserCase.isCurCase && !typeParserCase.caseOptions.isCurCase) {
        if (this.useMetadata) {
          this.metadata.currentOptions = { ...typeParserCase.caseOptions };
        }
        chosenTypeParserCase = typeParserCase;
        break;
      } else if (
        !typeParserCase.isCurCase &&
        typeParserCase.caseOptions.isCurCase
      ) {
        const isCurrentCaseResult = await this.currentCaseConfigHandler(
          typeParserCase,
          typeParser,
          file,
        );
        if (isCurrentCaseResult) {
          chosenTypeParserCase = typeParserCase;
          break;
        }
      } else if (typeParserCase.isCurCase) {
        const isCurrent = await typeParserCase.isCurCase(
          file,
          typeParserCase.preParser ? typeParserCase.preParser : typeParser,
        );
        if (isCurrent) {
          if (this.useMetadata) {
            this.metadata.currentOptions = { ...typeParserCase.caseOptions };
          }
          chosenTypeParserCase = typeParserCase;
          break;
        }
      }
    }
    if (!chosenTypeParserCase)
      this.throwError(this.errorTranslations?.wrongExtension);

    const OPTIONS: CaseOptions = chosenTypeParserCase.caseOptions;

    return [
      chosenTypeParserCase.preParser
        ? chosenTypeParserCase.preParser
        : typeParser,
      chosenTypeParserCase,
      OPTIONS,
    ];
  }

  private throwError(message: string, line = 1): never {
    throw new Error(
      JSON.stringify({
        message,
        line,
      }),
    );
  }

  protected isImportValueValid(value: unknown): boolean {
    return !isEmptyImportField(value);
  }

  protected setDocumentHeader(documentHeader) {
    this.documentHeader = documentHeader;
  }

  protected setDocumentBody(documentBody) {
    this.documentBody = documentBody;
  }

  protected getFirstValidCellByColumn(
    columnIdentifiers: (string | number)[],
    validationFunc = this.isImportValueValid,
    options: {
      compareStringColumnsAtLower?: boolean;
      compareStringColumnsAsInclude?: boolean;
      revertSearch?: boolean;
    } = {
      compareStringColumnsAtLower: false,
      compareStringColumnsAsInclude: true,
      revertSearch: false,
    },
  ) {
    for (const columnIdentifier of columnIdentifiers) {
      if (typeof columnIdentifier === 'number') {
        if (validationFunc(this.documentBody[this.line][columnIdentifier]))
          return this.documentBody[this.line][columnIdentifier];
      }
      if (typeof columnIdentifier === 'string') {
        const foundColumnIndex = (
          options.revertSearch ? _.findLastIndex : _.findIndex
        )(this.documentHeader, (header: unknown) => {
          if (options.compareStringColumnsAsInclude) {
            return options.compareStringColumnsAtLower
              ? header
                  .toString()
                  .toLowerCase()
                  .includes(columnIdentifier.toLowerCase())
              : header.toString().includes(columnIdentifier);
          }
          return options.compareStringColumnsAtLower
            ? header.toString().toLowerCase() === columnIdentifier.toLowerCase()
            : header.toString() === columnIdentifier;
        });
        if (foundColumnIndex === -1) continue;
        else if (validationFunc(this.documentBody[this.line][foundColumnIndex]))
          return this.documentBody[this.line][foundColumnIndex];
      }
    }
  }

  private replaceWord(cond, word) {
    if (cond.replace) {
      for (const rep of cond.replace) {
        word = word.replaceAll(rep.from, rep.to);
      }
    }
    if (cond.replaceOne) {
      for (const rep of cond.replaceOne) {
        if (rep.from.regexp) {
          word = word.replace(
            new RegExp(rep.from.regexp.str, rep.from.regexp.flags),
            rep.to,
          );
        } else {
          word = word.replace(rep.from, rep.to);
        }
      }
    }
    return word;
  }

  protected splitFields(columnIdentifiers: number[]) {
    let result = '';
    for (const columnIdentifier of columnIdentifiers) {
      result += this.documentBody[this.line][columnIdentifier] || '';
    }
    return result || undefined;
  }

  protected getFromStore(field: string) {
    return this.store[field];
  }

  protected findString(matchValue: RegExp | searchFunc):
    | {
        data: unknown;
        raw: number;
        column: number;
      }
    | undefined {
    if (typeof matchValue === 'object') {
      for (let i = 0; i < this.importDocument.length; i++) {
        for (let j = 0; j < this.importDocument[i].length; j++) {
          const curWord = this.importDocument[i][j];
          if (matchValue.test(curWord)) {
            return { data: curWord, raw: i, column: j };
          }
        }
      }
    } else {
      for (let i = 0; i < this.importDocument.length; i++) {
        for (let j = 0; j < this.importDocument[i].length; j++) {
          const curWord = this.importDocument[i][j];
          let prevWord = '';
          if (this.importDocument[i][j - 1])
            prevWord = this.importDocument[i][j - 1];
          else if (this.importDocument[i - 1] && this.importDocument[i - 1][j])
            prevWord = this.importDocument[i - 1][j];
          let nextWord = '';
          if (this.importDocument[i][j + 1])
            nextWord = this.importDocument[i][j + 1];
          else if (this.importDocument[i + 1] && this.importDocument[i + 1][j])
            nextWord = this.importDocument[i + 1][j];
          if (matchValue(curWord, { prevWord, nextWord })) {
            return { data: curWord, raw: i, column: j };
          }
        }
      }
    }
  }

  listAvailableImportTypes(): AVAILABLE_IMPORT_TYPES[] {
    return Object.keys(this.config) as AVAILABLE_IMPORT_TYPES[];
  }

  private isCurrentCheck(object, currentIndex, conditions) {
    const useStr = object[0]?.str !== undefined;
    for (const cond of conditions) {
      if (isArray(cond)) {
        let value;
        if (isArray(cond[0])) {
          value = '';
          for (const index of cond[0]) {
            value += useStr
              ? object[currentIndex + index]?.str
              : typeof object === 'string'
              ? object
              : object[currentIndex][index];
          }
        } else {
          const index = cond[0];
          value = useStr
            ? object[currentIndex + index]?.str
            : typeof object === 'string'
            ? object
            : object[currentIndex][index];
        }
        const [finalCond] = Object.keys(cond[1]);
        const condValue = cond[1][finalCond];
        if (finalCond === 'eq') {
          if (value !== condValue) return false;
        } else if (finalCond === 'in') {
          if (!value.includes(condValue)) return false;
        } else if (finalCond === 'inRaw') {
          if (!object[cond[0]].includes(condValue)) return false;
        } else if (finalCond === 'dateFormat') {
          if (!moment(value, condValue, true).isValid()) return false;
        } else if (finalCond === 'split') {
          const spliter = condValue.spliter;
          const newConds = condValue.arr;
          const values = useStr
            ? value.split(spliter).map((el) => ({ str: el }))
            : value;
          if (!this.isCurrentCheck(values, 0, newConds)) return false;
        } else if (finalCond === 'regexp') {
          if (!new RegExp(condValue.str, condValue.flags).test(value))
            return false;
        }
      } else {
        const keys = Object.keys(cond);
        if (keys.includes('or')) {
          if (
            !cond.or
              .map((el) => this.isCurrentCheck(object, currentIndex, [el]))
              .some((el) => el)
          ) {
            return false;
          }
        } else if (keys.includes('and')) {
          if (
            !cond.and
              .map((el) => this.isCurrentCheck(object, currentIndex, [el]))
              .every((el) => el)
          ) {
            return false;
          }
        }
      }
    }
    return true;
  }

  private getCondValues(cond, key) {
    const firstValue = cond[0].column
      ? this.getParseOperationField({}, { [key]: cond[0] }, key)
      : cond[0];
    const secondValue = cond[1].column
      ? this.getParseOperationField({}, { [key]: cond[1] }, key)
      : cond[1];
    return { firstValue, secondValue };
  }

  private getParseOperationField(result, fields, key) {
    const field = fields[key];
    if (field.or) {
      for (const cond of field.or) {
        const res = this.getParseOperationField({}, { [key]: cond }, key);
        if (res) {
          result[key] = res;
          return res;
        }
      }
      return;
    }
    let value;
    if (field.column) {
      if (!field.dateFormat) {
        value = this.getFirstValidCellByColumn(field.column) || '';
      } else {
        const rawValue = this.getFirstValidCellByColumn(field.column);
        value = moment(rawValue, field.dateFormat, 'en').toISOString();
      }
    }
    if (field.fromStorage) {
      value = this.getFromStore(field.fromStorage);
    }
    value = this.replaceWord(field, value);
    if (field.trim) {
      value = value?.trim();
    }
    if (field.split) {
      const by = field.split.by.regexp
        ? new RegExp(field.split.by.regexp.str, field.split.by.regexp.flags)
        : field.split.by;
      if (field.split.get < 0) {
        const splited = value.split(by);
        value = splited[splited.length + field.split.get];
      } else {
        value = value.split(by)[field.split.get];
      }
    }
    if (field.div && !isNaN(Number(value))) {
      value = (Number(value) / field.div).toString();
    }
    if (field.if) {
      if (field.if.eq) {
        const { firstValue, secondValue } = this.getCondValues(
          field.if.eq,
          key,
        );
        if (firstValue !== secondValue) return;
      }
      if (field.if.neq) {
        const { firstValue, secondValue } = this.getCondValues(
          field.if.neq,
          key,
        );
        if (firstValue === secondValue) return;
      }
      if (field.if.in) {
        const { firstValue, secondValue } = this.getCondValues(
          field.if.in,
          key,
        );
        if (!firstValue.includes(secondValue)) return;
      }
      if (field.if.dateFormat) {
        const { firstValue, secondValue } = this.getCondValues(
          field.if.dateFormat,
          key,
        );
        if (!moment(firstValue, secondValue, true).isValid()) return;
      }
      if (field.if.ndateFormat) {
        const { firstValue, secondValue } = this.getCondValues(
          field.if.ndateFormat,
          key,
        );
        if (moment(firstValue, secondValue, true).isValid()) return;
      }
      if (field.if.headerIn) {
        if (!this.documentHeader.some((el) => el.includes(field.if.headerIn)))
          return;
      }
      if (field.if.isNum) {
        const { firstValue, secondValue } = this.getCondValues(
          field.if.isNum,
          key,
        );
        if (secondValue && isNaN(Number(firstValue))) return;
        if (!secondValue && !isNaN(Number(firstValue))) return;
      }
    }
    if (field.joinIfExist) {
      value = field.joinIfExist.arr
        .map((el) => {
          if (el.column)
            return this.getParseOperationField({}, { [key]: el }, key);
          return el;
        })
        .filter(Boolean)
        .join(field.joinIfExist.by);
    }
    if (field.add) {
      field.add.forEach((el) => {
        if (el.column) {
          value += this.getParseOperationField({}, { [key]: el }, key) || '';
        } else {
          value += el;
        }
      });
    }
    result[key] = value;
    return value;
  }

  private deleteFromTo(object, conds) {
    for (const cond of conds) {
      const count = cond.count || Infinity;
      for (let i = 0; i < count; i++) {
        let modified = false;
        let fromIndex, toIndex;
        if (cond.from) {
          fromIndex = object.findIndex((arr, i) =>
            this.isCurrentCheck(object, i, cond.from),
          );
          if (!cond.to && fromIndex >= 0) {
            modified = true;
            object.splice(fromIndex);
          }
        }

        if (cond.to) {
          toIndex = object.findIndex((arr, i) =>
            this.isCurrentCheck(object, i, cond.to),
          );
          if (!cond.from && toIndex >= 0) {
            modified = true;
            object.splice(0, toIndex);
          }
        }

        if (cond.from && cond.to && fromIndex >= 0 && toIndex >= 0) {
          modified = true;
          object.splice(fromIndex, toIndex - fromIndex + 1);
        }
        if (!modified) break;
      }
    }
  }

  private async currentCaseConfigHandler(typeParserCase, typeParser, file) {
    const preParser = typeParserCase.preParser || typeParser;
    let rawDocument;
    if (typeParserCase.caseOptions.isCurCase.useBuffer) {
      const buffer = new Uint8Array(await file.arrayBuffer());
      rawDocument = '';

      for (let i = 0; i < buffer.length; i += 10000) {
        const chunk = buffer.slice(i, i + 10000);
        rawDocument += String.fromCharCode.apply(null, chunk);
      }
    } else {
      rawDocument = await preParser.getRawData(await file.arrayBuffer(), {
        ...typeParserCase?.caseOptions?.preParserConfigs,
      });
    }
    if (this.useMetadata) {
      this.metadata.rawDocument = { ...rawDocument };
    }
    if (typeParserCase.caseOptions.withoutEmpty) {
      rawDocument = rawDocument
        .map((el) => (el.length ? el.filter(Boolean) : []))
        .filter((el) => Boolean(el.length));
    }
    if (isArray(typeParserCase.caseOptions.isCurCase)) {
      const isCurrent = isNotEmpty(
        rawDocument.find((value, i) =>
          this.isCurrentCheck(
            rawDocument,
            i,
            typeParserCase.caseOptions.isCurCase,
          ),
        ),
      );
      if (isCurrent) {
        if (this.useMetadata) {
          this.metadata.currentOptions = { ...typeParserCase.caseOptions };
          this.metadata.rawDocumentAfter = { ...rawDocument };
        }
        return true;
      }
    } else {
      if (typeParserCase.caseOptions.isCurCase.and) {
        const isCurrent = typeParserCase.caseOptions.isCurCase.and.every(
          (el) => {
            if (el.not) {
              return !isNotEmpty(
                rawDocument.find((value, i) =>
                  this.isCurrentCheck(rawDocument, i, el.not),
                ),
              );
            }
            return isNotEmpty(
              rawDocument.find((value, i) =>
                this.isCurrentCheck(rawDocument, i, el),
              ),
            );
          },
        );
        if (isCurrent) {
          if (this.useMetadata) {
            this.metadata.currentOptions = { ...typeParserCase.caseOptions };
            this.metadata.rawDocumentAfter = { ...rawDocument };
          }
          return true;
        }
      }
      if (typeParserCase.caseOptions.isCurCase.simple) {
        const isCurrent = this.isCurrentCheck(
          rawDocument,
          0,
          typeParserCase.caseOptions.isCurCase.simple,
        );
        if (isCurrent) {
          if (this.useMetadata) {
            this.metadata.currentOptions = { ...typeParserCase.caseOptions };
            this.metadata.rawDocumentAfter = { ...rawDocument };
          }
          return true;
        }
      }
      if (typeParserCase.caseOptions.isCurCase.useBuffer) {
        const isCurrent = this.isCurrentCheck(
          rawDocument,
          0,
          typeParserCase.caseOptions.isCurCase.useBuffer,
        );
        if (isCurrent) {
          if (this.useMetadata) {
            this.metadata.currentOptions = { ...typeParserCase.caseOptions };
            this.metadata.rawDocumentAfter = { ...rawDocument };
          }
          return true;
        }
      }
      if (typeParserCase.caseOptions.isCurCase.not) {
        const isCurrent = !this.isCurrentCheck(
          rawDocument,
          0,
          typeParserCase.caseOptions.isCurCase.not,
        );
        if (isCurrent) {
          if (this.useMetadata) {
            this.metadata.currentOptions = { ...typeParserCase.caseOptions };
            this.metadata.rawDocumentAfter = { ...rawDocument };
          }
          return true;
        }
      }
    }
    return false;
  }
}
