import { isNotEmpty } from 'class-validator';
import moment from 'moment';

import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  BasePDFPreParser,
  Config,
  ImportResultItemMask,
} from '@finmap/import-parsers/base-import-parser-v3';

export class MilleniumBankParser extends BaseImportParserV3 {
  // public debug: boolean = true;
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.CSV]: [
      {
        proceedCase: (importDocument) => {
          this.setDocumentHeader(importDocument[0]);
          this.setDocumentBody(importDocument.slice(1));
          return (): ImportResultItemMask => ({
            currency: this.getFirstValidCellByColumn(['Waluta', 10]),
            date: this.getFirstValidCellByColumn(['Data transakcji', 1]),
            counterparty: this.getFirstValidCellByColumn([
              'Odbiorca/Zleceniodawca',
              5,
            ]),
            debit: this.getFirstValidCellByColumn(['Obciążenia', 7]),
            credit: this.getFirstValidCellByColumn(['Uznania', 8]),
            comment: this.getFirstValidCellByColumn(['Opis', 6]),
          });
        },
        caseOptions: {
          defaultCurrency: 'PLN',
          encoding: 'win1251',
          preParserConfigs: { CSVDelimiter: ',', simpleParse: true },
        },
      },
    ],
    [AVAILABLE_IMPORT_TYPES.XLS]: [
      {
        caseOptions: {
          defaultCurrency: 'UAH',
          withoutEmpty: true,
          isCurCase: [
            [
              0,
              {
                eq: 'Companhia',
              },
            ],
          ],
          proceedCaseConfig: {
            delete: [
              {
                to: [
                  [
                    0,
                    {
                      eq: 'Companhia',
                    },
                  ],
                  [
                    1,
                    {
                      eq: 'Produto',
                    },
                  ],
                ],
                count: 1,
              },
            ],
            fields: {
              sum: {
                column: [7],
              },
              date: {
                column: ['Data Valor', 5],
                dateFormat: 'DD/MM/YYYY',
              },
              comment: {
                column: ['Descricao', 6],
              },
            },
          },
        },
      },
    ],
    [AVAILABLE_IMPORT_TYPES.PDF]: [
      {
        proceedCase: (importDocument) => {
          const header = importDocument[0];
          const rawBody = importDocument.slice(1);
          const body = [];
          rawBody.forEach((el) => {
            el[1] = '';
            if (el[4] && !isNaN(Number(el[4]))) {
              if (el[2]) {
                const splited = el[2].split('\n');
                const words = splited[0].split(' ');
                let lastWord = words.pop();
                if (!isNaN(Number(lastWord))) el[2] = lastWord;
                else words.push(lastWord);

                if (splited.length) {
                  words.push(...splited.slice(1));
                }

                if (words.length) {
                  el[1] = words.join(' ');
                }
              }
              body.push(el);
            } else {
              const lastIndex = body.length - 1;
              if (el[0] && body[lastIndex][0])
                body[lastIndex][0] += `\n${el[0]}`;
              else if (el[0] && !body[lastIndex][0]) body[lastIndex][0] = el[0];

              if (el[2] && body[lastIndex][1])
                body[lastIndex][1] += `\n${el[2]}`;
              else if (el[2] && !body[lastIndex][1]) body[lastIndex][1] = el[2];

              if (el[3] && body[lastIndex][3])
                body[lastIndex][3] += `\n${el[3]}`;
              else if (el[3] && !body[lastIndex][3]) body[lastIndex][3] = el[3];

              if (el[4] && body[lastIndex][4])
                body[lastIndex][4] += `\n${el[4]}`;
              else if (el[4] && !body[lastIndex][4]) body[lastIndex][4] = el[4];
            }
          });
          console.log({ body, importDocument });

          this.setDocumentHeader(header);
          this.setDocumentBody(body);
          return (): ImportResultItemMask => {
            return {
              date: this.getFirstValidCellByColumn([
                'Data\ntransakcji /\nData\nksięgowania',
                0,
              ]).split('\n')[0],
              debit: this.getFirstValidCellByColumn(['Obciążenia', 2]),
              credit: this.getFirstValidCellByColumn(['Uznania', 3]),
              comment: this.getFirstValidCellByColumn(['Opis', 1]),
            };
          };
        },
        caseOptions: {
          defaultCurrency: 'PLN',
          preParserConfigs: {
            pageSeparatorsLengthPx: 4,
            maxInterlineSpacingPx: 10,
            verticalAlign: 'top',
            prepareRawPDF: (self: BasePDFPreParser) => {
              self.findHeader(
                (word, etc) => word === 'Data' && etc?.nextWord === 'Opis',
                (word, etc) =>
                  word === 'księgowania' && etc?.prevWord === 'Data',
              );
              self.deleteFromTo(
                undefined,
                (word, etc) =>
                  !isNaN(Number(word)) && etc?.prevWord === 'Saldo końcowe:',
                1,
              );
              self.deleteFromTo(
                (word, etc) =>
                  word === 'Data wystawienia dokumentu:' &&
                  moment(etc?.nextWord, 'DD.MM.YYYY', true).isValid(),
                (word, etc) =>
                  word.includes('całkowicie wpłaconym w wysokości ') &&
                  word.includes(' złotych.'),
              );
              self.defineOperation([
                (value) => /^-?\d+(\.\d{2})?$/.test(value), // '-22200.00'
              ]);
            },
          },
        },
      },
    ],
  };
}
