import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  Config,
} from '@finmap/import-parsers/base-import-parser-v3';

export class ThreeSMoneyCsvParser extends BaseImportParserV3 {
  protected config: Config = {
    [AVAILABLE_IMPORT_TYPES.CSV]: [
      {
        caseOptions: {
          defaultCurrency: 'EUR',
          isCurCase: [
            [
              0,
              {
                eq: 'MCV',
              },
            ],
            [
              1,
              {
                eq: 'Date',
              },
            ],
            [
              2,
              {
                eq: 'Entry ID',
              },
            ],
          ],
          proceedCaseConfig: {
            fields: {
              date: {
                column: ['Date', 1],
                dateFormat: 'YYYY-MM-DD',
              },
              comment: {
                column: ['Reference', 3],
              },
              sum: {
                column: ['Transaction amount', 6],
              },
            },
          },
        },
      },
    ],
  };
  // private mainAccount: Account;

  // protected COLUMNS_MAP: AnyObject = {
  //   A: 'mvc',
  //   B: 'date',
  //   C: 'entry',
  //   D: 'reference',
  //   E: 'paidIn',
  //   F: 'paidOut',
  //   G: 'balance',
  //   H: 'currency',
  // };

  // protected importName = '3c.money';

  // private prepareDate(originalDate) {
  //   const format = 'YYYY-MM-DD';
  //   let date;
  //   try {
  //     date = moment(originalDate, format).toDate();
  //   } catch {
  //     null;
  //   }
  //   if (!date) return;

  //   return this.dateToFormat2(date);
  // }

  // protected transformOne(operation: AnyObject, index: number): AnyObject {
  //   const errors = this.getErrors();

  //   const { mvc, date, entry, reference, paidIn, paidOut, balance, currency } =
  //     operation;

  //   let isAfter, isBefore;
  //   try {
  //     isAfter = moment(date).isAfter(moment().add(10, 'y'));
  //     isBefore = moment(date).isBefore(moment('2015-01-01', 'YYYY/MM/DD'));
  //   } catch (e) {
  //     console.log(e);
  //   }
  //   if (isAfter) this.throwError(errors.maxDate, index);
  //   if (isBefore) this.throwError(errors.minDate, index);

  //   let type, accountFromId, accountToId, sum;
  //   const credit = Math.abs(Number(paidIn));
  //   const debit = Math.abs(Number(paidOut));

  //   if (credit !== 0) {
  //     type = OperationType.INCOME;
  //     accountToId = this.mainAccount.normalizedLabel;
  //     sum = credit;
  //   }
  //   if (debit !== 0) {
  //     type = OperationType.CONSUMPTION;
  //     accountFromId = this.mainAccount.normalizedLabel;
  //     sum = debit;
  //   }

  //   const result: any = {
  //     sum,
  //     index,
  //     type,
  //   };

  //   this.addIfNotFalsy(result, {
  //     accountFromId,
  //     accountToId,
  //     subType: this.getSubType(type, undefined),
  //     date: date && this.prepareDate(date),
  //   });

  //   this.setComments(result, [reference]);

  //   const finCurrency = CURRENCIES.find((cur) => cur.code === currency).code;
  //   this.setAccountIDsByType(result, finCurrency, finCurrency);

  //   return result;
  // }

  // public setRawData(raw, account: Account) {
  //   this.mainAccount = account;

  //   const upperAlphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

  //   const str = iconv.decode(Buffer.from(raw), 'win1251');

  //   const OPTIONS = { delimiter: ',', skip_empty_lines: true };
  //   const strArray: string[][] = parse(str, OPTIONS);

  //   const allSheet = strArray.map((str) => {
  //     const resObj = {} as AnyObject;
  //     for (let i = 0; i < str.length; i++) {
  //       const letter = upperAlphabet[i];
  //       const value = str[i];
  //       resObj[letter] = value;
  //     }
  //     return resObj;
  //   });

  //   this.headersJson = allSheet[0];
  //   this.sheetJson = allSheet.slice(1);
  // }

  // protected doBeforeTranform(): AnyObject[] {
  //   return this.sheetJson.map((obj) => {
  //     return Object.keys(obj).reduce((acc, cur) => {
  //       const key = this.COLUMNS_MAP[cur];
  //       acc[key] = obj[cur];
  //       return acc;
  //     }, {});
  //   });
  // }
}
