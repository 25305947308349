import moment from 'moment';

import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  Config,
  ImportResultItemMask,
} from '@finmap/import-parsers/base-import-parser-v3';

export class ChaseBankParser extends BaseImportParserV3 {
  // public debug: boolean = true;
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.CSV]: [
      {
        caseOptions: {
          preParserConfigs: {
            CSVDelimiter: ',',
            simpleParse: true,
          },
          proceedCaseConfig: {
            fields: {
              date: {
                column: ['Posting Date', 1],
                dateFormat: 'MM.DD.YYYY',
              },
              comment: {
                column: ['Description', 2],
              },
              sum: {
                column: ['Amount', 3],
              },
            },
          },
        },
      },
    ],
    [AVAILABLE_IMPORT_TYPES.PDF]: [
      {
        proceedCase: (importDocument) => {
          let type = 'income';
          let lastIsDate = false;
          const result = [];
          const year = importDocument[1][2].split(' ').pop();

          for (let i = 0; i < importDocument.length; i++) {
            const rows = importDocument[i];
            if (moment(rows[0], 'MM/DD', true).isValid()) {
              const [month, day] = rows[0].split('/');
              let comment = '';
              let sum;
              if (rows.length === 3) {
                comment += rows[1];
              }
              const sumIndex = rows.length === 2 ? 1 : 2;
              const splited = rows[sumIndex].split(' ');
              const rawSum = splited.pop().replaceAll(',', '');
              comment += splited.join(' ');
              if (isNaN(Number(rawSum[0]))) {
                sum = rawSum.substring(1);
              } else {
                sum = rawSum;
              }
              lastIsDate = true;
              result.push([`${day}.${month}.${year}`, comment, sum, type]);
            }
            if (rows[0] === '' && lastIsDate) {
              const lastIndex = result.length - 1;
              result[lastIndex][1] += ' ' + rows[1];
            }
            if (rows[0].includes('deposits and additions')) {
              type = 'income';
              lastIsDate = false;
            }
            if (rows[0].includes('fees')) {
              type = 'consumption';
              lastIsDate = false;
            }
            if (rows[0].includes('electronic withdrawal')) {
              type = 'consumption';
              lastIsDate = false;
            }
          }
          this.setDocumentHeader(['DATE', 'DESCRIPTION', 'AMOUNT', 'TYPE']);
          this.setDocumentBody(result);
          return (): ImportResultItemMask => {
            const result: any = {
              date: this.getFirstValidCellByColumn(['DATE', 0]),
              comment: this.getFirstValidCellByColumn(['DESCRIPTION', 1]),
            };
            const type = this.getFirstValidCellByColumn(['TYPE', 3]);
            if (type === 'income') {
              result.credit = this.getFirstValidCellByColumn(['AMOUNT', 2]);
            } else {
              result.debit = this.getFirstValidCellByColumn(['AMOUNT', 2]);
            }
            return result;
          };
        },
        caseOptions: {
          defaultCurrency: 'USD',
          isCurCase: [
            [
              0,
              {
                in: '',
              },
            ],
          ],
          preParserConfigs: {
            verticalAlign: 'top',
            spaceLengthPx: 2,
            prepareRawConfig: {
              findHeader: {
                from: [
                  {
                    word: {
                      eq: 'DATE',
                    },
                  },
                ],
                to: [
                  {
                    word: {
                      in: 'AMOUNT',
                    },
                    prevWord: {
                      eq: 'DESCRIPTION',
                    },
                  },
                ],
              },
              delete: [
                {
                  from: [
                    {
                      word: {
                        eq: 'DEPOSITS',
                      },
                    },
                  ],
                  to: [
                    {
                      word: {
                        eq: 'ADDITIONS',
                      },
                    },
                  ],
                },
                {
                  from: [
                    {
                      word: {
                        eq: 'DAILY',
                      },
                      nextWord: {
                        eq: 'ENDING',
                      },
                    },
                  ],
                },
              ],
              define: [{ is: true }],
            },
          },
        },
      },
    ],
  };
}
