import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CustomButton from '../../../components/Button';
import Dialog from '../../../components/Dialog/Dialog';
import TextFieldComponent from '../../../components/TextField/TextFieldComponent';
import { BelvoBankType } from '../../../store/integrations/types';

type Props = {
  onClose(): void;
  isOpened: boolean;
  onSuccess(
    number: string,
    name: string,
    number2: string | null,
    companyName: string | null,
  ): void;
  currentBank: BelvoBankType;
};

function BelvoCredsDialog(props: Props) {
  const { onClose, onSuccess, isOpened, currentBank } = props;

  const [hasNumberError, setHasNumberError] = useState<boolean | null>(null);
  const [numberValue, setNumberValue] = useState<null | string>(null);
  const [hasNumber2Error, setHasNumber2Error] = useState<boolean | null>(null);
  const [number2Value, setNumber2Value] = useState<null | string>(null);
  const [nameValue, setNameValue] = useState<null | string>(null);
  const [companyNameValue, setCompanyNameValue] = useState<null | string>(null);

  const handleSetNumberValue = useCallback((value: string) => {
    const isValid = !isNaN(Number(value));
    setHasNumberError(!isValid);
    if (isValid) {
      setNumberValue(value);
    }
  }, []);

  const handleSetNumber2Value = useCallback((value: string) => {
    const isValid = !isNaN(Number(value));
    setHasNumber2Error(!isValid);
    if (isValid) {
      setNumber2Value(value);
    }
  }, []);

  const handleSetNameValue = useCallback((value: string) => {
    setNameValue(value);
  }, []);

  const handleSetCompanyNameValue = useCallback((value: string) => {
    setCompanyNameValue(value);
  }, []);

  const handleCreateConnection = useCallback(() => {
    if (numberValue && nameValue) {
      onSuccess(numberValue, nameValue, number2Value, companyNameValue);
    }
  }, [onSuccess, numberValue, nameValue, number2Value, companyNameValue]);

  const { t } = useTranslation();

  return (
    <>
      <Dialog
        isOpened={isOpened}
        onClose={onClose}
        title={t('integrations.belvo.yourCredentials')}
      >
        {currentBank.name_code.includes('_business') ? (
          <>
            <TextFieldComponent
              value={number2Value}
              onChange={handleSetNumber2Value}
              fullWidth
              type={'text'}
              placeholder={t('integrations.belvo.number2Placeholder')}
              isError={hasNumber2Error === true}
              errorText={t('integrations.belvo.numberValidationError')}
            />
            <TextFieldComponent
              value={companyNameValue}
              onChange={handleSetCompanyNameValue}
              fullWidth
              type={'text'}
              placeholder={t('integrations.belvo.companyName')}
              isError={companyNameValue === ''}
              errorText={t('integrations.belvo.emptyValidationError')}
            />
          </>
        ) : (
          <></>
        )}

        <TextFieldComponent
          value={numberValue}
          onChange={handleSetNumberValue}
          fullWidth
          type={'text'}
          placeholder={t('integrations.belvo.numberPlaceholder')}
          isError={hasNumberError === true}
          errorText={t('integrations.belvo.numberValidationError')}
        />

        <TextFieldComponent
          value={nameValue}
          onChange={handleSetNameValue}
          fullWidth
          type={'text'}
          placeholder={t('integrations.belvo.name')}
          isError={nameValue === ''}
          errorText={t('integrations.belvo.emptyValidationError')}
        />

        <CustomButton
          disabled={
            hasNumberError ||
            numberValue === null ||
            numberValue === '' ||
            nameValue === '' ||
            nameValue === null
          }
          title={t('bank.set')}
          action={handleCreateConnection}
          fullWidth
        />
      </Dialog>
    </>
  );
}

export default React.memo(BelvoCredsDialog);
