import { CustomFilters } from '../journal/types';
import { CreateUserFilterPayload, UserFilter } from './customFiltersApi.types';
import {
  CopyFiltersPayload,
  FiltersState,
  ResetFiltersPayload,
  SetFutureJournalFilterPayload,
  SetPeriodFilterPayload,
} from './types';

export const SET_CUSTOM_FILTER = 'SET_CUSTOM_FILTER';
export const RESET_CUSTOM_FILTERS = 'RESET_CUSTOM_FILTERS';
export const RESET_FILTERS = 'RESET_FILTERS';
export const COPY_FILTERS = 'COPY_FILTERS';
export const COPY_PERIOD_FILTERS = 'COPY_PERIOD_FILTERS';
export const INIT_FILTERS = 'INIT_FILTERS';
export const SET_FILTERS_PERIOD = 'SET_FILTERS_PERIOD';
export const SET_SEARCH_FILTER = 'SET_SEARCH_FILTER';
export const SET_RANGE_FROM_FILTER = 'SET_RANGE_FROM_FILTER';
export const SET_RANGE_TO_FILTER = 'SET_RANGE_TO_FILTER';
export const SET_TAGS_FILTER = 'SET_TAGS_FILTER';
export const SET_FUTURE_JOURNAL_FILTER = 'SET_FUTURE_JOURNAL_FILTER';
export const RESET_FUTURE_JOURNAL_FILTER = 'RESET_FUTURE_JOURNAL_FILTER';
export const UPDATE_FUTURE_DATE_FILTER = 'UPDATE_FUTURE_DATE_FILTER';

export const UPDATE_USER_CUSTOM_FILTER_PENDING =
  'UPDATE_USER_CUSTOM_FILTER_PENDING';
export const UPDATE_USER_CUSTOM_FILTER_SUCCESS =
  'UPDATE_USER_CUSTOM_FILTER_SUCCESS';
export const UPDATE_USER_CUSTOM_FILTER_FAILED =
  'UPDATE_USER_CUSTOM_FILTER_FAILED';

export const GET_USER_FILTERS_PENDING = 'GET_USER_FILTERS_PENDING';
export const GET_USER_FILTERS_SUCCESS = 'GET_USER_FILTERS_SUCCESS';
export const GET_USER_FILTERS_FAILED = 'GET_USER_FILTERS_FAILED';

export const CREATE_USER_FILTER_PENDING = 'CREATE_USER_FILTER_PENDING';
export const CREATE_USER_FILTER_SUCCESS = 'CREATE_USER_FILTER_SUCCESS';
export const CREATE_USER_FILTER_FAILED = 'CREATE_USER_FILTER_FAILED';

export const REMOVE_USER_FILTER_PENDING = 'REMOVE_USER_FILTER_PENDING';
export const REMOVE_USER_FILTER_SUCCESS = 'REMOVE_USER_FILTER_SUCCESS';
export const REMOVE_USER_FILTER_FAILED = 'REMOVE_USER_FILTER_FAILED';

export const UPDATE_USER_FILTER_PENDING = 'UPDATE_USER_FILTER_PENDING';
export const UPDATE_USER_FILTER_SUCCESS = 'UPDATE_USER_FILTER_SUCCESS';
export const UPDATE_USER_FILTER_FAILED = 'UPDATE_USER_FILTER_FAILED';

const setPeriod = (data: SetPeriodFilterPayload) => ({
  type: SET_FILTERS_PERIOD,
  payload: data,
});

const setFutureJournalFilter = (
  data: SetFutureJournalFilterPayload,
  selector: keyof FiltersState = 'journal',
) => ({
  type: SET_FUTURE_JOURNAL_FILTER,
  payload: { data, selector },
});

const resetFutureJournalFilter = () => ({
  type: SET_FUTURE_JOURNAL_FILTER,
});

const setCustomFilter = (
  data: any,
  filterType: keyof CustomFilters,
  selector: keyof FiltersState,
) => ({
  type: SET_CUSTOM_FILTER,
  payload: { data, filterType, selector },
});

const resetFilters = (data: ResetFiltersPayload) => ({
  type: RESET_FILTERS,
  payload: data,
});

const copyFilters = (data: CopyFiltersPayload) => ({
  type: COPY_FILTERS,
  payload: data,
});

const copyPeriodFilters = (data: CopyFiltersPayload) => ({
  type: COPY_PERIOD_FILTERS,
  payload: data,
});

const resetCustomFilters = (selector: keyof FiltersState) => ({
  type: RESET_CUSTOM_FILTERS,
  payload: { selector },
});

const setTagsFilter = (tags: any[]) => ({
  type: SET_TAGS_FILTER,
  payload: { tags },
});

const setSearchFilter = (search: string) => ({
  type: SET_SEARCH_FILTER,
  payload: { search },
});

const setRangeFromFilter = (value: number | null) => ({
  type: SET_RANGE_FROM_FILTER,
  payload: { value },
});

const setRangeToFilter = (value: number | null) => ({
  type: SET_RANGE_TO_FILTER,
  payload: { value },
});

const getUserFilters = () => ({
  type: GET_USER_FILTERS_PENDING,
});

const createUserFilter = (data: CreateUserFilterPayload) => ({
  type: CREATE_USER_FILTER_PENDING,
  payload: data,
});

const removeUserFilter = (id: string) => ({
  type: REMOVE_USER_FILTER_PENDING,
  payload: { id },
});

const updateUserFilter = (filter: UserFilter) => ({
  type: UPDATE_USER_CUSTOM_FILTER_PENDING,
  payload: { filter },
});

export default {
  setPeriod,
  copyFilters,
  resetFilters,
  setTagsFilter,
  getUserFilters,
  setSearchFilter,
  setCustomFilter,
  removeUserFilter,
  createUserFilter,
  updateUserFilter,
  copyPeriodFilters,
  resetCustomFilters,
  setFutureJournalFilter,
  resetFutureJournalFilter,
  setRangeToFilter,
  setRangeFromFilter,
};
