import { Typography } from '@material-ui/core';
import cn from 'classnames';
import { SyntheticEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FEATURES } from '@finmap/core-constants';
import moment from 'moment';

import AlertIcon from '../../assets/images/svg/alert-triangle.svg';
import WarningIcon from '../../assets/images/svg/warning-triangle.svg';
import DragAndDropIcon from '../../assets/images/svg/drug_drop_small.svg';
import EditIcon from '../../assets/images/svg/edit.svg';
import UnVisibleIcon from '../../assets/images/svg/eye-hide.svg';
import RestrictedIcon from '../../assets/images/svg/restricted.svg';
import Spinner from '../../components/Spinner';
import Tooltip from '../../components/Tooltip';
import usePermissions from '../../hooks/usePermissions';
import useRestricted from '../../hooks/useRestricted';
import useUnleash from '../../hooks/useUnleash';
import { AccountValue } from '../../store/accounts/types';
import {
  IntegrationInformerStatus,
  IntegrationTypeId,
} from '../../store/integrations/types';
import { useStyles } from './styles';
import utils from './utils';

type Props = {
  value: AccountValue;
  subscriptionActive: boolean;

  onReconnectAuth?(id: string, value: AccountValue): void;
  onEditRow(id: string): void;
};

function RenderAccountRow(props: Props) {
  const { value, onEditRow, subscriptionActive, onReconnectAuth } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const { readAccess, openRestrictedDialog } = useRestricted();
  const { isEditableAccounts } = usePermissions();
  const useReconnectIntegration = useUnleash(
    FEATURES.USE_RECONNECT_INTEGRATION,
  );

  const icon = utils.getIntegrationIcon(
    value.integration?.typeId,
    value.integration?.logoLink,
  );

  let integrationInformerStatus = IntegrationInformerStatus.WITHOUT;
  let errorText = '';
  let hasIntegrationProblem = false;

  if (
    value.integration?.typeId === IntegrationTypeId.Payoneer &&
    moment(value.integration?.createdAt).add(30, 'day').isBefore(moment())
  ) {
    errorText = t('integrations.repeatIntegration');
    hasIntegrationProblem = true;
    integrationInformerStatus = IntegrationInformerStatus.ERROR;
  } else if (
    value.integration?.typeId === IntegrationTypeId.Nordigen &&
    moment(value.integration?.createdAt).add(90, 'day').isBefore(moment())
  ) {
    errorText = t('integrations.repeatIntegration');
    hasIntegrationProblem = true;
    integrationInformerStatus = IntegrationInformerStatus.ERROR;
  } else if (
    value.integration?.typeId === IntegrationTypeId.Nordigen &&
    typeof value.integration?.lastErrorStatus === 'number' &&
    value.integration?.lastErrorStatus !== 429
  ) {
    errorText = t('integrations.repeatIntegration');
    hasIntegrationProblem = true;
    integrationInformerStatus = IntegrationInformerStatus.ERROR;
  } else if (
    value.integration?.typeId === IntegrationTypeId.Nordigen &&
    moment(value.integration?.createdAt).add(87, 'day').isBefore(moment()) &&
    moment(value.integration?.createdAt).add(90, 'day').isAfter(moment())
  ) {
    errorText = t('integrations.reconnectWarning', {
      postProcess: 'sprintf',
      sprintf: [
        value.integration?.bankId?.replaceAll('_', ' '),
        moment(value.integration?.createdAt)
          .add(90, 'day')
          .diff(moment(), 'days'),
      ],
    });
    hasIntegrationProblem = true;
    integrationInformerStatus = IntegrationInformerStatus.WARNING;
  } else if (
    value.integration?.typeId === IntegrationTypeId.Payoneer &&
    moment(value.integration?.createdAt).add(27, 'day').isBefore(moment()) &&
    moment(value.integration?.createdAt).add(30, 'day').isAfter(moment())
  ) {
    errorText = t('integrations.reconnectWarning', {
      postProcess: 'sprintf',
      sprintf: [
        'Payoneer',
        moment(value.integration?.createdAt)
          .add(30, 'day')
          .diff(moment(), 'days'),
      ],
    });
    hasIntegrationProblem = true;
    integrationInformerStatus = IntegrationInformerStatus.WARNING;
  }

  const reconnectIntegrationWarning =
    useReconnectIntegration && hasIntegrationProblem;

  const handleReconnectBank = useCallback(() => {
    if (onReconnectAuth && value.integration) {
      onReconnectAuth(value.integration._id, value);
    }
  }, [onReconnectAuth, value]);

  const handleEditClick = useCallback(
    (event: SyntheticEvent) => {
      event.stopPropagation();

      if (readAccess || !isEditableAccounts) {
        openRestrictedDialog();
      } else {
        onEditRow(event.currentTarget.id);
      }
    },
    [onEditRow, readAccess, openRestrictedDialog, isEditableAccounts],
  );

  return (
    <div
      key={value._id}
      className={cn(classes.row, !value.visible && classes.opacity)}
      id={!value.visible ? `invisible-${value._id}` : `edit-${value._id}`}
      onClick={handleEditClick}
    >
      <div
        className={cn(
          classes.leftBlock,
          reconnectIntegrationWarning && classes.width60,
        )}
      >
        {isEditableAccounts && (
          <img
            src={DragAndDropIcon}
            alt="DragAndDropIcon"
            className={cn(classes.opacity, classes.margin16)}
          />
        )}
        {!isEditableAccounts && <div className={classes.margin16} />}
        <Typography className={cn(classes.opacity, classes.margin16)}>
          {value.currency?.symbol || ''}
        </Typography>
        <Typography
          className={cn(
            classes.textEclipse,
            reconnectIntegrationWarning && classes.margin16,
            reconnectIntegrationWarning && classes.textEclipseShort,
          )}
          title={value.name}
        >
          {value.name}
        </Typography>
        {reconnectIntegrationWarning && (
          <Tooltip title={errorText} disablePortal={false} placement="top">
            <img
              onClick={handleReconnectBank}
              src={
                integrationInformerStatus === IntegrationInformerStatus.ERROR
                  ? AlertIcon
                  : WarningIcon
              }
              alt="alert"
            />
          </Tooltip>
        )}
      </div>
      <div className={classes.rightBlock}>
        {!value.integration?.loaderDone && value.integration?.loaderState && (
          <>
            <Spinner />
            <div className={classes.flexRow}>
              <Typography className={classes.loaderStateNumber}>
                {`${value.integration.loaderState}%`}
              </Typography>
              <Typography className={cn(classes.loaderState, classes.hide)}>
                {t('integrations.loaded').toLowerCase()}...
              </Typography>
            </div>
          </>
        )}
        {reconnectIntegrationWarning &&
          value.visible &&
          value.name.length < 20 && (
            <div
              className={classes.reconnectButtonContainer}
              onClick={handleReconnectBank}
            >
              <Typography className={classes.reconnectButtonText}>
                {t('integrations.reconnectBank')}
              </Typography>
            </div>
          )}
        {value.integration?.loaderDone &&
          Boolean(value.integration) &&
          icon && (
            <>
              {!subscriptionActive && (
                <img
                  src={RestrictedIcon}
                  alt="restricted"
                  className={classes.margin16}
                />
              )}
              <img
                src={icon}
                alt="bank-icon"
                className={cn(
                  classes.bankIcon,
                  !reconnectIntegrationWarning && classes.margin8,
                )}
              />
            </>
          )}
        {value.visible &&
          !reconnectIntegrationWarning &&
          isEditableAccounts && (
            <img className={classes.pointer} alt="visible" src={EditIcon} />
          )}
        {!value.visible && (
          <img
            className={classes.pointer}
            alt="unvisible"
            src={UnVisibleIcon}
          />
        )}
      </div>
    </div>
  );
}

export default RenderAccountRow;
