import { createSelector } from 'reselect';

import { AppState } from '../reducers';

export const getContainerOffset = (state: AppState) =>
  state.common.containerOffset;

export const getShowEmptyStateTooltipV3 = (state: AppState) =>
  state.common.showEmptyStateTooltipV3;

export const getShowRestrictedDialog = (state: AppState) =>
  state.common.showRestrictedDialog;

export const getShowCoins = (state: AppState) => state.common.showCoins;

export const getAmountEmoji = (state: AppState) => state.common.amountEmoji;

export const getPaymentEmoji = (state: AppState) => state.common.paymentEmoji;

export const getShowLeftMenu = (state: AppState) => state.common.showLeftMenu;

export const getVersion = (state: AppState) => state.common.version;

export const getVersionUI = (state: AppState) => state.common.versionUI;

export const getServerError = (state: AppState) => state.common.serverError;

export const getCreateElementError = (state: AppState) =>
  state.common.createElementError;

export const getCreateElementLoading = (state: AppState) =>
  state.common.createElementLoading;

export const getSearchFocused = (state: AppState) => state.common.searchFocused;

export const getActivities = (state: AppState) => state.common.activities;

export const getServerTime = (state: AppState) => state.common.serverTime;

export const getUserGeoData = (state: AppState) => state.common.userGeoData;

export const getSyncIntegrations = (state: AppState) =>
  state.common.syncIntegrations;

export const getSyncFirstIntegration = (state: AppState) =>
  state.common.syncFirstIntegration;

export const getSyncIntegrationsCount = (state: AppState) =>
  state.common.syncIntegrationsCount;

export const getCompletedIntegrationSync = (state: AppState) =>
  state.common.completedIntegrations;

export const selectActivitiesAutoComplete = createSelector(
  getActivities,
  (activities) =>
    activities.map((el) => ({
      id: el._id,
      label: el.label,
      name: el.label,
    })),
);
