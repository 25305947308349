import { AnyAction } from 'redux';

import { initialHistoryFilters } from '../filters/initial';
import { OperationType } from '../operations/types';
import {
  ANALYTIC_INIT,
  GET_ACCOUNT_STATEMENT_PENDING,
  GET_ACCOUNT_STATEMENT_SUCCESS,
  GET_CHARTS_PENDING,
  GET_CIRCLE_CHARTS_PENDING,
  GET_CIRCLE_CHARTS_SUCCESS,
  GET_CREDIT_DATA_PENDING,
  GET_CREDIT_DATA_SUCCESS,
  GET_DEBIT_DATA_PENDING,
  GET_DEBIT_DATA_SUCCESS,
  GET_MAIN_CHART_PENDING,
  GET_MAIN_CHART_SUCCESS,
  GET_PIE_CHAR_DATA_AMOUNT,
  GET_PIE_CHAR_DATA_SUCCESS,
  GET_PROJECTS_DATA_PENDING,
  GET_PROJECTS_DATA_SUCCESS,
  GET_STACKED_CHART_DATA_SUCCESS,
  GET_TABLE_DATA_PENDING,
  GET_TABLE_DATA_SUCCESS,
  SET_LOADING_CIRCLES,
  SET_LOADING_ERROR,
  SET_LOADING_STACKED,
  SET_STACKED_CHART_DATA_LOADING,
  SET_TYPE_FILTER,
  START_LOAD_BALANCE_DATA,
  FINISH_LOAD_BALANCE_DATA,
  SET_CUSTOMERS_SUM,
  SET_DEBTORS_SUM,
  SET_INVESTMENTS_SUM,
  SET_RESERVES_SUM,
  SET_INTANGIBLE_SUM,
  SET_PROPERTY_SUM,
  SET_COUNTERPARTIES_SUM,
  SET_TAX_SUM,
  SET_EMPLOYEES_SUM,
  SET_CREDITORS_SUM,
  SET_ADDITIONAL_SUM,
  SET_AUTHORIZED_SUM,
} from './actions';
import {
  GET_CREDIT_HISTORY_DATA_PENDING,
  GET_CREDIT_HISTORY_DATA_SUCCESS,
  GET_DEBIT_HISTORY_DATA_PENDING,
  GET_DEBIT_HISTORY_DATA_SUCCESS,
  GET_HISTORY_JOURNAL_PENDING,
  GET_HISTORY_JOURNAL_SUCCESS,
  GET_HISTORY_POPUP_PENDING,
  GET_HISTORY_POPUP_SUCCESS,
  RESET_HISTORY_CUSTOM_FILTERS,
  RESET_HISTORY_FILTERS,
  SET_CUSTOM_HISTORY_FILTER,
  SET_HISTORY_FILTERS_PERIOD,
  SET_HISTORY_OPERATION_ID,
} from './history.actions';
import {
  AnalyticState,
  HistoryCustomFilters,
  HistoryJournal,
  StackedItemData,
} from './types';

const initialCustomFilters: HistoryCustomFilters = {
  operations: null,
  types: null,
  users: null,
  accounts: null,
};

export const initialState: AnalyticState = {
  actionsHistory: {
    historyJournal: {
      items: [],
      total: 0,
    },
    popupData: [],
    customFilters: initialCustomFilters,
    filters: initialHistoryFilters,
    operationsCount: null,
    isEmptyJournal: false,
    loading: false,
  },
  accountStatement: {
    consumption: 0,
    income: 0,
    transferFrom: 0,
    transferTo: 0,
    currencySymbol: '',
  },
  cashFlowAndPLData: {
    periodChart: {
      differences: [],
      approvedDifferences: [],
      approvedConsumptions: [],
      notApprovedConsumptions: [],
      approvedIncomes: [],
      notApprovedIncomes: [],
      months: [],
    },
    circlesCharts: {
      categories: {
        income: {
          itemsApproved: [],
          itemsNotApproved: [],
        },
        consumption: {
          itemsApproved: [],
          itemsNotApproved: [],
        },
      },
      counterparties: {
        income: {
          itemsApproved: [],
          itemsNotApproved: [],
        },
        consumption: {
          itemsApproved: [],
          itemsNotApproved: [],
        },
      },
      projects: {
        income: {
          itemsApproved: [],
          itemsNotApproved: [],
        },
        consumption: {
          itemsApproved: [],
          itemsNotApproved: [],
        },
      },
    },
    tableData: [],
    chartByMonth: {
      categories: {
        consumption: [],
        income: [],
        months: [],
      },
      counterparties: {
        consumption: [],
        income: [],
        months: [],
      },
      projects: {
        consumption: [],
        income: [],
        months: [],
      },
    },
    typeFilter: null,
  },
  debit: {
    borrowersDebit: [],
    clientsDebit: [],
    investorsDebit: [],
  },
  credit: {
    counterpartiesCredit: [],
    creditorsCredit: [],
    ownersCredit: [],
    suppliersCredit: [],
    staffMembersCredit: [],
    taxOrganisationsCredit: [],
  },
  projects: [],
  loading: false,
  loadingCircles: {
    main: false,
    income: false,
    consumption: false,
  },
  loadingStacked: {
    income: false,
    consumption: false,
  },
  loadingIncomeStacked: false,
  loadingConsumptionStacked: false,
  loadingTable: false,
  loadingErrors: {
    periodChartError: false,
    incomePieError: false,
    consumptionPieError: false,
    incomeStackedError: false,
    consumptionStackedError: false,
  },
  pieChartDataAmount: {
    incomePieAmount: null,
    consumptionPieAmount: null,
  },
  balance: {
    loading: false,
    assets: {
      customersSum: 0,
      debtorsSum: 0,
      investmentsSum: 0,

      reservesValue: 0,
      intangibleValue: 0,
      propertyValue: 0,
    },
    liabilities: {
      counterpartiesSum: 0,
      taxSum: 0,
      employeesSum: 0,
      creditorsSum: 0,

      additionalValue: 0,
      authorizedValue: 0,
    },
  },
};

const analyticReducer = (
  // eslint-disable-next-line default-param-last
  state = initialState,
  { type, payload }: AnyAction,
) => {
  switch (type) {
    case ANALYTIC_INIT: {
      return { ...initialState };
    }

    case SET_TYPE_FILTER: {
      return {
        ...state,
        cashFlowAndPLData: {
          ...state.cashFlowAndPLData,
          typeFilter: payload.data,
        },
      };
    }

    case SET_LOADING_ERROR: {
      return {
        ...state,
        loadingErrors: {
          ...state.loadingErrors,
          [payload.error]: payload.value,
        },
      };
    }

    case GET_HISTORY_POPUP_PENDING: {
      return {
        ...state,
        actionsHistory: {
          ...state.actionsHistory,
          loading: true,
        },
      };
    }

    case GET_HISTORY_POPUP_SUCCESS: {
      return {
        ...state,
        actionsHistory: {
          ...state.actionsHistory,
          popupData: payload.data.items,
          loading: false,
        },
      };
    }

    case GET_HISTORY_JOURNAL_PENDING: {
      return {
        ...state,
        actionsHistory: {
          ...state.actionsHistory,
        },
      };
    }
    case GET_HISTORY_JOURNAL_SUCCESS: {
      const { data, update }: { data: HistoryJournal; update: boolean } =
        payload;
      const loadedItems = state.actionsHistory.historyJournal.items
        ? [...state.actionsHistory.historyJournal.items]
        : [];

      const items = update ? [...loadedItems, ...data.items] : data.items;
      return {
        ...state,
        actionsHistory: {
          ...state.actionsHistory,
          historyJournal: {
            items,
            total: data.total,
          },
          loading: false,
        },
      };
    }

    case SET_HISTORY_OPERATION_ID: {
      return {
        ...state,
        actionsHistory: {
          ...state.actionsHistory,
          filters: {
            ...state.actionsHistory.filters,
            operationId: payload.operationId,
          },
        },
      };
    }

    case SET_HISTORY_FILTERS_PERIOD: {
      return {
        ...state,
        actionsHistory: {
          ...state.actionsHistory,
          filters: {
            ...state.actionsHistory.filters,
            startDate: payload.startDate,
            endDate: payload.endDate,
            id: payload.id,
          },
        },
      };
    }

    case RESET_HISTORY_FILTERS: {
      return {
        ...state,
        actionsHistory: {
          ...state.actionsHistory,
          filters: initialHistoryFilters,
          customFilters: initialCustomFilters,
        },
      };
    }

    case SET_CUSTOM_HISTORY_FILTER: {
      const { data, filterType } = payload;

      return {
        ...state,
        actionsHistory: {
          ...state.actionsHistory,
          customFilters: {
            ...state.actionsHistory.customFilters,
            [filterType]: data,
          },
        },
      };
    }

    case RESET_HISTORY_CUSTOM_FILTERS: {
      return {
        ...state,
        actionsHistory: {
          ...state.actionsHistory,
          customFilters: initialCustomFilters,
        },
      };
    }

    case GET_PROJECTS_DATA_PENDING:
    case GET_DEBIT_DATA_PENDING:
    case GET_DEBIT_HISTORY_DATA_PENDING:
    case GET_CREDIT_HISTORY_DATA_PENDING:
    case GET_CREDIT_DATA_PENDING:
    case GET_ACCOUNT_STATEMENT_PENDING:
    case GET_MAIN_CHART_PENDING: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_ACCOUNT_STATEMENT_SUCCESS: {
      return {
        ...state,
        accountStatement: payload.data,
        loading: false,
      };
    }

    case GET_CIRCLE_CHARTS_PENDING: {
      return {
        ...state,
        loadingCircles: {
          main: true,
          income: false,
          consumption: false,
        },
      };
    }

    case GET_CHARTS_PENDING: {
      return {
        ...state,
        loadingIncomeStacked: true,
        loadingConsumptionStacked: true,
      };
    }

    case GET_TABLE_DATA_PENDING: {
      return {
        ...state,
        loadingTable: true,
      };
    }

    case GET_TABLE_DATA_SUCCESS: {
      return {
        ...state,
        cashFlowAndPLData: {
          ...state.cashFlowAndPLData,
          tableData: payload.data.tableData,
        },
        loadingTable: false,
      };
    }

    case GET_MAIN_CHART_SUCCESS: {
      return {
        ...state,
        cashFlowAndPLData: {
          ...state.cashFlowAndPLData,
          periodChart: payload.data.periodChart,
        },
        loading: false,
      };
    }

    case SET_STACKED_CHART_DATA_LOADING: {
      return {
        ...state,
        loadingStacked: {
          income: true,
          consumption: true,
        },
      };
    }

    case SET_LOADING_CIRCLES: {
      return {
        ...state,
        loadingCircles: {
          ...state.loadingCircles,
          [payload.type]: payload.value,
        },
      };
    }

    case SET_LOADING_STACKED: {
      return {
        ...state,
        loadingStacked: {
          ...state.loadingStacked,
          [payload.type]: payload.value,
        },
      };
    }

    case GET_PIE_CHAR_DATA_SUCCESS: {
      const { typeFilter, data, operationType, onlyApproved } = payload;
      const errorField =
        operationType === OperationType.income
          ? `${OperationType.income}PieError`
          : `${OperationType.consumption}PieError`;

      return {
        ...state,
        cashFlowAndPLData: {
          ...state.cashFlowAndPLData,
          circlesCharts: {
            ...state.cashFlowAndPLData.circlesCharts,
            [typeFilter]: {
              // @ts-ignore
              ...state.cashFlowAndPLData.circlesCharts[typeFilter],
              [operationType]: {
                itemsApproved: data[operationType].itemsApproved,
                itemsNotApproved: onlyApproved
                  ? []
                  : data[operationType].itemsNotApproved,
              },
            },
          },
        },
        loadingCircles: {
          ...state.loadingCircles,
          [operationType]: false,
        },
        loadingErrors: {
          ...state.loadingErrors,
          [errorField]: false,
        },
      };
    }

    case GET_PIE_CHAR_DATA_AMOUNT: {
      return {
        ...state,
        pieChartDataAmount: payload,
      };
    }

    case GET_CIRCLE_CHARTS_SUCCESS: {
      return {
        ...state,
        loadingCircles: {
          ...initialState.loadingCircles,
        },
      };
    }

    case GET_STACKED_CHART_DATA_SUCCESS: {
      const { data, operationType, typeFilter, onlyApproved } = payload;
      const errorField =
        operationType === OperationType.income
          ? `${OperationType.income}StackedError`
          : `${OperationType.consumption}StackedError`;

      const formattedData = onlyApproved
        ? data[operationType].map((el: StackedItemData) => {
            const { nestedItems } = el;

            if (!nestedItems?.length) {
              return {
                ...el,
                notApprovedData: [],
              };
            }

            const formattedNestedItems = nestedItems.map((ntEl) => ({
              ...ntEl,
              notApprovedData: [],
            }));

            return {
              ...el,
              nestedItems: formattedNestedItems,
              notApprovedData: [],
            };
          })
        : data[operationType];

      return {
        ...state,
        cashFlowAndPLData: {
          ...state.cashFlowAndPLData,
          chartByMonth: {
            ...state.cashFlowAndPLData.chartByMonth,
            [typeFilter]: {
              // @ts-ignore
              ...state.cashFlowAndPLData.chartByMonth[typeFilter],
              [operationType]: formattedData,
            },
          },
        },
        loadingStacked: {
          ...state.loadingStacked,
          [operationType]: false,
        },
        loadingErrors: {
          ...state.loadingErrors,
          [errorField]: false,
        },
      };
    }

    case GET_DEBIT_DATA_SUCCESS: {
      return {
        ...state,
        debit: payload.data,
        loading: false,
      };
    }

    case GET_DEBIT_HISTORY_DATA_SUCCESS: {
      const { data } = payload;
      const dataToSave = data?.dataToSave;

      return {
        ...state,
        debit: dataToSave || data,
        loading: false,
      };
    }

    case GET_CREDIT_HISTORY_DATA_SUCCESS: {
      const { data } = payload;
      const dataToSave = data?.dataToSave;

      return {
        ...state,
        credit: dataToSave || data,
        loading: false,
      };
    }

    case GET_CREDIT_DATA_SUCCESS: {
      return {
        ...state,
        credit: payload.data,
        loading: false,
      };
    }

    case GET_PROJECTS_DATA_SUCCESS: {
      return {
        ...state,
        projects: payload.data,
        loading: false,
      };
    }

    case START_LOAD_BALANCE_DATA: {
      return {
        ...state,
        balance: {
          ...state.balance,
          loading: true,
        },
      };
    }

    case FINISH_LOAD_BALANCE_DATA: {
      return {
        ...state,
        balance: {
          ...state.balance,
          loading: false,
        },
      };
    }

    case SET_CUSTOMERS_SUM: {
      const { sum } = payload;
      return {
        ...state,
        balance: {
          ...state.balance,
          assets: {
            ...state.balance.assets,
            customersSum: sum,
          },
        },
      };
    }

    case SET_DEBTORS_SUM: {
      const { sum } = payload;
      return {
        ...state,
        balance: {
          ...state.balance,
          assets: {
            ...state.balance.assets,
            debtorsSum: sum,
          },
        },
      };
    }

    case SET_INVESTMENTS_SUM: {
      const { sum } = payload;
      return {
        ...state,
        balance: {
          ...state.balance,
          assets: {
            ...state.balance.assets,
            investmentsSum: sum,
          },
        },
      };
    }

    case SET_RESERVES_SUM: {
      const { sum } = payload;
      return {
        ...state,
        balance: {
          ...state.balance,
          assets: {
            ...state.balance.assets,
            reservesValue: sum,
          },
        },
      };
    }

    case SET_INTANGIBLE_SUM: {
      const { sum } = payload;
      return {
        ...state,
        balance: {
          ...state.balance,
          assets: {
            ...state.balance.assets,
            intangibleValue: sum,
          },
        },
      };
    }

    case SET_PROPERTY_SUM: {
      const { sum } = payload;
      return {
        ...state,
        balance: {
          ...state.balance,
          assets: {
            ...state.balance.assets,
            propertyValue: sum,
          },
        },
      };
    }

    case SET_COUNTERPARTIES_SUM: {
      const { sum } = payload;
      return {
        ...state,
        balance: {
          ...state.balance,
          liabilities: {
            ...state.balance.liabilities,
            counterpartiesSum: sum,
          },
        },
      };
    }

    case SET_TAX_SUM: {
      const { sum } = payload;
      return {
        ...state,
        balance: {
          ...state.balance,
          liabilities: {
            ...state.balance.liabilities,
            taxSum: sum,
          },
        },
      };
    }

    case SET_EMPLOYEES_SUM: {
      const { sum } = payload;
      return {
        ...state,
        balance: {
          ...state.balance,
          liabilities: {
            ...state.balance.liabilities,
            employeesSum: sum,
          },
        },
      };
    }

    case SET_CREDITORS_SUM: {
      const { sum } = payload;
      return {
        ...state,
        balance: {
          ...state.balance,
          liabilities: {
            ...state.balance.liabilities,
            creditorsSum: sum,
          },
        },
      };
    }

    case SET_ADDITIONAL_SUM: {
      const { sum } = payload;
      return {
        ...state,
        balance: {
          ...state.balance,
          liabilities: {
            ...state.balance.liabilities,
            additionalValue: sum,
          },
        },
      };
    }

    case SET_AUTHORIZED_SUM: {
      const { sum } = payload;
      return {
        ...state,
        balance: {
          ...state.balance,
          liabilities: {
            ...state.balance.liabilities,
            authorizedValue: sum,
          },
        },
      };
    }

    default:
      return state;
  }
};

export default analyticReducer;
