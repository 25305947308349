import Http from '../../services/Http';

export const getCalendar = (
  year: number,
  month: number,
  ordersQuery: string,
  customFiltersQuery: string,
  offset: number,
  limit: number,
) =>
  Http.makeInstance().post('analytics/calendar', {
    query: `year=${year}&month=${month}&offset=${offset}&limit=${limit}&${ordersQuery}&${customFiltersQuery}`,
  });

export default {
  getCalendar,
};
