import { Location } from 'history';

import { AccountValue } from '../../../store/accounts/types';
import { Category, SubCategory } from '../../../store/categories/types';
import { Client } from '../../../store/clients/types';
import { Company } from '../../../store/company/types';
import { Currency } from '../../../store/currency/types';
import { JournalData } from '../../../store/journal/types';
import {
  OperationSubType,
  OperationType,
  ResponseData,
} from '../../../store/operations/types';
import { Project } from '../../../store/projects/types';
import { Tag } from '../../../store/tags/types';
import { AutoCompleteProps } from '../../../store/types';
import { OtherPayloadProps } from '../Components/Repeat/OthersDialog.types';
import { SplitPaymentProjectInfo } from '../Components/SplittingPayment/types';

export enum CustomPeriodEnum {
  prevMonth = 'prevMonth',
  currentMonth = 'currentMonth',
  custom = 'custom',
}

export const CustomPeriodID = [
  '',
  CustomPeriodEnum.prevMonth,
  CustomPeriodEnum.currentMonth,
  CustomPeriodEnum.custom,
];

export type OperationToTransferProps = {
  date: number;
  amount: number;
  accountToId: string;
  amountTo: number | undefined;
  tagIds: string[];
  accountFromId: string;
  comment: string;
  projectId: string | undefined;
};

export type OwnProps = {
  botId?: string;
  externalId?: string;
  integrationId?: string;
  type: OperationType;
  isCopy: boolean;
  createAndCopyState: boolean;
  startTimestamp: number | undefined;
  endTimeStamp: number | undefined;
  initialScheduled: boolean;
  repeats: FullPeriod;
  amount: string | null;
  currencyAmount: string | null;
  exchangeRate: string | null;
  account: AccountValue | null;
  toAccount: AccountValue | null;
  client: Client | null;
  selectedTags: any[] | null;
  comments: string;
  project: Project | null;
  courses: any;
  companyCurrencySymbol: string | undefined;
  category: Category | SubCategory | null;
  incomeDate: number;
  sellDate: number | number[];
  salaryPeriodId: CustomPeriodEnum | null;
  taxPeriodId: CustomPeriodEnum | null;
  editProps?: JournalData;
  isNewState?: boolean;
  isEdit?: boolean;
  isCompare?: boolean;
  isGeneric?: boolean;
  difference?: JournalData;
  editOperationId: string | null;
  currency: (AutoCompleteProps & { symbol: string; isCrypto?: boolean }) | null;
  reportType?: string;
  location: Location;
  isNewOperation?: boolean;

  onRemoveOperation(scheduled?: boolean): void;

  changeCurrency(value: AutoCompleteProps & { symbol: string }): void;

  changeIsCopyComponent(value: boolean): void;
  changeAmount(value: number): void;
  changeCurrencyAmount(value: number): void;
  changeExchangeRateAmount(value: number): void;
  changeAccount(id: string): void;
  changeToAccount(id: string): void;
  changeClient(id: string): void;
  changeCategory(id: string, type: OperationType): void;
  changeIncomeDate(value: number): void;
  changeRepeats(value: FullPeriod): void;
  handleChangeSplitPaymentProjects(projects: SplitPaymentProjectInfo[]): void;
  changeComments(value: string): void;
  changeTags(tags: any[] | null): void;
  changeSalaryPeriod(id: CustomPeriodEnum): void;
  changeTaxPeriod(id: CustomPeriodEnum): void;

  onCreateOperation(
    scheduled: boolean,
    prop:
      | OperationSubType
      | 'incomeLoanRepayment'
      | 'incomeLoan'
      | 'consumptionLoanRepayment'
      | 'consumptionLoan'
      | 'transfer',
    closeDialog?: boolean,
  ): void;

  onCloseDialog(): void;
  handleOperationToTransfer(id: string, data: OperationToTransferProps): void;

  onChangeDateOfPayment(value: number): void;
  onChangeDateOfPaymentPeriod(start: number, end: number): void;
  onChangeDateOfPaymentPeriodId(value: CustomPeriodEnum): void;
  onSetOperationType(value: OperationType): void;
  onSetSellDateWasChanged(): void;
};

export type SelectedProps = {
  isCopyComponent: boolean;
  createAndCopyState: boolean;
  creatingClientInProgress: boolean;
  creatingIncomeCategoryInProgress: boolean;
  creatingConsumptionCategoryInProgress: boolean;
  creatingProjectInProgress: boolean;
  accounts: AccountValue[];
  company: Company | null;
  clients: Client[];
  investors: Client[];
  creditors: Client[];
  borrowers: Client[];
  owners: Client[];
  suppliers: Client[];
  staffMembers: Client[];
  taxOrganisations: Client[];
  projects: Project[];
  categories: (Category | SubCategory)[];
  consumptionCategories: (Category | SubCategory)[];
  tags: Tag[];
  companyCurrencySymbol: string | undefined;
  exchangeRateFromTo: number | null;
  allClients: Client[];
  companyCurrency: Currency | undefined;
};

export type ActionsProps = {
  createCategory(name: string): void;
  createConsumptionCategories(name: string): void;
  createTag(name: string): void;
  createProject(name: string): void;

  createClient(name: string): void;
  createInvestor(name: string): void;
  createCreditor(name: string): void;
  createBorrower(name: string): void;
  createOwner(name: string): void;
  createSupplier(name: string): void;
  createStaffMember(name: string): void;
  createTaxOrganisation(name: string): void;

  removeOperation(ids: string, scheduled: boolean): void;

  setIsCopyComponent(value: boolean): void;
  changeToTransfer(): void;
};

export type Props = SelectedProps & ActionsProps & OwnProps;

export type MainPayload = Omit<
  ResponseData,
  'dateOfPayment' | 'categoryId' | 'clientId' | 'companyId'
>;

export type SceneProps = {
  isEdit?: boolean;
  isNewState?: boolean;
  isCompare?: boolean;
  editProps?: JournalData;
  subType: OperationSubType;
  difference?: JournalData;
  isGeneric?: boolean;

  onCloseDialog(): void;
};

export type FullPeriod = {
  id: number;
  payload?: OtherPayloadProps;
};

export type CalculateSumAndCompanyCurrencySum = {
  isCrypto: boolean;
  account: AccountValue | null;
  currencyValue: (AutoCompleteProps & { symbol: string }) | null;
  amount: string | null;
  currencyAmount: string | null;
  companyCurrency: Currency | undefined;
  exchangeRate: string | null;
  revertState: boolean;
  isEdit: boolean;
  editProps?: JournalData;
  type?: OperationType | null;
};
