import Http from './Http';

const getSubscriptionUrl = (tariffId: string, period: number) => {
  return Http.makeInstance().post('/subscriptions', {
    tariffId,
    period,
  });
};

const getSubscriptionUrlForActiveTariff = () =>
  Http.makeInstance().get('/subscriptions/payNow');

const getInvoiceLink = () =>
  Http.makeInstance().get('/subscriptions/invoiceLink');

const cancelSubscription = (tariffId: string) =>
  Http.makeInstance().delete(`/tariffs/cancel/${tariffId}`);

export default {
  getSubscriptionUrl,
  cancelSubscription,
  getSubscriptionUrlForActiveTariff,
  getInvoiceLink,
};
