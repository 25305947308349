import i18next from 'i18next';
import { isEqual } from 'lodash';
import moment from 'moment';
import { createSelector } from 'reselect';

import {
  selectAllCategoriesCount,
  selectAllPLCategoriesCount,
  selectPLConsumptionCategoriesIds,
  selectPLIncomeCategoriesIds,
} from '../categories/selectors';
import { PeriodId } from '../journal/types';
import { AppState } from '../reducers';
import { initialFiltersState } from './reducer';
import { FiltersState } from './types';

export const getLanguage = (state: AppState) => state.company.lng;

export const getFilters = (state: AppState) => state.filters;

export const getFilterNameById = (_id: string) =>
  createSelector(getFilters, (filters) => {
    const filter = filters.userFilters.find((el) => el._id === _id);
    return filter?.name;
  });

export const selectFilters = (
  selector: keyof FiltersState,
  customFilter?: string,
) =>
  createSelector(getFilters, (filters) =>
    // @ts-ignore
    customFilter ? filters[selector][customFilter] : filters[selector].filters,
  );

export const selectJournalFilters = createSelector(
  getFilters,
  (filters) => filters.journal,
);

export const selectJournalCustomFilters = createSelector(
  getFilters,
  (filters) => filters.journal.customFilters,
);

export const selectCustomFilters = (selector: keyof FiltersState) =>
  createSelector(
    getFilters,
    (filters) =>
      // @ts-ignore
      filters[selector].customFilters,
  );

export const selectAdditionalPeriods = createSelector(
  getLanguage,
  (language) => {
    const i18 = i18next.getFixedT(language);

    return [
      {
        id: PeriodId.currentYear,
        name: i18('filter.currentYear'),
        value: [
          moment.utc().startOf('year').valueOf(),
          moment.utc().endOf('year').valueOf(),
        ],
      },
      {
        id: PeriodId.prevYear,
        name: i18('filter.prevYear'),
        value: [
          moment.utc().subtract(1, 'year').startOf('year').valueOf(),
          moment.utc().subtract(1, 'year').endOf('year').valueOf(),
        ],
      },
    ];
  },
);

export const selectPeriods = createSelector(getLanguage, (language) => {
  const i18 = i18next.getFixedT(language);

  return [
    {
      id: PeriodId.currentWeek,
      name: i18('filter.currentWeek'),
      value: [
        moment.utc().startOf('week').valueOf(),
        moment.utc().endOf('week').valueOf(),
      ],
    },
    {
      id: PeriodId.prevWeek,
      name: i18('filter.prevWeek'),
      value: [
        moment.utc().startOf('week').subtract(7, 'days').startOf('day').unix() *
          1000,
        moment.utc().startOf('week').subtract(1, 'days').endOf('day').valueOf(),
      ],
    },
    {
      id: PeriodId.currentMonth,
      name: i18('filter.currentMonth'),
      value: [
        moment.utc().startOf('month').valueOf(),
        moment.utc().endOf('month').valueOf(),
      ],
    },
    {
      id: PeriodId.prevMonth,
      name: i18('filter.prevMonth'),
      value: [
        moment.utc().subtract(1, 'months').startOf('month').valueOf(),
        moment.utc().subtract(1, 'months').endOf('month').valueOf(),
      ],
    },
    {
      id: PeriodId.allTime,
      name: i18('filter.allTime'),
      value: [undefined, undefined],
    },
  ];
});

export const selectCustomFiltersProjects = (selector: keyof FiltersState) =>
  createSelector(
    getFilters,
    (filters) =>
      // @ts-ignore
      filters[selector].customFilters.projects,
  );

export const selectCustomFiltersTags = (selector: keyof FiltersState) =>
  createSelector(
    getFilters,
    (filters) =>
      // @ts-ignore
      filters[selector].customFilters.tags,
  );

export const selectCustomFilterAccounts = (selector: keyof FiltersState) =>
  createSelector(
    getFilters,
    (filters) =>
      // @ts-ignore
      filters[selector].customFilters.accounts,
  );

export const selectFilterSearch = createSelector(
  selectJournalFilters,
  (filters) => filters.search,
);

export const selectFilterTagsSearch = createSelector(
  selectJournalFilters,
  (filters) => filters.tags,
);

export const selectRangeSearchFrom = createSelector(
  selectJournalFilters,
  (filters) => filters.rangeFrom,
);

export const selectRangeSearchTo = createSelector(
  selectJournalFilters,
  (filters) => filters.rangeTo,
);

export const selectFilterPeriod = (selector: keyof FiltersState) =>
  createSelector(getFilters, (filters) => ({
    // @ts-ignore
    startDate: filters[selector].filters.startDate,
    // @ts-ignore
    endDate: filters[selector].filters.endDate,
    // @ts-ignore
    id: filters[selector].filters.id,
  }));

export const selectCustomFilterCategoriesByType = (
  selector: keyof FiltersState,
) =>
  createSelector(
    getFilters,
    (filters) =>
      // @ts-ignore
      filters[selector].customFilters.categoriesByType,
  );

export const selectCustomFilterCategoriesByIds = (
  selector: keyof FiltersState,
) =>
  createSelector(
    getFilters,
    (filters) =>
      // @ts-ignore
      filters[selector].customFilters.categoriesByIds,
  );

export const selectCustomFilterClients = (selector: keyof FiltersState) =>
  createSelector(
    getFilters,
    (filters) =>
      // @ts-ignore
      filters[selector].customFilters.clients,
  );

export const isSearchFilterActive = createSelector(
  selectFilterSearch,
  selectFilterTagsSearch,
  (searchValue, tags) => Boolean(searchValue) || Boolean(tags.length),
);

export const isRangeFilterActive = createSelector(
  selectRangeSearchFrom,
  selectRangeSearchTo,
  (from, to) => from !== null || to !== null,
);

export const isPeriodFilterActive = (selector: keyof FiltersState) =>
  createSelector(getFilters, (allFilters) => {
    // @ts-ignore
    const { filters } = allFilters[selector];
    // @ts-ignore
    const { filters: initialFilters } = initialFiltersState[selector];

    return !isEqual(filters, initialFilters);
  });

export const isCustomFiltersActive = (selector: keyof FiltersState) =>
  createSelector(
    getFilters,
    selectAllCategoriesCount,
    selectAllPLCategoriesCount,
    (allFilters, allCategoriesCount, allPLCategoriesCount) => {
      // @ts-ignore
      const { customFilters } = allFilters[selector];
      // @ts-ignore
      const { customFilters: initialCustomFilters } =
        initialFiltersState[selector];

      const { categoriesByIds, status, ...rest } = customFilters;
      const {
        categoriesByIds: initialCategoriesByIds,
        status: initialStatus,
        ...initialRest
      } = initialCustomFilters;

      const count =
        selector === 'profitAndLoss' || selector === 'tableProfitAndLoss'
          ? allPLCategoriesCount
          : allCategoriesCount;

      return (
        !isEqual(rest, initialRest) ||
        (!!categoriesByIds.length && categoriesByIds.length !== count)
      );
    },
  );

export const selectPLCategoriesIds = createSelector(
  selectPLIncomeCategoriesIds,
  selectPLConsumptionCategoriesIds,
  (income, consumption) => income.concat(consumption),
);
