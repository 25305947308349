import { ClickAwayListener, Grow, Paper, Popper } from '@material-ui/core';
import cn from 'classnames';
import React, { useCallback, useEffect, useRef } from 'react';

import { useStyles } from './styles';
import { Props } from './types';

function PopperComponent(props: Props) {
  const {
    id,
    width,
    onClick,
    onClose,
    children,
    anchorRef,
    paperClasses,
    disablePortal = true,
    placement: injectPlacement,
  } = props;

  const classes = useStyles({
    width,
  });

  const popperRef = useRef<HTMLDivElement>(null);

  const handleClose = useCallback(
    (event: any) => {
      onClose(event);
    },
    [onClose],
  );

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      // @ts-ignore
      if (popperRef?.current && !popperRef.current.contains(event.target)) {
        onClose();
      }
    }

    document.addEventListener('mousedown', handleClickOutside, true);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside, true);
    };
  }, [onClose]);

  return (
    <Popper
      id={id}
      open
      ref={popperRef}
      anchorEl={anchorRef?.current}
      role={undefined}
      transition
      onClick={onClick}
      disablePortal={disablePortal}
      placement={injectPlacement}
      className={classes.popper}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === 'bottom' ? 'center top' : 'center bottom',
          }}
        >
          <Paper className={cn(classes.ownDropdownContainer, paperClasses)}>
            <ClickAwayListener onClickAway={handleClose}>
              <div>{children}</div>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}

export default React.memo(PopperComponent);
