import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  Config,
} from '@finmap/import-parsers/base-import-parser-v3';

export class MonoBusinessParser extends BaseImportParserV3 {
  // public debug: boolean = true;
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.XLSX]: [
      {
        caseOptions: {
          defaultCurrency: 'UAH',
          withoutEmpty: true,
          isCurCase: [
            [
              0,
              {
                in: 'Список транзакцій за рахунком',
              },
            ],
          ],
          proceedCaseConfig: {
            withoutEmpty: true,
            delete: [
              {
                to: [
                  [
                    0,
                    {
                      eq: 'Дата \nоперації',
                    },
                  ],
                  [
                    1,
                    {
                      eq: 'Час \nоперації',
                    },
                  ],
                ],
                count: 1,
              },
            ],
            fields: {
              sum: {
                column: ['Сума в валюті \nрахунку', 7],
              },
              date: {
                column: ['Дата \nоперації', 0],
              },
              time: {
                column: ['Час \nоперації', 1],
              },
              comment: {
                column: ['Деталі операції', 3],
              },
              counterparty: {
                column: ['Контрагент', 4],
              },
            },
          },
        },
      },
      {
        caseOptions: {
          defaultCurrency: 'UAH',
          withoutEmpty: true,
          isCurCase: [
            [
              0,
              {
                in: '',
              },
            ],
          ],
          proceedCaseConfig: {

            delete: [
              {
                to: [
                  [
                    0,
                    {
                      eq: 'Дата \nоперації',
                    },
                  ],
                  [
                    1,
                    {
                      eq: 'Час \nоперації',
                    },
                  ],
                ],
                count: 1,
              },
            ],
            fields: {
              sum: {
                column: ['Сума в валюті \nрахунку', 7],
              },
              date: {
                column: ['Дата \nоперації', 0],
              },
              time: {
                column: ['Час \nоперації', 1],
              },
              comment: {
                column: ['Деталі операції', 3],
              },
              counterparty: {
                column: ['Контрагент', 4],
              },
            },
          },
        },
      },
    ],
    [AVAILABLE_IMPORT_TYPES.PDF]: [
      {
        caseOptions: {
          defaultCurrency: 'UAH',
          isCurCase: [
            [
              0,
              {
                eq: 'АТ "УНІВЕРСАЛ БАНК"',
              },
            ],
          ],
          preParserConfigs: {
            verticalAlign: 'middle',
            interlineSpacingAccuracy: 5,
            prepareRawConfig: {
              findHeader: {
                from: [
                  {
                    word: {
                      eq: 'Сума у',
                    },
                    nextWord: {
                      eq: 'валюті',
                    },
                  },
                ],
                to: [
                  {
                    word: {
                      eq: 'зарахувань)',
                    },
                    prevWord: {
                      eq: '(для',
                    },
                  },
                ],
              },
              delete: [
                {
                  to: [
                    {
                      word: {
                        eq: 'Сума у',
                      },
                      nextWord: {
                        eq: 'валюті',
                      },
                    },
                  ],
                  count: 1,
                },
                {
                  from: [
                    {
                      word: {
                        eq: 'Операційний директор',
                      },
                    },
                  ],
                  count: 1,
                },
              ],
              define: [
                {
                  dateFormat: 'DD.MM.YYYY',
                },
              ],
            },
          },
          proceedCaseConfig: {
            fields: {
              dateAndTime: {
                column: ['Дата та час\nоперації', 0],
                replace: [
                  {
                    from: '\n',
                    to: ' ',
                  },
                ],
              },
              comment: {
                column: ['Деталі операції', 1],
                add: [
                  ' ',
                  {
                    column: ['Реквізити контрагента', 2],
                  },
                ],
              },
              sum: {
                column: [
                  'Сума у\nвалюті\nрахунку /\nЕквівалент\nсуми за\nкурсом НБУ на\nдату операції\n(для\nзарахувань)',
                  5,
                ],
              },
            },
          },
        },
      },
    ],
  };
}
