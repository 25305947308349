import { SagaIterator } from 'redux-saga';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { LIMIT_ITEMS_PER_PAGE, usersIdFiltersFeature } from '../../constants';
import { TAction } from '../../sagas/types';
import { showError } from '../../utils/showError';
import { GET_LOG_ACCOUNTS_FOR_FILTERS_PENDING } from '../accounts/actions';
import { getUser, isLogEnable } from '../auth/selectors';
import { User } from '../auth/types';
import { categoryActionTypes } from '../categories/actions';
import { getClientsByTypeLog } from '../clients/sagas';
import { UrlPrefix } from '../clients/types';
import { selectCustomFilters, selectFilters } from '../filters/selectors';
import { FiltersState, PeriodsFilterTypes } from '../filters/types';
import { GET_LOG_PROJECTS_PENDING } from '../projects/actions';
import { GET_LOG_TAGS_PENDING } from '../tags/actions';
import {
  GET_FUTURE_JOURNAL_PENDING,
  GET_FUTURE_JOURNAL_SUCCESS,
  GET_FUTURE_PAYMENTS_LENGTH_PENDING,
  GET_FUTURE_PAYMENTS_LENGTH_SUCCESS,
  GET_JOURNAL_LOAD_MORE_PENDING,
  GET_JOURNAL_OPERATIONS_COUNT_PENDING,
  GET_JOURNAL_OPERATIONS_COUNT_SUCCESS,
  GET_JOURNAL_PENDING,
  GET_JOURNAL_SUCCESS,
  GET_LOG_CONTEXT,
  JOURNAL_INIT,
  RESET_FUTURE_JOURNAL,
  RESET_JOURNAL_LOADING,
  SET_IS_APPROVED_FUTURE,
} from './actions';
import api from './api';
import {
  GetJournalPayload,
  LoadFutureJournalPayload,
  LoadMorePayload,
} from './sagas.types';
import {
  createBaseQuery,
  createBaseQueryObj,
  getIsApprovedFuture,
  getShowFuturePayments,
  selectFutureItems,
  selectJournalItemsCount,
} from './selectors';
import { Journal, LogRequestBody, PeriodId } from './types';

function* getLogContext(action: TAction<{ selector: keyof FiltersState }>) {
  try {
    const { selector } = action.payload;

    yield put({ type: GET_LOG_ACCOUNTS_FOR_FILTERS_PENDING });
    yield put({ type: GET_LOG_PROJECTS_PENDING });
    yield put({ type: GET_LOG_TAGS_PENDING });
    yield put({
      type: categoryActionTypes.GET_LOG_CATEGORIES_PENDING,
      payload: { selector },
    });

    yield all(
      Object.keys(UrlPrefix).map((prefix) =>
        // @ts-ignore
        getClientsByTypeLog({ payload: { prefix } }),
      ),
    );
  } catch (error) {
    showError(error);
  }
}

export function* loadFutureJournal(
  action: TAction<LoadFutureJournalPayload>,
): SagaIterator {
  try {
    const { selector } = action.payload;
    const user: User = yield select(getUser);
    const isTestFiltersUser = usersIdFiltersFeature.includes(user?._id);

    const dateFilter: PeriodsFilterTypes = yield select(
      selectFilters(selector, 'filters'),
    );

    const futureDateFilter = yield select(
      selectFilters(selector, 'futureFilter'),
    );

    if (isTestFiltersUser) {
      const logObjRequest: LogRequestBody = yield select(
        createBaseQueryObj(selector),
      );

      if (dateFilter.id === PeriodId.allTime) {
        Object.assign(logObjRequest, futureDateFilter);
      } else {
        Object.assign(logObjRequest, dateFilter);
      }

      logObjRequest.approved = false;
      logObjRequest.offset = 0;
      logObjRequest.limit = 100000;

      const { data }: { data: Journal } = yield call(
        api.getJournalObj,
        logObjRequest,
      );
      yield put({
        type: GET_FUTURE_JOURNAL_SUCCESS,
        payload: { data },
      });
    } else {
      const { query, ordersQuery, customFiltersQuery } = yield select(
        createBaseQuery(selector),
      );

      const datesFilter =
        dateFilter.id === PeriodId.allTime ? futureDateFilter : dateFilter;

      const journalQuery =
        query +
          Object?.keys(datesFilter ?? {})
            .map((filter) =>
              // @ts-ignore
              datesFilter[filter] !== undefined
                ? // @ts-ignore
                  `${filter}=${datesFilter[filter]}`
                : '',
            )
            .join('&') || '';

      const { data }: { data: Journal } = yield call(
        api.getJournal,
        journalQuery,
        ordersQuery,
        `${customFiltersQuery}&approved=false`,
        0,
        100000,
      );
      yield put({
        type: GET_FUTURE_JOURNAL_SUCCESS,
        payload: { data },
      });
    }
  } catch (error) {
    showError(error);
  }
}

function* loadMoreJournal(action: TAction<LoadMorePayload>): SagaIterator {
  try {
    const { selector = 'journal', startIndex } = action.payload;
    const user: User = yield select(getUser);
    const isTestFiltersUser = usersIdFiltersFeature.includes(user?._id);

    const showFuturePayments = yield select(getShowFuturePayments);
    const loadedFuturePayments = yield select(selectFutureItems);

    if (isTestFiltersUser) {
      const logObjRequest: LogRequestBody = yield select(
        createBaseQueryObj(selector),
      );

      logObjRequest.approved = true;
      logObjRequest.offset = showFuturePayments
        ? startIndex - loadedFuturePayments.length
        : startIndex;
      logObjRequest.limit = LIMIT_ITEMS_PER_PAGE;

      const { data }: { data: Journal } = yield call(
        api.getJournalObj,
        logObjRequest,
      );

      yield put({ type: GET_JOURNAL_SUCCESS, payload: { data } });
    } else {
      const { query, ordersQuery, customFiltersQuery, filters } = yield select(
        createBaseQuery(selector),
      );

      const journalQuery =
        query +
          Object?.keys(filters)
            .map((filter) =>
              filters[filter] !== undefined
                ? `${filter}=${filters[filter]}`
                : '',
            )
            .join('&') || '';

      const { data }: { data: Journal } = yield call(
        api.getJournal,
        journalQuery,
        ordersQuery,
        `${customFiltersQuery}&approved=true`,
        showFuturePayments
          ? startIndex - loadedFuturePayments.length
          : startIndex,
        LIMIT_ITEMS_PER_PAGE,
      );

      yield put({ type: GET_JOURNAL_SUCCESS, payload: { data } });
    }
  } catch (error) {
    showError(error);
  }
}

export function* loadJournal(action: TAction<GetJournalPayload>): SagaIterator {
  try {
    const selector = action?.payload?.selector || 'journal';
    const update = action?.payload?.update;
    const isCreated = action?.payload?.isCreated;
    const onlyFuture = !!action?.payload?.onlyFuture;

    yield put({
      type: GET_FUTURE_PAYMENTS_LENGTH_PENDING,
      payload: { selector },
    });

    const disableLoadFuturePayments =
      action?.payload?.disableLoadFuturePayments;

    let logCount;

    try {
      const { data: logCountResponse } = yield call(api.getOperationsCount);
      logCount = logCountResponse;
    } catch (e) {
      logCount = window.location.hostname.includes('preview') ? 1 : 0;
    }

    yield put({
      type: GET_JOURNAL_OPERATIONS_COUNT_SUCCESS,
      payload: { data: { logCount: !!logCount } },
    });

    const init = action?.payload?.init;

    const isApprovedFuture = yield select(getIsApprovedFuture);
    const itemsCount = yield select(selectJournalItemsCount);
    const { status } = yield select(selectCustomFilters(selector));

    if (init !== false && !isApprovedFuture) {
      yield put({ type: JOURNAL_INIT });
    }

    const logEnable = yield select(isLogEnable);

    if (!logEnable) {
      return;
    }

    const user: User = yield select(getUser);
    const isTestFiltersUser = usersIdFiltersFeature.includes(user?._id);

    const logObjRequest: LogRequestBody = yield select(
      createBaseQueryObj(selector),
    );
    const { query, ordersQuery, customFiltersQuery, filters } = yield select(
      createBaseQuery(selector),
    );
    const journalQuery =
      query +
        Object?.keys(filters)
          .map((filter) =>
            filters[filter] !== undefined ? `${filter}=${filters[filter]}` : '',
          )
          .join('&') || '';

    if (!disableLoadFuturePayments) {
      if (isApprovedFuture) {
        yield put({
          type: GET_FUTURE_JOURNAL_PENDING,
          payload: { selector, update },
        });
      } else {
        yield put({
          type: GET_FUTURE_JOURNAL_PENDING,
          payload: { selector },
        });
      }
    } else {
      yield put({
        type: RESET_FUTURE_JOURNAL,
        payload: { data: { items: [] } },
      });
    }

    const currenLoadedItems = isCreated ? itemsCount + 1 : itemsCount;

    if (!logCount) {
      yield put({ type: RESET_JOURNAL_LOADING });
    } else if (isTestFiltersUser) {
      logObjRequest.approved = true;
      logObjRequest.limit = update ? currenLoadedItems : LIMIT_ITEMS_PER_PAGE;

      const { data }: { data: Journal } = yield call(
        api.getJournalObj,
        logObjRequest,
      );

      yield put({ type: GET_JOURNAL_SUCCESS, payload: { data, update } });
    } else if (
      ['journal', 'calendar', 'debit', 'credit', 'statement'].includes(
        selector,
      ) ||
      status
    ) {
      let data: Journal = { futureItems: [], items: [], total: 0 };
      if (!onlyFuture) {
        const result: { data: Journal } = yield call(
          api.getJournal,
          journalQuery,
          ordersQuery,
          `${customFiltersQuery}&approved=true`,
          0,
          update ? currenLoadedItems : LIMIT_ITEMS_PER_PAGE,
        );
        data = result.data;
      }

      yield put({ type: GET_JOURNAL_SUCCESS, payload: { data, update } });
    }

    yield put({
      type: SET_IS_APPROVED_FUTURE,
      payload: { isApprovedFuture: false },
    });
  } catch (error) {
    showError(error);
  }
}

function* getOperationsCount() {
  try {
    const { data: logCount } = yield call(api.getOperationsCount);

    yield put({
      type: GET_JOURNAL_OPERATIONS_COUNT_SUCCESS,
      payload: { data: { logCount: Boolean(logCount) } },
    });
  } catch (error) {
    showError(error);
  }
}

function* getFuturePaymentsAmount(
  action: TAction<{ selector: keyof FiltersState }>,
) {
  try {
    const user: User = yield select(getUser);

    const isTestFiltersUser = usersIdFiltersFeature.includes(user?._id);

    if (isTestFiltersUser) {
      const logObjRequest: LogRequestBody = yield select(
        createBaseQueryObj(action.payload.selector),
      );

      logObjRequest.approved = false;
      logObjRequest.offset = 0;
      logObjRequest.limit = 0;

      const { data }: { data: Journal } = yield call(
        api.getJournalObj,
        logObjRequest,
      );

      yield put({
        type: GET_FUTURE_PAYMENTS_LENGTH_SUCCESS,
        payload: { totalFutureAmount: data.total },
      });
    } else {
      const { query, filters, customFiltersQuery } = yield select(
        createBaseQuery(action.payload.selector),
      );

      const journalQuery =
        query +
          Object?.keys(filters)
            .map((filter) =>
              filters[filter] !== undefined
                ? `${filter}=${filters[filter]}`
                : '',
            )
            .join('&') || '';

      const { data }: { data: Journal } = yield call(
        api.getJournal,
        journalQuery,
        customFiltersQuery,
        '&approved=false',
        0,
        0,
      );

      yield put({
        type: GET_FUTURE_PAYMENTS_LENGTH_SUCCESS,
        payload: { totalFutureAmount: data.total },
      });
    }
  } catch (error) {
    showError(error);
  }
}

export default function journal() {
  return all([
    takeLatest(GET_JOURNAL_PENDING, loadJournal),
    takeLatest(GET_JOURNAL_LOAD_MORE_PENDING, loadMoreJournal),
    takeLatest(GET_FUTURE_JOURNAL_PENDING, loadFutureJournal),
    takeLatest(GET_JOURNAL_OPERATIONS_COUNT_PENDING, getOperationsCount),
    takeLatest(GET_LOG_CONTEXT, getLogContext),
    takeLatest(GET_FUTURE_PAYMENTS_LENGTH_PENDING, getFuturePaymentsAmount),
  ]);
}
