import { Typography } from '@material-ui/core';
import cn from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Dialog from '../../../components/Dialog/Dialog';
import useOnboardingV2 from '../../../hooks/useOnboardingV2';
import operationActions from '../../../store/operations/actions';
import {
  OperationSubType,
  OperationType,
} from '../../../store/operations/types';
import ConsumptionComponent from '../../Operations/Consumptions';
import IncomeComponent from '../../Operations/Incomes';
import TransferScene from '../../Operations/Transfers';
import RenderBadge from './RenderBadge';
import { useStyles } from './styles';
import { Props } from './types';

function OperationsDialog(props: Props) {
  const {
    type,
    isEdit,
    onClose,
    editProps,
    isGeneric,
    reportType,
    isNewOperation,
  } = props;

  const [stateType, setStateType] = useState(type);

  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { onDeleteIntegrationStep, isIntegrationActiveStep } =
    useOnboardingV2();

  const handleClose = useCallback(
    (byUser: boolean) => {
      dispatch(operationActions.resetOperationProps());

      if (isIntegrationActiveStep && byUser) {
        onDeleteIntegrationStep();
      }

      onClose();
    },
    [dispatch, isIntegrationActiveStep, onClose, onDeleteIntegrationStep],
  );

  const copyEditProps = useRef(editProps);

  const handleChangeToTransfer = useCallback(() => {
    setStateType(OperationType.transfer);

    dispatch(operationActions.setAttachments([]));

    if (copyEditProps.current) {
      copyEditProps.current.attachments = [];
      copyEditProps.current.isTransformToTransfer = true;

      if (stateType === OperationType.income) {
        copyEditProps.current.isTransformFromIncome = true;
      }
    }

    if (
      editProps &&
      editProps.projectObjects &&
      Object.keys(editProps.projectObjects).length > 1
    ) {
      dispatch(
        operationActions.setSplitPaymentProjects({ splitPaymentProjects: [] }),
      );

      if (copyEditProps.current) {
        copyEditProps.current.projectObjects = {};
      }
    }

    if (copyEditProps.current) {
      copyEditProps.current.scheduled = false;
      copyEditProps.current.schedulePeriodId = 1000;
    }
  }, [dispatch, editProps, stateType]);

  const Component = useCallback(() => {
    switch (stateType) {
      case OperationType.income: {
        return (
          <IncomeComponent
            isGeneric={isGeneric}
            onCloseDialog={handleClose}
            editProps={editProps}
            isEdit={isEdit}
            changeToTransfer={handleChangeToTransfer}
            isNewOperation={isNewOperation}
          />
        );
      }
      case OperationType.consumption: {
        return (
          <ConsumptionComponent
            isGeneric={isGeneric}
            onCloseDialog={handleClose}
            editProps={editProps}
            isEdit={isEdit}
            reportType={reportType}
            changeToTransfer={handleChangeToTransfer}
            isNewOperation={isNewOperation}
          />
        );
      }
      case OperationType.transfer: {
        return (
          <TransferScene
            type={OperationType.transfer}
            onCloseDialog={handleClose}
            editProps={copyEditProps.current}
            isEdit={isEdit}
          />
        );
      }
      default:
        return null;
    }
  }, [
    stateType,
    isGeneric,
    handleClose,
    editProps,
    isEdit,
    handleChangeToTransfer,
    reportType,
    isNewOperation,
  ]);

  const RenderTitle = useCallback(() => {
    if (!isEdit) {
      return (
        <Typography className={classes.title}>
          {t(`operation.new.${type}`)}
        </Typography>
      );
    }

    return (
      <div className={classes.titleContainer}>
        <Typography className={cn(classes.title, classes.marginRight12)}>
          {t(`operation.${stateType}`)}
        </Typography>
        {isEdit && !!editProps && <RenderBadge editProps={editProps} />}
      </div>
    );
  }, [isEdit, classes, t, stateType, editProps, type]);

  useEffect(() => {
    if (!isEdit) {
      dispatch(
        operationActions.getOperationItems({
          type,
          subType:
            type === OperationType.income
              ? OperationSubType.sale
              : OperationSubType.supplier,
        }),
      );

      dispatch(operationActions.getSettingsCategories());
    }
  }, [type, dispatch, isEdit]);

  return (
    <Dialog isOpened onClose={handleClose} title={<RenderTitle />}>
      <Component />
    </Dialog>
  );
}

export default React.memo(OperationsDialog);
