import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@material-ui/core';

import CustomButton from '../../../components/Button';
import Dialog from '../../../components/Dialog/Dialog';
import Loader from '../../../components/Loader/LottieLoader';
import FacebookPixelContext from '../../../Providers/FacebookFixelProvider/FacebookPixelContext';
import accountsApi from '../../../services/accounts';
import belvoApi from '../../../services/bankIntegrations/belvo';
import Storages, { StorageKey } from '../../../services/Storages';
import { selectCurrentCompany } from '../../../store/company/selectors';
import belvoActions from '../../../store/integrations/belvoActions';
import { CreateIntegrationPayload } from '../../../store/integrations/sagas.types';
import {
  BelvoAccount,
  IntegrationTypeId,
} from '../../../store/integrations/types';
import { getTimeOffset } from '../../../utils/dateToUTC';
import ErrorDialog from '../../Integrations/ErrorDialog';
import AccountSelector from '../../Operations/Components/AccountSelector';
import { useStyles } from './styles';
import useFormatBalance from '../../../hooks/useFormatBalance';
import { getSyncIntegrations } from '../../../store/common/selectors';
import commonActions from '../../../store/common/actions';

function BelvoAccountsDialog({
  onCloseAccountsListDialog,
}: {
  onCloseAccountsListDialog(): void;
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const facebookContext = useContext(FacebookPixelContext);
  const { getBalance } = useFormatBalance();

  const [accounts, setAccounts] = useState<BelvoAccount[] | null>(null);
  const [currentAccount, setCurrentAccount] = useState<BelvoAccount | null>(
    null,
  );
  const [showDialog, setShowDialog] = useState(false);
  const [showInProcessDialog, setShowInProcessDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [integrationLoading, setIntegrationLoading] = useState(false);

  const company = useSelector(selectCurrentCompany);
  const syncIntegrations = useSelector(getSyncIntegrations);

  const handleCloseErrorDialog = useCallback(() => {
    Storages.remove(StorageKey.belvo);

    setAccounts(null);
    setShowErrorDialog(false);

    onCloseAccountsListDialog();
  }, [onCloseAccountsListDialog]);

  const handleCloseDialog = useCallback(() => {
    setShowDialog(false);

    Storages.remove(StorageKey.belvo);
  }, []);

  const handleCloseInProgressDialog = useCallback(() => {
    setShowInProcessDialog(false);
    Storages.remove(StorageKey.belvo);
  }, []);

  const handleChangeAccount = useCallback((val: any) => {
    setCurrentAccount(val);
  }, []);

  const handleIntegrationStart = useCallback(async () => {
    const belvoIntegration = Storages.get(StorageKey.belvo);

    setIntegrationLoading(true);

    if (belvoIntegration && currentAccount) {
      const {
        integrationId,
        accountName,
        accountId: storedAccountId,
        startDate,
        bankName,
      } = belvoIntegration;

      let accountId = storedAccountId;

      if (!accountId) {
        const {
          data: { newAccount },
        } = await accountsApi.createAccount(
          currentAccount.currency,
          accountName,
          currentAccount.balance,
          IntegrationTypeId.Belvo,
        );

        accountId = newAccount._id;
      }

      dispatch(belvoActions.setBelvoIntegrationId(integrationId));

      const payload: CreateIntegrationPayload = {
        typeId: IntegrationTypeId.Belvo,
        accountId,
        startDate: new Date(startDate).getTime() + getTimeOffset(),
        bankName,
        facebookContext,
      };

      await belvoApi.updateCredentials({
        integrationId,
        accountId: currentAccount.id,
      });

      dispatch(belvoActions.createBelvoIntegration(payload));

      setIntegrationLoading(false);
      handleCloseDialog();

      onCloseAccountsListDialog();
    }
  }, [
    dispatch,
    currentAccount,
    handleCloseDialog,
    facebookContext,
    onCloseAccountsListDialog,
  ]);

  useEffect(() => {
    const belvoIntegration = Storages.get(StorageKey.belvo);

    if (!belvoIntegration) {
      return;
    }

    const getBelvoAccounts = async () => {
      try {
        setLoading(true);

        if (!belvoIntegration.continue) {
          await belvoApi.updateLink({
            integrationId: belvoIntegration.integrationId,
            linkId: belvoIntegration.link,
          });
        }

        const { data: belvoAccounts } = await belvoApi.getIntegrationAccounts(
          belvoIntegration.integrationId,
        );

        setLoading(false);

        if (!belvoAccounts.length && !belvoIntegration.continue) {
          setShowInProcessDialog(true);
          const belvoContinueIntegration = Storages.get(
            StorageKey.belvoContinue,
          );
          if (Array.isArray(belvoContinueIntegration)) {
            const findedCurrentCred = belvoContinueIntegration.find(
              (el) => el.integrationId === belvoIntegration.integrationId,
            );
            if (!findedCurrentCred) {
              belvoContinueIntegration.push(belvoIntegration);
              Storages.put(StorageKey.belvoContinue, belvoContinueIntegration);
            }
          } else {
            Storages.put(StorageKey.belvoContinue, [belvoIntegration]);
          }
          if (!syncIntegrations) {
            dispatch(commonActions.setSyncIntegrations(true));
            dispatch(commonActions.updateSyncFirstIntegration(true));
          }
          return;
        }

        const formatAccounts = belvoAccounts.map((el: BelvoAccount) => ({
          ...el,
          name: `${el.name || ''} ${getBalance(el.balance)} ${el.currency}`,
        }));

        setAccounts(formatAccounts);
        setShowDialog(true);
      } catch (e) {
        setLoading(false);
        setShowErrorDialog(true);
      }
    };

    if (belvoIntegration?.integrationId && !showErrorDialog) {
      getBelvoAccounts();
    }
  }, [company, showErrorDialog, getBalance, syncIntegrations, dispatch]);

  if (showErrorDialog) {
    return <ErrorDialog onClose={handleCloseErrorDialog} />;
  }

  if (loading) {
    return (
      <div className={classes.overlay}>
        <Loader size="medium" />
      </div>
    );
  }

  const belvoIntegration = Storages.get(StorageKey.belvo);

  if (!belvoIntegration) {
    return null;
  }

  return (
    <>
      {showDialog && (
        <Dialog
          isOpened={showDialog}
          onClose={handleCloseDialog}
          title={t('operationDialogs.account.placeholder')}
        >
          <AccountSelector
            // @ts-ignore
            value={currentAccount}
            // @ts-ignore
            accounts={accounts}
            onChange={handleChangeAccount}
            disablePortal={false}
          />
          <CustomButton
            fullWidth
            action={handleIntegrationStart}
            loading={integrationLoading}
            title={t('bank.connectAccount')}
          />
        </Dialog>
      )}

      {showInProcessDialog && (
        <Dialog
          isOpened={showInProcessDialog}
          onClose={handleCloseInProgressDialog}
          titleClassName={classes.marginTop}
          title={t('integrations.belvo.continueIntegrationTitle')}
        >
          <Typography className={classes.description}>
            {t('integrations.belvo.continueIntegrationDescription', {
              postProcess: 'sprintf',
              sprintf: [belvoIntegration.bankName],
            })}
          </Typography>

          <CustomButton
            fullWidth
            action={handleCloseInProgressDialog}
            loading={integrationLoading}
            title={t('common.ok')}
          />
        </Dialog>
      )}
    </>
  );
}

export default React.memo(BelvoAccountsDialog);
