import i18next from 'i18next';
import { createSelector } from 'reselect';

import { WITHOUT_ACCOUNT_NAME } from '../../constants';
import {
  DEFAULT_CONSUMPTION_EMPTY_ID,
  DEFAULT_INCOME_EMPTY_ID,
} from '../../constants/defaultCategories';
import { TreeNode } from '../../organisms/shared/custom-filters-dialog/types';
import { selectCompanyCurrencyId } from '../company/selectors';
import { selectCustomFilterAccounts } from '../filters/selectors';
import { FiltersState } from '../filters/types';
import { OperationSubType, OperationType } from '../operations/types';
import { AppState } from '../reducers';
import { AccountValue } from './types';

export const getAccounts = (state: AppState) => state.accounts.accounts;

export const getLoadingFuturePayments = (state: AppState) =>
  state.accounts.loadingFuturePayments;

export const getFuturePayments = (state: AppState) =>
  state.accounts.futurePayments;

export const getEditAccountInProgress = (state: AppState) =>
  state.accounts.editAccountInProgress;

export const getLanguage = (state: AppState) => state.company.lng;

export const getLoadingAccounts = (state: AppState) =>
  state.accounts.loadingAccounts;

export const getNewAccount = (state: AppState) => state.accounts.newAccount;

export const selectDemoAccount = createSelector(getAccounts, (accounts) =>
  accounts.log.find((el) => el.demo),
);

export const selectIncomeFuturePayments = createSelector(
  getFuturePayments,
  (payments) => payments.filter((el) => el.type === OperationType.income),
);

export const selectLogAccounts = createSelector(
  getAccounts,
  (accounts) => accounts.log,
);

export const selectFiltersAccounts = createSelector(
  getAccounts,
  //@ts-ignore
  (accounts) => accounts.filters,
);

export const selectLogAccountsWithoutNotSpecifiedAccount = createSelector(
  getAccounts,
  (accounts) => accounts.log.filter((el) => el.name !== WITHOUT_ACCOUNT_NAME),
);

export const selectTotalAmount = createSelector(
  selectLogAccounts,
  getFuturePayments,
  (accounts, futurePayments) => {
    const amount = accounts.reduce(
      (acc, el) => acc + el.companyCurrencyBalance,
      0,
    );

    return futurePayments.reduce((acc, el) => acc + el.sum, amount);
  },
);

export const selectConsumptionFuturePayments = createSelector(
  getFuturePayments,
  (payments) => payments.filter((el) => el.type === OperationType.consumption),
);

export const selectStatementAccounts = createSelector(
  selectLogAccounts,
  (accounts) => accounts.filter((el) => el._id !== WITHOUT_ACCOUNT_NAME),
);

export const selectOperationAccounts = createSelector(
  getAccounts,
  (accounts) => {
    const { log, operation } = accounts;

    return operation.map((el) => {
      const logAccount = log.find((logItem) => logItem._id === el._id);

      if (logAccount && logAccount.integration) {
        return {
          ...el,
          integration: { ...logAccount.integration },
        };
      }

      return el;
    });
  },
);

export const selectAccountsWithCompanyCurrencyId = createSelector(
  selectOperationAccounts,
  selectCompanyCurrencyId,
  (accounts, companyCurrencyId) =>
    accounts.filter((el) => el.currency._id === companyCurrencyId),
);

export const selectOperationAccountsToAutocomplete = createSelector(
  selectOperationAccounts,
  (accounts) =>
    accounts.map((el) => ({
      id: el._id,
      name: el.name,
      label: el.label,
    })),
);

export const selectSettingsAccounts = createSelector(
  getAccounts,
  (accounts) => accounts.settings,
);

export const selectSettingsAccountsWithNoAccounts = createSelector(
  selectSettingsAccounts,
  getLanguage,
  (accounts, language) => {
    const i18 = i18next.getFixedT(language);

    // @ts-ignore
    accounts.push({
      _id: '616b437ecaef522217409e07',
      label: i18('accounts.accountNotSpecified'),
      name: i18('accounts.accountNotSpecified'),
    });

    return accounts;
  },
);

export const treeNodeAccounts = (selector: keyof FiltersState) =>
  createSelector(
    selectFiltersAccounts,
    getLanguage,
    selectCustomFilterAccounts(selector),
    (accounts, lng, customFilterAccounts) => {
      const i18 = i18next.getFixedT(lng);

      const children = accounts.reduce((acc: TreeNode[], el: AccountValue) => {
        if (el.name !== WITHOUT_ACCOUNT_NAME) {
          return [
            ...acc,
            {
              id: el._id,
              label: el.name,
              checked:
                !customFilterAccounts || customFilterAccounts.includes(el._id),
              value: el.name,
            },
          ];
        }

        return acc;
      }, []);

      return [
        {
          label: i18('filters.all.accounts'),
          checked: !customFilterAccounts,
          expanded: true,
          children,
        },
      ];
    },
  );

export const selectCountIntegrations = createSelector(
  selectSettingsAccounts,
  (accounts) => accounts.filter((el) => Boolean(el.integration)).length,
);

export const selectFuturePaymentPeriods = createSelector(
  getLanguage,
  (language) => {
    const i18 = i18next.getFixedT(language);

    return [
      {
        id: 'month',
        value: 1,
        label: `1 ${i18('customPeriod.month').toLowerCase()}`,
        shortLabel: `1 ${i18('customPeriod.shortMonth').toLowerCase()}`,
      },
      {
        id: 'half-month',
        value: 6,
        label: `6 ${i18('customPeriod.months').toLowerCase()}`,
        shortLabel: `6 ${i18('customPeriod.shortMonth').toLowerCase()}`,
      },
      {
        id: 'all',
        value: 0,
        label: i18('common.all'),
        shortLabel: i18('common.all'),
      },
    ];
  },
);

export const selectIncomeCustomCategoriesToFuturePayments = createSelector(
  selectIncomeFuturePayments,
  (payments) => {
    let amount = 0;

    const customIds = payments.reduce((acc: string[], payment) => {
      const { categoryId, subType, sum } = payment;

      if (subType === OperationSubType.sale) {
        if (!categoryId) {
          if (!acc.includes(DEFAULT_INCOME_EMPTY_ID)) {
            acc.push(DEFAULT_INCOME_EMPTY_ID);
          }
        } else {
          acc.push(categoryId);
        }

        amount += sum;
      }

      return acc;
    }, []);

    const result = [];

    if (customIds.length) {
      result.push({
        categoryId: customIds,
        sum: amount,
        subType: OperationSubType.sale,
        type: OperationType.income,
      });
    }

    // @ts-ignore
    result.push(
      ...payments.filter((el) => el.subType !== OperationSubType.sale),
    );

    return result;
  },
);

export const selectConsumptionCustomCategoriesToFuturePayments = createSelector(
  selectConsumptionFuturePayments,
  (payments) => {
    let amount = 0;

    const customIds = payments.reduce((acc: string[], payment) => {
      const { categoryId, subType, sum } = payment;

      if (subType === OperationSubType.supplier) {
        if (!categoryId) {
          if (!acc.includes(DEFAULT_CONSUMPTION_EMPTY_ID)) {
            acc.push(DEFAULT_CONSUMPTION_EMPTY_ID);
          }
        } else {
          acc.push(categoryId);
        }

        amount += sum;
      }

      return acc;
    }, []);

    const result = [];
    if (customIds.length) {
      result.push({
        categoryId: customIds,
        sum: amount,
        subType: OperationSubType.supplier,
        type: OperationType.consumption,
      });
    }

    // @ts-ignore
    result.push(
      ...payments.filter((el) => el.subType !== OperationSubType.supplier),
    );

    return result;
  },
);
