import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  Config,
} from '@finmap/import-parsers/base-import-parser-v3';

export class MontepioParser extends BaseImportParserV3 {
   // public debug: boolean = true;
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.XLS]: [
      {
        caseOptions:{
          "defaultCurrency": "UAH",
          "withoutEmpty": true,
          "isCurCase": [
            [
              0,
              {
                "in": "DATA MOV"
              }
            ]
          ],
          "proceedCaseConfig": {
            "delete": [
              {
                "to": [
                  [
                    0,
                    {
                      "eq": "DATA MOV."
                    }
                  ],
                  [
                    1,
                    {
                      "eq": "DATA VALOR"
                    }
                  ]
                ],
                "count": 1
              }
            ],
            "fields": {
              "sum": {
                "column": [
                  "IMPORTÂNCIA",
                  3
                ],
                "replace": [
                  {
                    "from": ".",
                    "to": ""
                  }
                ]
              },
              "date": {
                "or": [
                {
                  "column": [
                    "DATA VALOR",
                    1
                  ],
                  "if": {
                    "dateFormat": [
                  {
                  "column": [
                    "DATA VALOR",
                    1
                  ],
                    "split": { "by": " ", "get": 0 }
                	},
                      "DD/MM/YYYY"
                  ]
                  }

                },
                {
                  "column": [
                    "DATA VALOR",
                    1
                  ],
                  "dateFormat": "YYYY-DD-MM"


                }
              ]
                      },
              "comment": {
                "column": [
                  "DESCRIÇÃO",
                  2
                ]
              }
            }
          }
        }
      }
    ],
  };
}
