import moment from 'moment';

import { PeriodId, Status } from '../journal/types';
import { OperationType } from '../operations/types';

export const initialCategoriesByType = [
  OperationType.income,
  OperationType.consumption,
  OperationType.transfer,
];

export const initialCashFlowAndPLCategoriesByType = [
  OperationType.income,
  OperationType.consumption,
];

export const initialCustomFilters = {
  accounts: null,
  categoriesByType: initialCategoriesByType,
  categoriesBySubType: null,
  categoriesByIds: [],
  clients: null,
  projects: null,
  tags: null,
  status: null,
  _id: null,
};

export const initialPLCustomFilters = {
  ...initialCustomFilters,
  status: {
    id: Status.future,
    name: '',
  },
  categoriesByType: initialCashFlowAndPLCategoriesByType,
};

export const initialProjectCustomFilters = {
  ...initialCustomFilters,
  status: {
    id: Status.future,
    name: '',
  },
  categoriesByType: initialCategoriesByType,
};

export const initialCashAndFlowCustomFilters = {
  ...initialCustomFilters,
  status: {
    id: Status.future,
    name: '',
  },
  categoriesByType: initialCashFlowAndPLCategoriesByType,
};

export const initialDebitAndCreditCustomFilters = {
  ...initialCustomFilters,
  categoriesByType: initialCashFlowAndPLCategoriesByType,
  currentClient: null,
};

export const initialJournalFilters = {
  id: PeriodId.allTime,
  startDate: undefined,
  endDate: undefined,
};

export const initialFutureJournalFilter: {
  startDate: number | undefined;
  endDate: number;
} = {
  startDate: undefined,
  endDate: moment.utc().add(7, 'days').endOf('day').valueOf(),
};

export const initialHistoryFilters = {
  id: PeriodId.allTime,
  startDate: undefined,
  endDate: undefined,
  operationId: undefined,
};

export const initialDebitAndCreditFilters = {
  id: undefined,
  startDate: undefined,
  endDate: undefined,
};

export const initialProjectsFilters = {
  id: PeriodId.allTime,
  startDate: undefined,
  endDate: undefined,
};

export const initialStatementFilters = {
  id: PeriodId.allTime,
  startDate: undefined,
  endDate: undefined,
};

export const initialCalendarFilters = {
  id: PeriodId.allTime,
  startDate: undefined,
  endDate: undefined,
};

export const initialCashFlowAndPLFilters = {
  id: PeriodId.allTime,
  startDate: undefined,
  endDate: undefined,
};
