import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import CustomButton from '../../../components/Button';
import Dialog from '../../../components/Dialog/Dialog';
import Loader from '../../../components/Loader/LottieLoader';
import useFormatBalance from '../../../hooks/useFormatBalance';
import FacebookPixelContext from '../../../Providers/FacebookFixelProvider/FacebookPixelContext';
import accountsApi from '../../../services/accounts';
import nordigenApi from '../../../services/bankIntegrations/nordigen';
import Storages, { StorageKey } from '../../../services/Storages';
import { selectCurrentCompany } from '../../../store/company/selectors';
import nordigenActions from '../../../store/integrations/nordigenActions';
import { CreateIntegrationPayload } from '../../../store/integrations/sagas.types';
import {
  IntegrationTypeId,
  NordigenAccount,
} from '../../../store/integrations/types';
import { getTimeOffset } from '../../../utils/dateToUTC';
import ErrorDialog from '../../Integrations/ErrorDialog';
import AccountSelector from '../../Operations/Components/AccountSelector';
import { useStyles } from './styles';
import { Typography } from '@material-ui/core';

function NordigenAccountsDialog({
  onCloseAccountsListDialog,
  savedAccounts,
  savedIntegration,
}: {
  onCloseAccountsListDialog(): void;
  savedAccounts?: NordigenAccount[];
  savedIntegration?: {
    integrationId: string;
    bankName: string;
    accountName?: string;
    accountId?: string;
    startDate: number;
  };
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const facebookContext = useContext(FacebookPixelContext);
  const { getBalance } = useFormatBalance();

  const [accounts, setAccounts] = useState<NordigenAccount[] | null>(null);
  const [currentAccount, setCurrentAccount] = useState<NordigenAccount | null>(
    null,
  );
  const [showDialog, setShowDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [integrationLoading, setIntegrationLoading] = useState(false);
  const [showInProcessDialog, setShowInProcessDialog] = useState(false);

  const company = useSelector(selectCurrentCompany);

  const handleCloseErrorDialog = useCallback(() => {
    Storages.remove(StorageKey.nordigen);

    setAccounts(null);
    setShowErrorDialog(false);

    onCloseAccountsListDialog();
  }, [onCloseAccountsListDialog]);

  const handleCloseDialog = useCallback(() => {
    setShowDialog(false);

    Storages.remove(StorageKey.nordigen);
  }, []);

  const handleCloseInProgressDialog = useCallback(() => {
    setShowInProcessDialog(false);
    Storages.remove(StorageKey.nordigen);
  }, []);

  const handleChangeAccount = useCallback((val: any) => {
    setCurrentAccount(val);
  }, []);

  const handleIntegrationStart = useCallback(async () => {
    const nordigenIntegration =
      savedIntegration || Storages.get(StorageKey.nordigen);

    setIntegrationLoading(true);

    if (nordigenIntegration && currentAccount) {
      const {
        integrationId,
        accountName,
        accountId: storedAccountId,
        startDate,
        bankName,
      } = nordigenIntegration;

      let accountId = storedAccountId;

      if (!accountId) {
        const {
          data: { newAccount },
        } = await accountsApi.createAccount(
          currentAccount.currency,
          accountName,
          currentAccount.amount,
          IntegrationTypeId.Nordigen,
        );

        accountId = newAccount._id;
      }

      dispatch(nordigenActions.setNordigenIntegrationId(integrationId));

      const payload: CreateIntegrationPayload = {
        typeId: IntegrationTypeId.Nordigen,
        accountId,
        startDate: new Date(startDate).getTime() + getTimeOffset(),
        bankName,
        facebookContext,
      };

      await nordigenApi.updateCredentials({
        integrationId,
        accountId: currentAccount.accountId,
        externalAccountCurrency: currentAccount.externalAccountCurrency,
      });

      dispatch(nordigenActions.createNordigenIntegration(payload));

      setIntegrationLoading(false);
      handleCloseDialog();

      onCloseAccountsListDialog();
    }
  }, [
    dispatch,
    currentAccount,
    handleCloseDialog,
    facebookContext,
    onCloseAccountsListDialog,
    savedIntegration,
  ]);

  useEffect(() => {
    const nordigenIntegration =
      savedIntegration || Storages.get(StorageKey.nordigen);

    if (!nordigenIntegration) {
      return;
    }

    const getNordigenAccounts = async () => {
      try {
        setLoading(true);

        let nordigenAccounts = [];
        if (savedAccounts) {
          nordigenAccounts = savedAccounts;
        } else {
          const { data } = await nordigenApi.getIntegrationAccounts(
            nordigenIntegration.integrationId,
          );
          nordigenAccounts = data;
        }

        setLoading(false);

        if (nordigenAccounts.status) {
          Storages.remove(StorageKey.nordigen);
        } else {
          if (!nordigenAccounts.length && !savedAccounts) {
            setShowInProcessDialog(true);
            const nordigenContinueIntegration = Storages.get(
              StorageKey.nordigenContinue,
            );
            if (Array.isArray(nordigenContinueIntegration)) {
              const findedCurrentCred = nordigenContinueIntegration.find(
                (el) => el.integrationId === nordigenIntegration.integrationId,
              );
              if (!findedCurrentCred) {
                nordigenContinueIntegration.push(nordigenIntegration);
                Storages.put(
                  StorageKey.nordigenContinue,
                  nordigenContinueIntegration,
                );
              }
            } else {
              Storages.put(StorageKey.nordigenContinue, [nordigenIntegration]);
            }
            return;
          }

          const formatAccounts = nordigenAccounts.map(
            (el: NordigenAccount) => ({
              ...el,
              name: `${el.name || ''} ${getBalance(el.amount)} ${el.currency}`,
            }),
          );

          setAccounts(formatAccounts);
          setShowDialog(true);
        }
      } catch (e) {
        setLoading(false);
        setShowErrorDialog(true);
      }
    };

    if (nordigenIntegration?.integrationId && !showErrorDialog) {
      getNordigenAccounts();
    }
  }, [company, showErrorDialog, getBalance, savedAccounts, savedIntegration]);

  if (showErrorDialog) {
    return <ErrorDialog onClose={handleCloseErrorDialog} />;
  }

  if (loading) {
    return (
      <div className={classes.overlay}>
        <Loader size="medium" />
      </div>
    );
  }

  const nordigenIntegration =
    savedIntegration || Storages.get(StorageKey.nordigen);

  if (!nordigenIntegration) {
    return null;
  }

  return (
    <>
      {showDialog && (
        <Dialog
          isOpened={showDialog}
          onClose={handleCloseDialog}
          title={t('operationDialogs.account.placeholder')}
        >
          <AccountSelector
            // @ts-ignore
            value={currentAccount}
            // @ts-ignore
            accounts={accounts}
            onChange={handleChangeAccount}
            disablePortal={false}
          />
          <CustomButton
            fullWidth
            action={handleIntegrationStart}
            loading={integrationLoading}
            title={t('bank.connectAccount')}
          />
        </Dialog>
      )}
      {showInProcessDialog && (
        <Dialog
          isOpened={showInProcessDialog}
          onClose={handleCloseInProgressDialog}
          titleClassName={classes.marginTop}
          title={t('integrations.nordigen.continueIntegrationTitle')}
        >
          <Typography className={classes.description}>
            {t('integrations.nordigen.continueIntegrationDescription', {
              postProcess: 'sprintf',
              sprintf: [nordigenIntegration.bankName],
            })}
          </Typography>

          <CustomButton
            fullWidth
            action={handleCloseInProgressDialog}
            loading={integrationLoading}
            title={t('common.ok')}
          />
        </Dialog>
      )}
    </>
  );
}

export default React.memo(NordigenAccountsDialog);
