import { createSelector } from 'reselect';

import { OPERATION_TYPES } from '@finmap/core-constants';

import { EMPTY, EMPTY_ID } from '../constants';
import { selectLogAccounts } from '../store/accounts/selectors';
import {
  selectAllCategoriesCount,
  selectFullLogConsumptionCategoriesIds,
  selectFullLogIncomeCategoriesIds,
} from '../store/categories/selectors';
import { selectAllLogClients } from '../store/clients/selectors';
import { selectCustomFilters } from '../store/filters/selectors';
import { FiltersState } from '../store/filters/types';
import { LogRequestBody, Status } from '../store/journal/types';
import { OperationType } from '../store/operations/types';
import { AppState } from '../store/reducers';

export const getLanguage = (state: AppState) => state.company.lng;

export const prepareCustomFiltersQuerySelectorObj = (
  selector: keyof FiltersState,
) =>
  createSelector(
    selectCustomFilters(selector),
    selectAllLogClients,
    selectLogAccounts,
    selectAllCategoriesCount, // (selector),
    selectFullLogIncomeCategoriesIds,
    selectFullLogConsumptionCategoriesIds,
    (
      filters,
      allClients,
      allAccounts,
      allCategoriesCount,
      allIncomeIds,
      allConsumptionIds,
    ): LogRequestBody => {
      const {
        tags,
        // @ts-ignore
        status,
        clients,
        accounts,
        projects,
        // @ts-ignore
        isDateOfPayment,
        categoriesByIds,
        categoriesByType,
        analyticCategoriesByType,
        projectsJournal,
        categoriesByTypeJournal,
        // @ts-ignore
        currentClient,
      } = filters;
      const logObjRequestBody: LogRequestBody = {};

      if (status && selector !== 'journal') {
        logObjRequestBody.approved = status[0] === Status.payed;
      }

      if (
        clients &&
        (!['credit', 'debit'].includes(selector) || !currentClient)
      ) {
        const clientsIds = clients.length
          ? clients
          : allClients.map((el) => el._id);

        if (clients.length) {
          logObjRequestBody.counterpartyIds = clientsIds.map((el: string) =>
            el === EMPTY ? EMPTY_ID : el,
          );
        } else {
          logObjRequestBody.counterpartyIds = allClients
            .map((client) => client._id)
            .filter((clientId) => !clientsIds.includes(clientId))
            .map((el: string) => (el === EMPTY ? EMPTY_ID : el));
        }
      }
      if (currentClient && ['credit', 'debit'].includes(selector)) {
        logObjRequestBody.counterpartyIds = [currentClient];
      }

      if (tags) {
        logObjRequestBody.tagIds = tags.length
          ? tags.map((el: string) => (el === EMPTY ? EMPTY_ID : el))
          : [EMPTY_ID];
      }

      if (projects && selector !== 'projects') {
        logObjRequestBody.projectIds = projects.length
          ? projects.map((el: string) => (el === EMPTY ? EMPTY_ID : el))
          : [EMPTY_ID];
      }
      if (selector === 'projects' && projectsJournal) {
        logObjRequestBody.projectIds = projectsJournal.length
          ? projectsJournal
          : [];
      }

      if (accounts) {
        const accountIds = accounts.length
          ? accounts
          : allAccounts.map((el) => el._id);

        if (accounts.length) {
          logObjRequestBody.accountIds = accountIds;
        } else {
          logObjRequestBody.accountIds = allAccounts
            .map((account) => account._id)
            .filter((accountId) => !accountIds.includes(accountId));
        }
      }
      if (
        categoriesByIds.length &&
        categoriesByIds.length !== allCategoriesCount
      ) {
        logObjRequestBody.categoryV2Ids = categoriesByIds;
      }

      if (analyticCategoriesByType?.length) {
        logObjRequestBody.types = analyticCategoriesByType;
      } else if (categoriesByType.length && selector !== 'projects') {
        // can come undefined, so need to filter
        const types = categoriesByType.filter((el: OperationType) => el);

        if (categoriesByIds.length !== allCategoriesCount) {
          if (!types.includes(OperationType.income)) {
            const isIncome = categoriesByIds.some((el: string) =>
              allIncomeIds.includes(el),
            );

            if (isIncome) {
              types.push(OperationType.income);
            }
          }

          if (!types.includes(OperationType.consumption)) {
            const isConsumption = categoriesByIds.some((el: string) =>
              allConsumptionIds.includes(el),
            );

            if (isConsumption) {
              types.push(OperationType.consumption);
            }
          }
        }
        logObjRequestBody.types = types;
      } else if (selector === 'projects' && categoriesByTypeJournal.length) {
        // can come undefined, so need to filter
        logObjRequestBody.types = categoriesByTypeJournal.filter(
          (el: OperationType) => el,
        );
      }

      if (
        selector === 'tableProfitAndLoss' ||
        selector === 'profitAndLoss' ||
        selector === 'logTableProfitAndLoss' ||
        (selector === 'projects' && isDateOfPayment) ||
        (selector === 'logProjects' && isDateOfPayment)
      ) {
        logObjRequestBody.useDateOfPayment = true;
      }

      if (
        categoriesByIds.length &&
        categoriesByIds.length !== allCategoriesCount &&
        logObjRequestBody.types?.includes(OPERATION_TYPES.TRN) &&
        !logObjRequestBody.categoryV2Ids?.includes(EMPTY)
      ) {
        logObjRequestBody.categoryV2Ids?.push(EMPTY);
      }
      return logObjRequestBody;
    },
  );

export const prepareCustomFiltersQuerySelector = (
  selector: keyof FiltersState,
) =>
  createSelector(
    selectCustomFilters(selector),
    selectAllLogClients,
    selectLogAccounts,
    selectAllCategoriesCount, // (selector),
    selectFullLogIncomeCategoriesIds,
    selectFullLogConsumptionCategoriesIds,
    (
      filters,
      allClients,
      allAccounts,
      allCategoriesCount,
      allIncomeIds,
      allConsumptionIds,
    ) => {
      const query: string[] = [];

      const {
        tags,
        // @ts-ignore
        status,
        clients,
        accounts,
        projects,
        // @ts-ignore
        isDateOfPayment,
        categoriesByIds,
        categoriesByType,
        analyticCategoriesByType,
        projectsJournal,
        categoriesByTypeJournal,
        // @ts-ignore
        currentClient,
      } = filters;

      if (status && status.id === Status.payed && selector !== 'journal') {
        query.push('approved=true');
      }

      if (
        clients &&
        (!['credit', 'debit'].includes(selector) || !currentClient)
      ) {
        const clientsIds = clients.length
          ? clients.join(',')
          : allClients.map((el) => el._id);

        if (clients.length) {
          query.push(`counterpartyIds=${clientsIds}`);
        } else {
          query.push(`counterpartyIds=!,${clientsIds}`);
        }
      }

      if (currentClient && ['credit', 'debit'].includes(selector)) {
        query.push(`counterpartyIds=${currentClient}`);
      }

      if (tags) {
        const tagIds = tags.length ? tags.join(',') : [EMPTY];

        query.push(`tagIds=${tagIds}`);
      }

      if (projects && selector !== 'projects') {
        const projectIds = projects.length ? projects.join(',') : [EMPTY];

        query.push(`projectIds=${projectIds}`);
      }
      if (selector === 'projects' && projectsJournal) {
        const projectIds = projectsJournal.length
          ? projectsJournal.join(',')
          : [];

        query.push(`projectIds=${projectIds}`);
      }

      if (accounts) {
        const accountIds = accounts.length
          ? accounts.join(',')
          : allAccounts.map((el) => el._id);

        if (accounts.length) {
          query.push(`accountIds=${accountIds}`);
        } else {
          query.push(`accountIds=!,${accountIds}`);
        }
      }
      if (
        categoriesByIds.length &&
        categoriesByIds.length !== allCategoriesCount
      ) {
        if (selector === 'logProjects' && !categoriesByIds.includes('empty')) {
          query.push(
            `categoryV2Ids=${['empty', ...categoriesByIds].join(',')}`,
          );
        } else {
          query.push(`categoryV2Ids=${categoriesByIds.join(',')}`);
        }
      }

      if (analyticCategoriesByType?.length) {
        query.push(`types=${analyticCategoriesByType.join(',')}`);
      } else if (categoriesByType.length && selector !== 'projects') {
        // can come undefined, so need to filter
        const types = categoriesByType.filter((el: OperationType) => el);

        if (categoriesByIds.length !== allCategoriesCount) {
          if (!types.includes(OperationType.income)) {
            const isIncome = categoriesByIds.some((el: string) =>
              allIncomeIds.includes(el),
            );

            if (isIncome) {
              types.push(OperationType.income);
            }
          }

          if (!types.includes(OperationType.consumption)) {
            const isConsumption = categoriesByIds.some((el: string) =>
              allConsumptionIds.includes(el),
            );

            if (isConsumption) {
              types.push(OperationType.consumption);
            }
          }

          // FC-1503 bug
          if (
            ['cashFlow', 'profitAndLoss'].includes(selector) &&
            allCategoriesCount === 0 &&
            categoriesByIds.length !== 0
          ) {
            if (
              !types.includes(OperationType.income) &&
              allIncomeIds.length === 0
            ) {
              types.push(OperationType.income);
            }
            if (
              !types.includes(OperationType.consumption) &&
              allConsumptionIds.length === 0
            ) {
              types.push(OperationType.consumption);
            }
          }

          // FC-1226 / FC-1227
          // if (
          //   selector === 'logProjects' &&
          //   types.length &&
          //   !types.includes('transfer')
          // ) {
          //   types.push('transfer');
          // }
        }
        query.push(`types=${types.join(',')}`);
      } else if (selector === 'projects' && categoriesByTypeJournal.length) {
        // can come undefined, so need to filter
        const types = categoriesByTypeJournal.filter((el: OperationType) => el);
        query.push(`types=${types.join(',')}`);
      }

      if (
        selector === 'tableProfitAndLoss' ||
        selector === 'profitAndLoss' ||
        selector === 'logTableProfitAndLoss' ||
        (selector === 'projects' && isDateOfPayment) ||
        (selector === 'logProjects' && isDateOfPayment)
      ) {
        query.push('useDateOfPayment=true');
      }

      return query.join('&');
    },
  );
