import { Typography } from '@material-ui/core';
import cn from 'classnames';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import CustomButton from '../../../components/Button';
import Dialog from '../../../components/Dialog/Dialog';
import useSubscriptionActive from '../../../hooks/useSubscriptionActive';
import accountsApi from '../../../services/accounts';
import ChargeBee from '../../../services/ChargeBee';
import { AccountValue } from '../../../store/accounts/types';
import api from '../../../store/common/api';
import { selectCurrentCompany } from '../../../store/company/selectors';
import { TariffsId } from '../../../store/company/types';
import employeesActions from '../../../store/employees/actions';
import employeesApi from '../../../store/employees/api';
import {
  Context,
  SubscriptionPeriod,
  SubscriptionPeriods,
  SubscriptionTariff,
  SubscriptionType,
} from '../../../store/types';
import { isLite, isPro, isTrial } from '../../../utils/subscriptions';
import LiteNotSuiteYou from '../LiteNotSuiteYou';
import AllAllowContent from './components/AllAllowContent';
import Badge from './components/Badge';
import LiteContent from './components/LiteContent';
import ProContent from './components/ProContent';
import RenderPeriodTitle from './components/RenderPeriodTitle';
import SwitcherV2Discounts from './components/Switchers/SwitcherV2Discounts';
import { useStyles } from './styles';
import { Props } from './types';
import { reduceTariffsToId } from './utils';
import { getUserGeoData } from '../../../store/common/selectors';
import UzbekBadge from '../UzbekBadge';
import { FEATURES } from '@finmap/core-constants';
import useUnleash from '../../../hooks/useUnleash';
import subscriptionApi from '../../../services/subscription';

function SubscriptionDialog(props: Props) {
  const { onClose, title } = props;
  const { t } = useTranslation();
  const company = useSelector(selectCurrentCompany);
  const subscriptionActive = useSubscriptionActive();
  const userGeoData = useSelector(getUserGeoData);

  const useSolidgate = useUnleash(FEATURES.UseSolidgate);

  const dispatch = useDispatch();

  const [loadingTariffs, setLoadingTariffs] = useState(true);
  const [tariffs, setTariffs] = useState<SubscriptionTariff[] | null>(null);
  const [proActive, setProActive] = useState(true);
  const [period, setPeriod] = useState<SubscriptionPeriod>({
    id: SubscriptionPeriods.price12m,
    value: 12,
    title: `12 ${t('customPeriod.months')} (-25%)`,
  });
  const [showRestrictedDialog, setShowRestrictedDialog] = useState(false);
  const [shouldBePro, setShouldBePro] = useState(false);

  const tariffsToId = useMemo(() => reduceTariffsToId(tariffs), [tariffs]);
  const tariffCurrencySymbol = useMemo(
    () =>
      tariffs?.find((el) => el._id === TariffsId.Pro)?.currencySymbol ?? '$',
    [tariffs],
  );

  const classes = useStyles();

  const handleShowRestrictedDialog = useCallback(() => {
    setShowRestrictedDialog(true);
  }, []);

  const handleCloseRestrictedDialog = useCallback(() => {
    setShowRestrictedDialog(false);
  }, []);

  const handleSetPeriod = useCallback((value: SubscriptionPeriod) => {
    setPeriod(value);
  }, []);

  const handleSetProActive = useCallback(() => {
    setProActive(true);
  }, []);

  const handleSetProInActive = useCallback(() => {
    setProActive(false);
  }, []);

  const isPayNow = useCallback(() => {
    if (!subscriptionActive || isTrial(company)) {
      return false;
    }

    if (subscriptionActive && period.value !== company?.tariffPeriod) {
      return false;
    }

    if ((isPro(company) && !proActive) || (isLite(company) && proActive)) {
      return false;
    }

    const now = moment.utc().endOf('day');
    const companyEndDate = moment.utc(company?.tariffEndDate).endOf('day');

    let payNow;

    if (period.value === 1) {
      payNow = companyEndDate.diff(now.add(1, 'month')) <= 0;
    } else if (period.value === 6) {
      payNow = companyEndDate.diff(now.add(6, 'month')) <= 0;
    } else {
      payNow = companyEndDate.diff(now.add(1, 'year')) <= 0;
    }

    return payNow;
  }, [company, period, proActive, subscriptionActive]);

  const handleCreateTariff = useCallback(async () => {
    if (shouldBePro && isTrial(company) && !proActive) {
      handleShowRestrictedDialog();
    } else {
      const type = proActive ? SubscriptionType.Pro : SubscriptionType.Lite;

      if (tariffsToId) {
        const tariffId = tariffsToId[type].id;
        const periodId = period.value;

        const cbInstance = ChargeBee.makeChargeBeeInstance();

        const isUsedSolidgate = useSolidgate;

        if (isPayNow()) {
          if (isUsedSolidgate) return;
          cbInstance.openCheckout({
            hostedPage: () => {
              return ChargeBee.makeInstance()
                .get('/subscriptions/payNow')
                .then((response) => response.data);
            },
          });
        } else {
          if (isUsedSolidgate) {
            if (!tariffId) return;
            const integrationData = await subscriptionApi.getSubscriptionUrl(
              tariffId,
              periodId,
            );
            if (integrationData.data.url) {
              window.open(integrationData.data.url, '_blank');
            }
            return;
          }
          cbInstance.openCheckout({
            hostedPage: () => {
              return ChargeBee.makeInstance()
                .post('/subscriptions', {
                  tariffId,
                  period: periodId,
                })
                .then((response) => response.data);
            },
          });
        }
      }
    }
  }, [
    period,
    company,
    isPayNow,
    proActive,
    shouldBePro,
    tariffsToId,
    handleShowRestrictedDialog,
    useSolidgate,
  ]);

  useEffect(() => {
    const getTarrifs = async () => {
      const { data } = await api.getTariffs();

      setLoadingTariffs(false);
      setTariffs(data);
    };

    getTarrifs();
  }, []);

  useEffect(() => {
    const loadData = async () => {
      const [accounts, dataEmployees] = await Promise.all([
        accountsApi.getAccounts(Context.settings),
        employeesApi.getInvites(),
      ]);

      dispatch(employeesActions.setEmployees(dataEmployees));

      const integrationsCount = (accounts.data as AccountValue[]).filter((el) =>
        Boolean(el.integration),
      ).length;

      const {
        data: { employees, invites },
      } = dataEmployees;

      if (employees.length > 1 || invites.length || integrationsCount > 2) {
        setShouldBePro(true);
        setProActive(true);
      }
    };

    loadData();
  }, [dispatch]);

  return (
    <>
      <Dialog
        isOpened
        onClose={onClose}
        title={title || t('subscription.choosePlan')}
      >
        <>
          {userGeoData?.country.toLowerCase() === 'uz' && (
            <UzbekBadge oldScreen />
          )}
        </>
        <Typography className={cn(classes.subTitle)}>
          {t('subscription.perCompany')}
        </Typography>
        <div className={classes.root} id="cypress-subscription-dialog">
          <div
            className={cn(
              classes.container,
              proActive ? classes.primary : classes.secondary,
            )}
            onClick={handleSetProActive}
          >
            <div
              className={cn(classes.title, proActive && classes.marginBottom10)}
            >
              <div className={classes.info}>
                <Typography className={classes.infoText}>
                  {t('subscription.plan.pro')}
                </Typography>
                <Badge shouldBePro={shouldBePro} active={isPro(company)} />
              </div>
              {!loadingTariffs && tariffsToId && (
                <RenderPeriodTitle
                  period={period}
                  tariffCurrencySymbol={tariffCurrencySymbol}
                  tariffsToId={tariffsToId}
                  type={SubscriptionType.Pro}
                />
              )}
            </div>
            {proActive && (
              <>
                <AllAllowContent />
                <ProContent proActive={proActive} />
                <SwitcherV2Discounts
                  tariffsToId={tariffsToId}
                  type={SubscriptionType.Pro}
                  currentPeriod={period}
                  onSetPeriod={handleSetPeriod}
                  symbol={tariffCurrencySymbol}
                  loadingTariffs={loadingTariffs}
                />
              </>
            )}
          </div>
          <div
            className={cn(
              classes.container,
              !proActive ? classes.primary : classes.secondary,
            )}
            onClick={handleSetProInActive}
          >
            <div
              className={cn(
                classes.title,
                !proActive && classes.marginBottom10,
              )}
            >
              <div className={classes.info}>
                <Typography className={classes.infoText}>
                  {t('subscription.plan.lite')}
                </Typography>
                <Badge
                  active={isLite(company)}
                  isSuitYou={isTrial(company) && !shouldBePro}
                />
              </div>
              {!loadingTariffs && tariffsToId && (
                <RenderPeriodTitle
                  period={period}
                  tariffCurrencySymbol={tariffCurrencySymbol}
                  tariffsToId={tariffsToId}
                  type={SubscriptionType.Lite}
                />
              )}
            </div>
            {proActive && (
              <Typography className={classes.restricted}>
                {t('subscription.restrictedAccess')}
              </Typography>
            )}
            {!proActive && (
              <>
                <AllAllowContent />
                <LiteContent />
                <SwitcherV2Discounts
                  tariffsToId={tariffsToId}
                  type={SubscriptionType.Lite}
                  currentPeriod={period}
                  onSetPeriod={handleSetPeriod}
                  symbol={tariffCurrencySymbol}
                  loadingTariffs={loadingTariffs}
                />
              </>
            )}
          </div>
          <CustomButton
            title={
              isTrial(company)
                ? t('subscription.chooseTariff')
                : t('subscription.changeTariff')
            }
            action={handleCreateTariff}
            fullWidth
          />
        </div>
      </Dialog>
      {showRestrictedDialog && (
        <LiteNotSuiteYou
          onClose={handleCloseRestrictedDialog}
          onCloseParent={onClose}
        />
      )}
    </>
  );
}

export default React.memo(SubscriptionDialog);
