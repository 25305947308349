import moment from 'moment';

import { AccountValue } from '../../../store/accounts/types';
import { Category, SubCategory } from '../../../store/categories/types';
import { Client } from '../../../store/clients/types';
import {
  JournalData,
  ProjectObject,
  ProjectObjectsType,
} from '../../../store/journal/types';
import {
  OperationSubType,
  OperationType,
} from '../../../store/operations/types';
import { Project } from '../../../store/projects/types';
import { Tag } from '../../../store/tags/types';
import { getTimeOffset } from '../../../utils/dateToUTC';
import { CalculateSumAndCompanyCurrencySum, CustomPeriodEnum } from './types';

export const setEditAccount = (
  item: JournalData,
  accounts: AccountValue[],
  initialOperationType: string,
) => {
  const { type, accountToId, accountFromId } = item;

  const account =
    type === OperationType.income
      ? accounts.find((acc) => acc._id === accountToId) || null
      : accounts.find((acc) => acc._id === accountFromId) || null;

  const toAccount =
    type === OperationType.income
      ? accounts.find((acc) => acc._id === accountFromId) || null
      : accounts.find((acc) => acc._id === accountToId) || null;

  if (
    initialOperationType === OperationType.transfer &&
    type === OperationType.income
  ) {
    return { account: toAccount, toAccount: account };
  }
  return { account, toAccount };
};

export const setEditTags = (item: JournalData, tags: Tag[]) => {
  const { tagIds } = item;

  return tags.reduce((acc: any[], tag: Tag) => {
    if (tagIds.includes(tag._id)) {
      return [
        ...acc,
        {
          value: tag._id,
          label: tag.label,
        },
      ];
    }

    return acc;
  }, []);
};

export const setEditProjects = (item: JournalData, projects: Project[]) => {
  const { projectObjects }: { projectObjects?: ProjectObjectsType } = item;

  return Object.values(projectObjects ?? {})?.map((prObj: ProjectObject) => ({
    project: projects.find((pr: Project) => pr._id === prObj?._id) || null,
    sum: prObj.sum,
    percentages: prObj.stake,
    companyCurrencySum: prObj?.companyCurrencySum,
    transactionSum: prObj?.transactionSum,
  }));
};

export const setEditClient = (counterpartyId: string, clients: Client[]) =>
  clients.find((acc) => acc._id === counterpartyId) || null;

export const setEditCategory = (
  categoryId: string,
  categories: (Category | SubCategory)[],
) => categories.find((cat) => cat._id === categoryId) || null;

export function getDateOfPaymentPeriodId(
  date: number,
  endTimestamp: number,
  startTimestamp: number,
  isCustom?: boolean,
) {
  if (isCustom) return CustomPeriodEnum.custom;
  let dateOfPaymentPeriodId;

  const startMonthOfStartTimeStamp = moment
    .utc(startTimestamp)
    .startOf('month')
    .valueOf();
  const endMonthOfEndTimeStamp = moment
    .utc(endTimestamp)
    .endOf('month')
    .valueOf();

  const isSameMonthOfPeriod =
    startTimestamp &&
    endTimestamp &&
    moment.utc(startTimestamp).isSame(moment.utc(endTimestamp), 'month');

  if (
    startTimestamp !== startMonthOfStartTimeStamp ||
    endTimestamp !== endMonthOfEndTimeStamp ||
    !isSameMonthOfPeriod
  ) {
    dateOfPaymentPeriodId = CustomPeriodEnum.custom;
  } else {
    const isSameMonth = moment(date).isSame(moment(startTimestamp), 'month');
    const isPrevMonth =
      moment(date).diff(moment(startTimestamp), 'month') === 1;

    if (isSameMonth) {
      dateOfPaymentPeriodId = CustomPeriodEnum.currentMonth;
    } else if (isPrevMonth) {
      dateOfPaymentPeriodId = CustomPeriodEnum.prevMonth;
    } else {
      dateOfPaymentPeriodId = CustomPeriodEnum.custom;
    }
  }

  return dateOfPaymentPeriodId;
}

export function prepareEditData(item: JournalData) {
  const {
    _id,
    date,
    type,
    botId,
    subType,
    canEdit,
    canDelete,
    sumDouble,
    scheduled,
    attachments,
    externalId,
    taxPeriodId,
    exchangeRate,
    scheduleDays,
    integrationId,
    dateOfPayment,
    salaryPeriodId,
    transactionSum,
    scheduleEndDate,
    scheduleInterval,
    schedulePeriodId,
    scheduleMaxCount,
    comment: comments,
    companyCurrencySum,
    transactionCurrency,
    scheduleIntervalType,
    taxPeriodEndTimestamp,
    taxPeriodStartTimestamp,
    transactionCurrencyRate,
    salaryPeriodEndTimestamp,
    salaryPeriodStartTimestamp,
    consumptionPeriodEndTimestamp,
    consumptionPeriodStartTimestamp,
    isCustomPeriodId,
  } = item;

  let salaryPeriod;
  let taxPeriod;
  let dateOfPaymentPeriodId;
  let startTimestamp;
  let endTimestamp;

  if (subType === OperationSubType.salary) {
    const today = new Date();

    if (!salaryPeriodId) {
      startTimestamp = today.getTime() + getTimeOffset();
    } else if (salaryPeriodId === 2) {
      salaryPeriod = CustomPeriodEnum.currentMonth;

      const startMonth =
        new Date(today.getFullYear(), today.getMonth(), 1).getTime() +
        getTimeOffset();
      const endMonth =
        new Date(today.getFullYear(), today.getMonth() + 1, 0).getTime() +
        getTimeOffset();

      startTimestamp = startMonth;
      endTimestamp = endMonth;
    } else if (salaryPeriodId === 1) {
      salaryPeriod = CustomPeriodEnum.prevMonth;

      today.setMonth(today.getMonth() - 1);

      const startMonth =
        new Date(today.getFullYear(), today.getMonth(), 1).getTime() +
        getTimeOffset();
      const endMonth =
        new Date(today.getFullYear(), today.getMonth() + 1, 0).getTime() +
        getTimeOffset();

      startTimestamp = startMonth;
      endTimestamp = endMonth;
    } else {
      salaryPeriod = CustomPeriodEnum.custom;

      startTimestamp = salaryPeriodStartTimestamp;
      endTimestamp = salaryPeriodEndTimestamp;
    }
  } else if (subType === OperationSubType.tax) {
    const today = new Date();

    if (!taxPeriodId) {
      startTimestamp = today.getTime();
    } else if (taxPeriodId === 2) {
      taxPeriod = CustomPeriodEnum.currentMonth;

      const startMonth = new Date(
        today.getFullYear(),
        today.getMonth(),
        1,
      ).getTime();
      const endMonth = new Date(
        today.getFullYear(),
        today.getMonth() + 1,
        0,
      ).getTime();

      startTimestamp = startMonth;
      endTimestamp = endMonth;
    } else if (taxPeriodId === 1) {
      taxPeriod = CustomPeriodEnum.prevMonth;

      today.setMonth(today.getMonth() - 1);

      const startMonth = new Date(
        today.getFullYear(),
        today.getMonth(),
        1,
      ).getTime();
      const endMonth = new Date(
        today.getFullYear(),
        today.getMonth() + 1,
        0,
      ).getTime();

      startTimestamp = startMonth;
      endTimestamp = endMonth;
    } else {
      taxPeriod = CustomPeriodEnum.custom;

      startTimestamp = taxPeriodStartTimestamp;
      endTimestamp = taxPeriodEndTimestamp;
    }
  } else if (
    subType !== OperationSubType.sale &&
    subType !== OperationSubType.supplier
  ) {
    startTimestamp = 0;
  } else if (
    subType === OperationSubType.sale ||
    subType === OperationSubType.supplier
  ) {
    startTimestamp = consumptionPeriodStartTimestamp;
    endTimestamp = consumptionPeriodEndTimestamp;

    if (endTimestamp) {
      dateOfPaymentPeriodId = getDateOfPaymentPeriodId(
        date,
        endTimestamp,
        startTimestamp ?? 0,
        isCustomPeriodId,
      );
    }
  }

  const schedulePayload = {
    scheduleDays,
    scheduleEndDate,
    scheduleInterval: Number(scheduleInterval),
    scheduleMaxCount,
    scheduleIntervalType: Number(scheduleIntervalType),
  };

  return {
    id: _id,
    date,
    type,
    botId,
    canEdit,
    canDelete,
    currencyAmount: companyCurrencySum,
    comments,
    sumDouble,
    scheduled,
    taxPeriod,
    attachments,
    externalId,
    dateOfPayment,
    salaryPeriod,
    exchangeRate,
    endTimestamp,
    integrationId,
    startTimestamp,
    schedulePayload,
    schedulePeriodId,
    transactionSum,
    transactionCurrency,
    dateOfPaymentPeriodId,
    transactionCurrencyRate,
  };
}

export const getNewItem = (prev: any[] | null, next: any[]) => {
  const prevIds = prev?.map((el) => el._id) || [];

  return next.find((el) => !prevIds.includes(el._id));
};

export const calculateSumAndCompanyCurrencySum = (
  props: CalculateSumAndCompanyCurrencySum,
) => {
  const {
    type,
    isEdit,
    amount,
    account,
    revertState,
    // isCrypto,
    editProps,
    exchangeRate,
    currencyValue,
    currencyAmount,
    companyCurrency,
  } = props;

  // const exchangeRateDigits = isCrypto ? 9 : 6;
  // const amountRateDigits = isCrypto ? 9 : 2;

  const companyCurrencyId = companyCurrency?._id;
  const accountCurrencyId = account?.currency._id;
  const currencyId = currencyValue?.id;

  const rate = isEdit
    ? Number(exchangeRate ?? editProps?.exchangeRate)
    : Number(exchangeRate);

  if (type === OperationType.transfer) {
    if (revertState) {
      return {
        sum: Number(amount),
        companyCurrencySum: Number(currencyAmount),
        exchangeRateValue: rate,
        transactionSum: Number(currencyAmount),
        transactionCurrency: currencyId,
        transactionCurrencyRate: rate,
      };
    }

    return {
      sum: currencyAmount ? Number(currencyAmount) : Number(amount),
      companyCurrencySum: Number(amount),
      exchangeRateValue: rate,
      transactionSum: Number(amount),
      transactionCurrency: currencyId,
      transactionCurrencyRate: rate,
    };
  }

  if (!accountCurrencyId) {
    return {
      sum: Number(amount),
      companyCurrencySum: Number(amount),
      transactionSum: null,
      transactionCurrency: null,
      transactionCurrencyRate: null,
    };
  }

  if (
    companyCurrencyId === accountCurrencyId &&
    accountCurrencyId === currencyId
  ) {
    return {
      sum: Number(amount),
      companyCurrencySum: null,
      transactionSum: null,
      transactionCurrency: null,
      transactionCurrencyRate: null,
    };
  }

  if (
    companyCurrencyId === accountCurrencyId &&
    accountCurrencyId !== currencyId
  ) {
    return {
      sum: Number(currencyAmount),
      companyCurrencySum: null,
      exchangeRateValue: null,
      transactionSum: Number(amount),
      transactionCurrency: currencyId,
      transactionCurrencyRate: rate,
    };
  }

  if (
    companyCurrencyId !== accountCurrencyId &&
    accountCurrencyId === currencyId
  ) {
    return {
      sum: Number(amount),
      companyCurrencySum: Number(currencyAmount),
      exchangeRateValue: rate,
      transactionSum: Number(amount),
      transactionCurrency: currencyId,
      transactionCurrencyRate: rate,
    };
  }

  if (
    companyCurrencyId !== accountCurrencyId &&
    companyCurrencyId !== currencyId &&
    accountCurrencyId !== currencyId
  ) {
    return {
      sum: Number(currencyAmount),
      companyCurrencySum: null,
      exchangeRateValue: null,
      transactionSum: Number(amount),
      transactionCurrency: currencyId,
      transactionCurrencyRate: rate,
    };
  }

  if (
    companyCurrencyId !== accountCurrencyId &&
    accountCurrencyId !== currencyId
  ) {
    return {
      sum: Number(currencyAmount),
      companyCurrencySum: Number(amount),
      exchangeRateValue: rate,
      transactionSum: Number(amount),
      transactionCurrency: currencyId,
      transactionCurrencyRate: rate,
    };
  }

  return {};
};
