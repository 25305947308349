import { RESET_INTEGRATION_ACCOUNT } from '../integrations/actions';
import { CreateAccountPayload, DeleteAccountPayload } from './sagas.types';
import { AccountValue } from './types';

export const ACCOUNTS_INIT = 'ACCOUNTS_INIT';

export const GET_LOG_ACCOUNTS_PENDING = 'GET_LOG_ACCOUNTS_PENDING';
export const GET_LOG_ACCOUNTS_SUCCESS = 'GET_LOG_ACCOUNTS_SUCCESS';

export const GET_LOG_ACCOUNTS_FOR_FILTERS_PENDING =
  'GET_LOG_ACCOUNTS_FOR_FILTERS_PENDING';
export const GET_LOG_ACCOUNTS_FOR_FILTERS_SUCCESS =
  'GET_LOG_ACCOUNTS_FOR_FILTERS_SUCCESS';

export const GET_OPERATIONS_ACCOUNTS_PENDING =
  'GET_OPERATIONS_ACCOUNTS_PENDING';
export const GET_OPERATIONS_ACCOUNTS_SUCCESS =
  'GET_OPERATIONS_ACCOUNTS_SUCCESS';

export const GET_SETTINGS_ACCOUNTS_PENDING = 'GET_SETTINGS_ACCOUNTS_PENDING';
export const GET_SETTINGS_ACCOUNTS_SUCCESS = 'GET_SETTINGS_ACCOUNTS_SUCCESS';

export const CREATE_ACCOUNT_PENDING = 'CREATE_ACCOUNT_PENDING';
export const CREATE_ACCOUNT_SUCCESS = 'CREATE_ACCOUNT_SUCCESS';

export const DELETE_ACCOUNT_PENDING = 'DELETE_ACCOUNT_PENDING';
export const DELETE_ACCOUNT_SUCCESS = 'DELETE_ACCOUNT_SUCCESS';

export const UPDATE_GROUP_ACCOUNTS_PENDING = 'UPDATE_GROUP_ACCOUNTS_PENDING';
export const UPDATE_GROUP_ACCOUNTS_SUCCESS = 'UPDATE_GROUP_ACCOUNTS_SUCCESS';

export const UPDATE_ONE_ACCOUNT = 'UPDATE_ONE_ACCOUNT';

export const GET_FUTURE_PAYMENTS_BY_PERIOD_PENDING =
  'GET_FUTURE_PAYMENTS_BY_PERIOD_PENDING';
export const GET_FUTURE_PAYMENTS_BY_PERIOD_SUCCESS =
  'GET_FUTURE_PAYMENTS_BY_PERIOD_SUCCESS';

export const RESET_NEW_ACCOUNT = 'RESET_NEW_ACCOUNT';

const getOperationAccounts = () => ({
  type: GET_OPERATIONS_ACCOUNTS_PENDING,
});

const getSettingsAccounts = () => ({
  type: GET_SETTINGS_ACCOUNTS_PENDING,
});

const getLogAccounts = () => ({
  type: GET_LOG_ACCOUNTS_PENDING,
});

const getLogForFiltersAccounts = () => ({
  type: GET_LOG_ACCOUNTS_FOR_FILTERS_PENDING,
});

const removeAccountIntegrationProgress = (id: string) => ({
  type: RESET_INTEGRATION_ACCOUNT,
  payload: { id },
});

const updateFuturePaymentsPeriod = (
  futurePaymentsTotalMonths: number | null,
) => ({
  type: GET_FUTURE_PAYMENTS_BY_PERIOD_PENDING,
  payload: { futurePaymentsTotalMonths },
});

const setLogAccounts = (data: AccountValue[]) => ({
  type: GET_LOG_ACCOUNTS_SUCCESS,
  payload: { data },
});

const setLogForFiltersAccounts = (data: AccountValue[]) => ({
  type: GET_LOG_ACCOUNTS_FOR_FILTERS_SUCCESS,
  payload: { data },
});

const updateGroupAccounts = (accounts: AccountValue[]) => ({
  type: UPDATE_GROUP_ACCOUNTS_PENDING,
  payload: { accounts },
});

const deleteAccount = (data: DeleteAccountPayload) => ({
  type: DELETE_ACCOUNT_PENDING,
  payload: data,
});

const createAccount = (data: CreateAccountPayload) => ({
  type: CREATE_ACCOUNT_PENDING,
  payload: data,
});

const resetNewAccount = () => ({
  type: RESET_NEW_ACCOUNT,
});

export default {
  deleteAccount,
  createAccount,
  getLogAccounts,
  setLogAccounts,
  resetNewAccount,
  updateGroupAccounts,
  getSettingsAccounts,
  getOperationAccounts,
  updateFuturePaymentsPeriod,
  removeAccountIntegrationProgress,
  getLogForFiltersAccounts,
  setLogForFiltersAccounts,
};
