import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  Config,
} from '@finmap/import-parsers/base-import-parser-v3';

export class MBankVATPdfParser extends BaseImportParserV3 {
  // public debug: boolean = true;
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.PDF]: [
      {
        caseOptions: {
          defaultCurrency: 'EUR',
          isCurCase: [
            [
              0,
              {
                in: 'Lista operacji za okres od',
              },
            ],
            [
              12,
              {
                eq: 'Operacje',
              },
            ],
            [
              13,
              {
                eq: 'Data',
              },
            ],
          ],
          preParserConfigs: {
            verticalAlign: 'top',
            pageSeparatorsLengthPx: 15,
            prepareRawConfig: {
              findHeader: {
                from: [
                  {
                    word: {
                      eq: 'Data',
                    },
                    nextWord: {
                      eq: 'Opis operacji',
                    },
                  },
                ],
                to: [
                  {
                    word: {
                      eq: 'operacji',
                    },
                    prevWord: {
                      eq: 'operacji',
                    },
                  },
                ],
              },
              delete: [
                {
                  to: [
                    {
                      word: {
                        eq: 'Data',
                      },
                      nextWord: {
                        eq: 'Opis operacji',
                      },
                    },
                  ],
                  count: 1,
                },
              ],
              define: [
                {
                  dateFormat: 'YYYY-MM-DD',
                },
              ],
            },
          },
          proceedCaseConfig: {
            fields: {
              date: {
                column: ['Data operacji', 0],
                dateFormat: 'YYYY-MM-DD',
              },
              comment: {
                column: ['Opis operacji', 1],
              },
              sum: {
                column: ['Kwota', 4],
                replace: [
                  {
                    from: ' EUR',
                    to: '',
                  },
                ],
              },
            },
          },
        },
      },
      {
        caseOptions: {
          defaultCurrency: 'EUR',
          isCurCase: [
            [
              0,
              {
                in: 'Lista operacji za okres od',
              },
            ],
          ],
          preParserConfigs: {
            verticalAlign: 'top',
            interlineSpacingAccuracy: 30,
            prepareRawConfig: {
              findHeader: {
                from: [
                  {
                    word: {
                      eq: 'Data operacji',
                    },
                    nextWord: {
                      eq: 'Opis operacji',
                    },
                  },
                ],
                to: [
                  {
                    word: {
                      eq: 'Kwota',
                    },
                    prevWord: {
                      eq: 'Kategoria',
                    },
                  },
                ],
              },
              delete: [
                {
                  to: [
                    {
                      word: {
                        eq: 'Data operacji',
                      },
                      nextWord: {
                        eq: 'Opis operacji',
                      },
                    },
                  ],
                  count: 1,
                },
              ],
              define: [
                {
                  dateFormat: 'YYYY-MM-DD',
                },
              ],
            },
          },
          proceedCaseConfig: {
            fields: {
              date: {
                column: ['Data operacji', 0],
                dateFormat: 'YYYY-MM-DD',
              },
              comment: {
                column: ['Opis operacji', 1],
              },
              sum: {
                column: ['Kwota', 4],
                replace: [
                  {
                    from: ' EUR',
                    to: '',
                  },
                ],
              },
            },
          },
        },
      },

      {
        caseOptions: {
          defaultCurrency: 'EUR',
          isCurCase: [
            [
              0,
              {
                in: 'Elektroniczne zestawienie operacji za okres od',
              },
            ],
          ],
          preParserConfigs: {
            verticalAlign: 'top',
            prepareRawConfig: {
              findHeader: {
                from: [
                  {
                    word: {
                      eq: 'Data',
                    },
                    nextWord: {
                      eq: 'Data',
                    },
                  },
                ],
                to: [
                  {
                    word: {
                      eq: 'operacji',
                    },
                    prevWord: {
                      eq: 'operacji',
                    },
                  },
                ],
              },
              delete: [
                {
                  to: [
                    {
                      word: {
                        eq: 'Data',
                      },
                      nextWord: {
                        eq: 'Data',
                      },
                    },
                  ],
                  count: 1,
                },
              ],
              define: [
                {
                  dateFormat: 'YYYY-MM-DD',
                },
              ],
            },
          },
          proceedCaseConfig: {
            fields: {
              date: {
                column: ['Data\noperacji', 1],
                dateFormat: 'YYYY-MM-DD',
              },
              comment: {
                column: ['Opis operacji', 2],
              },
              sum: {
                column: ['Kwota', 3],
              },
            },
          },
        },
      },
    ],
  };
}
