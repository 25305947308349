import EnIcon from '../assets/images/langs/en.svg';
import PlIcon from '../assets/images/langs/pl.svg';
import RuIcon from '../assets/images/langs/ru.svg';
import UkIcon from '../assets/images/langs/uk.svg';
import { UrlPrefix } from '../store/clients/types';
import { OperationSubType } from '../store/operations/types';
import { ENV_BASE_URL, ENV_UI_URL } from './env';

export const ONESIGNAL_APP_ID = '29814375-fa04-4df7-8b48-27c2c40c88e5';
export const ONESIGNAL_API_KEY =
  'os_v2_app_fgaug5p2arg7pc2ie7bmidei4wxz2jbsdbze6onlwlrzqkxn6p2a7kfdse44c2l4bf4ipx55wq6hrh32754tut5hlwn4glmmilba6oy';
export const ONESIGNAL_API = 'https://onesignal.com/api/v1/';

export const MAX_ALLOW_AUTO_RULE_COUNT = 50;
export const DELAY_UPDATE_GROUP = 2000;
export const DELAY_UPDATE_GROUP_LONG = 4000;
export const DELAY_UPDATE_ANALYTIC = 2000;
export const DELAY_UPDATE_LOG = 1500;
export const DELAY_UPDATE_HISTORY_LOG = 1000;
export const DELAY_UPDATE_EMPLOYEES_PERMISSIONS = 1500;

export const SUBSCRIPTION_YEAR_PRO_DISCOUNT = 20;
export const SUBSCRIPTION_YEAR_LITE_DISCOUNT = 20;
export const SUBSCRIPTION_HALF_YEAR_PRO_DISCOUNT = 15;
export const SUBSCRIPTION_HALF_YEAR_LITE_DISCOUNT = 15;

export const PROJECTS_AMOUNT_CHARTS = 5;

export const EXCHANGE_RATE_DIGITS = 9;

export const CRYPTO_EXCHANGE_RATE_DIGITS = 9;

export const BALANCE_DIGITS = 3;

export const WISE_UNIVERSAL_CODE = 'transferwise_oauth_client_xo';

export const WISE_UNIVERSAL_NAME = 'Wise (former Transferwise)';

export const PAYPAL_UNIVERSAL_CODE = 'paypal_xo';

export const PAYPAL_UNIVERSAL_NAME = 'PayPal';

export const REFERRAL_LINK = 'https://finmap.online/referal';

export const MIN_PHONE_DIGITS = 6;

export const FORBIDDEN_COUNTRIES_CODES = ['RU', 'ru', 'RUS', 'rus'];

export const FORBIDDEN_COUNTRIES_REDIRECT_URL = 'ru.finmap.online';

export const GEO_LOCATION_URL = 'https://ip.finmap.online/';

export const CACHE_LOCATION_HOURS = 24;

export const MIN_OPERATION_DATE = '01.01.2015';

export const MAX_OPERATION_DATE_IN_YEARS = 10;

export const PRIVAT_24_MERCHANT_HELP_LINK_UA =
  'https://support.finmap.online/integracziya-z-pryvat24-fizosoba/';

export const PRIVAT_BUSINESS_AUTO_CLIENT_LINK_HELP_UA =
  'https://support.finmap.online/integracziya-z-pryvat24-biznes/';

export const PRIVAT_24_MERCHANT_HELP_LINK_RU =
  'https://support.finmap.online/ru/yntegraczyya-s-pryvat24-fyz-lycza/';

export const PRIVAT_BUSINESS_AUTO_CLIENT_LINK_HELP_RU =
  'https://support.finmap.online/ru/yntegraczyya-s-pryvat24-byznes/';

export const PUBLIC_OFFER_LINK =
  'https://my.finmap.online/finmap-public-offers-v1.html';

export const PRIVACY_NOTICE_LINK =
  'https://my.finmap.online/finmap-privacy-notice-v1.html';

export const SUPPORT_EMAIL = 'support@finmap.online';

export const EMPTY = 'empty';

export const EMPTY_ID = '000000000000000000000000';

export const DEFAULT = 'default';

export const DEFAULT_LANGUAGE = 'en';

export const SMART_LOOK_TOKEN = '06d73f66c4235ccf88829f56772eea02abaa444e';

export const DEFAULT_COUNTRY = 'Ukraine';

export const DEFAULT_UKRAINE_CURRENCY_SYMBOL = 'UAH';

export const DEFAULT_USD_CURRENCY_SYMBOL = 'USD';

export const GET_SERVER_TIME_INTERVAL = 3600000; // an hour

export const FACEBOOK_PIXEL_ID = '2158834257684398';

export const DAYS_AFTER_BLOCK = 1;

export const DAYS_TO_SHOW_TRIAL = 3;

export const ACTIVE_TRIAL_DAY = 3;

export const DEFAULT_TRIAL_DISCOUNT = 50;

export const TRIAL_SUBSCRIPTION_DAYS = 14;

export const DEFAULT_TRIAL_DAYS_TO_SHOW_BADGE = 7;

export const IMPORT_CHUNK_SIZE = 1000;

export const ESCAPE_KEY_CODE = 27;

export const ENTER_KEY_CODE = 13;

export const TAB_KEY_CODE = 9;

export const BASE_URL = ENV_BASE_URL;

export const UI_URL = ENV_UI_URL;

export const APPLE_STORE_LINK =
  'https://apps.apple.com/ua/app/finmap-business-money-tracker/id1605388450';

export const ANDROID_STORE_LINK =
  'https://play.google.com/store/apps/details?id=online.finmap.my';

export const UNLEASH_SECRET = 'd36aae59-62cf-4ca6-8c78-6c89ad26c883';

export const OWNER_ROLE_ID = '60049580d06fcf7e292d5ccb';

export const INTERCOM_APP_ID = 'm4e3h09o';

export const WITHOUT_ACCOUNT_NAME = 'AccountNotSpecified';

export const GA_MEASUREMENT_ID = 'UA-115468215-1';

export const INTEGRATION_TOAST_ID = 'integrationInProgress';

export const EXPORT_TOAST_ID = 'exportInProgress';

export const UPDATE_OPERATIONS_TOAST_ID = 'updateOperationsInProgress';
export const CREATE_PDF_TOAST_ID = 'createPDFInProgress';
export const COMPLETE_INTEGRATION_TOAST_ID = 'completeIntegrationInProgress';

export const IMPORT_TOAST_ID = 'importInProgress';

export const TELEGRAM_URL = 'https://t.me/finmap_beta_bot';

export const MAX_INPUT_LENGTH = 150;

export const MAX_COMMENT_LENGTH = 1000;

export const MAX_IMPORT_FILE_SIZE = 1e7;

export const MAX_IMPORT_OPERATION_FILE_SIZE = 16e6;

export const LIMIT_ITEMS_PER_PAGE = 25;

export const LIMIT_HISTORY_ITEMS_PER_PAGE = 100;

// Seconds in day
export const MAX_TIME_BEFORE_SHOW_APPROVE_REMOVE = 24 * 60 * 60;

export const fontWeightBold = 500;

export const Unauthorized = 401;

export const PaymentRequired = 402;

export const Forbidden = 403;

export enum Order {
  asc = 'asc',
  desc = 'desc',
}

export type LanguageItemProps = {
  name: string;
  shortName: string;
  isoName: string;
  imgSrc?: string;
  featureToggle?: string;
};

export const defaultLanguages: LanguageItemProps[] = [
  {
    name: 'languages.ukrainian',
    shortName: 'uk',
    isoName: 'UK',
    imgSrc: UkIcon,
  },
  {
    name: 'languages.english',
    shortName: 'en',
    isoName: 'EN',
    imgSrc: EnIcon,
  },
  {
    name: 'languages.polish',
    shortName: 'pl',
    isoName: 'PL',
    imgSrc: PlIcon,
  },
];

const additionalLanguages: LanguageItemProps[] = [
  {
    name: 'languages.portuguese',
    shortName: 'pt',
    isoName: 'PT',
    imgSrc: RuIcon,
    featureToggle: 'showPortugueseLanguage',
  },
  {
    name: 'languages.french',
    shortName: 'fr',
    isoName: 'FR',
    imgSrc: EnIcon,
    featureToggle: 'showFrenchLanguage',
  },
  {
    name: 'languages.brazil',
    shortName: 'pt-BR',
    isoName: 'PT-BR',
    imgSrc: RuIcon,
    featureToggle: 'showBrazilLanguage',
  },
  {
    name: 'languages.spanish',
    shortName: 'es',
    isoName: 'ES',
    imgSrc: PlIcon,
    featureToggle: 'showSpanishLanguage',
  },
  {
    name: 'languages.italian',
    shortName: 'it',
    isoName: 'IT',
    imgSrc: PlIcon,
    featureToggle: 'showItalianLanguage',
  },
  {
    name: 'languages.сzech',
    shortName: 'cz',
    isoName: 'CZ',
    imgSrc: PlIcon,
    featureToggle: 'showCzechLanguage',
  },
  {
    name: 'languages.turkish',
    shortName: 'tr',
    isoName: 'TR',
    imgSrc: PlIcon,
    featureToggle: 'showTurkishLanguage',
  },
  {
    name: 'languages.kazakh',
    shortName: 'kk',
    isoName: 'KK',
    imgSrc: PlIcon,
    featureToggle: 'showKazakhLanguage',
  },
  {
    name: 'languages.uzbek',
    shortName: 'uz',
    isoName: 'UZ',
    imgSrc: RuIcon,
    featureToggle: 'showUzbekLanguage',
  },
  {
    name: 'languages.russian',
    shortName: 'ru',
    isoName: 'RU',
    imgSrc: RuIcon,
    featureToggle: 'showRussianLanguage',
  },
];

export const languagesData = (useAdditionalLanguages: boolean) =>
  useAdditionalLanguages
    ? defaultLanguages.concat(additionalLanguages)
    : defaultLanguages;

export type ClientPrefixBySubType = {
  [key in OperationSubType]: UrlPrefix;
};

export const clientPrefixBySubType: Omit<ClientPrefixBySubType, 'generic'> = {
  [OperationSubType.sale]: UrlPrefix.clients,
  [OperationSubType.investment]: UrlPrefix.investors,
  [OperationSubType.loan]: UrlPrefix.borrowers,
  [OperationSubType.loanRepayment]: UrlPrefix.creditors,
  [OperationSubType.loanrepayment]: UrlPrefix.creditors,
  [OperationSubType.dividend]: UrlPrefix.owners,
  [OperationSubType.salary]: UrlPrefix.staffMembers,
  [OperationSubType.supplier]: UrlPrefix.suppliers,
  [OperationSubType.tax]: UrlPrefix.taxOrganisations,
};

export const lngToUrlOldEmptyStateOnboardPage: { [key in string]: string } = {
  ru: 'https://www.youtube.com/watch?v=n3-m3B8U8DU&list=PLa4GyR1HcCMyB3O88RFsaBWFJPsfQap95',
  uk: 'https://www.youtube.com/watch?v=gmuYi46ke2w&ab_channel=FINMAP.online%5BUkrainian%5D',
  pl: 'https://www.youtube.com/watch?v=nmI8px4x_WY&t=3s&ab_channel=FINMAP.online%5BPolish%5D',
  en: 'https://www.youtube.com/watch?v=hliCzOAM5qI&ab_channel=FINMAP.online%5BEnglish%5D',
};

export const lngToUrlHowToImport: { [key in string]: string } = {
  ru: 'https://www.youtube.com/watch?v=D4giaad7IPA&list=PLa4GyR1HcCMyB3O88RFsaBWFJPsfQap95',
  uk: 'https://www.youtube.com/watch?v=fHyNIRLMwvE&list=PLcNuuvhDn8IGsiSxVO4N57thmYZhxNlXB&index=2&ab_channel=FINMAP.online%5BUkrainian%5D',
  pl: 'https://www.youtube.com/watch?v=_6tjmshQZX0&list=PLR3Hr5wTdvpp3gQPqdV454QdBQ3VQoE8E&index=7&ab_channel=FINMAP.online%5BPolish%5D',
  en: 'https://www.youtube.com/watch?v=OR92yYyFNBM&t=7s&ab_channel=FINMAP.online%5BEnglish%5D',
};

export const lngToVimeoLink: { [key in string]: string } = {
  ru: 'https://youtu.be/Xp1fwo8vMrA',
  uk: 'https://www.youtube.com/watch?v=PGHeVyr5_mc',
  pl: 'https://vimeo.com/776157765',
  en: 'https://player.vimeo.com/video/646850026',
  tr: 'https://vimeo.com/776908541',
};

export const usersIdFiltersFeature = [
  '62bc050ac11307fbf9c1e509',
  '62a0c9fdd257497cdc4c00c8',
  '63d06385e24653357320b246',
  '61deacfe2a23320ec70ebe42',
  '62989800f077e74c8fb22818',
  '636e8cb78dfba952304ecfc5',
];
