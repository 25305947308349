export async function timeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms || 0));
}

export const getOffsetByTimeZoneAndDate = (
  timeZone: string, // 'Europe/Kiev' format
  date: Date | number = new Date(),
) => {
  return (
    new Date(new Date(date).toLocaleString('en-US', { timeZone })).getTime() -
    new Date(date).getTime()
  );
};
