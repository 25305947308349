import { Context } from '../store/types';
import Http from './Http';

const getAccounts = (context: Context, usedForFilters = false) => {
  if (usedForFilters)
    return Http.makeInstance().get(
      `/accounts?context=${context}&usedForFilters=true`,
    );
  return Http.makeInstance().get(`/accounts?context=${context}`);
};

const createAccount = (
  currencyId: string,
  name: string,
  startingBalance = 0,
  typeId?: string,
  isCrypto?: boolean,
) =>
  Http.makeInstance().post('/accounts', {
    typeId,
    currencyId,
    name,
    startingBalance,
    crypto: isCrypto,
  });

const updateAccount = (
  id: string,
  currencyId: string,
  name: string,
  startingBalance = 0,
  typeId?: string,
) =>
  Http.makeInstance().patch(`/accounts/${id}`, {
    typeId,
    currencyId,
    name,
    startingBalance,
  });

const updatePropAccount = (id: string, data: any) =>
  Http.makeInstance().patch(`/accounts/${id}?isBulkUpdate=true`, {
    ...data,
  });

const deleteAccount = (id: string) =>
  Http.makeInstance().delete(`/accounts/${id}`);

const getFuturePayments = () => Http.makeInstance().get('/futurePayments');

const getFuturePaymentsByPeriod = (futurePaymentsTotalMonths: number) =>
  Http.makeInstance().get(
    `/futurePayments/byPeriod?futurePaymentsTotalMonths=${futurePaymentsTotalMonths}`,
  );

export default {
  createAccount,
  getAccounts,
  updateAccount,
  updatePropAccount,
  deleteAccount,
  getFuturePayments,
  getFuturePaymentsByPeriod,
};
