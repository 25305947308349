import moment from 'moment';
import xlsx from 'xlsx';

import { TranslationService } from '@finmap/core-translations';
import { normalize } from '@finmap/core-utils';

import { SUBTYPE_MAP } from './datasets/subTypes';

export enum OperationType {
  INCOME = 'income',
  CONSUMPTION = 'consumption',
  TRANSFER = 'transfer',
}

export interface AnyObject {
  [key: string]: any;
}

export class BaseXlsxParser {
  private readonly FORMAT2 = 'YYYY-MM-DD';
  protected readonly FORMAT2WithTime = 'YYYY-MM-DD HH:mm:ss';
  protected readonly COMMENT_DEVIDER = '; ';
  protected readonly TAGS_DEVIDER = ', ';
  protected readonly FALSY_VALUES = [undefined, null, ''];
  protected readonly UPPER_ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

  protected translationsService: TranslationService;
  protected currentUserTranslations: any = {};
  protected headersJson: AnyObject;
  protected sheetJson: AnyObject[];
  protected acounts: AnyObject[];
  protected COLUMNS_MAP: AnyObject = {};
  protected transformedData: AnyObject[];
  protected importName: string;
  protected statementsOptions: any = [];
  protected currency = '';
  protected statementName = '';
  protected dateFormat = this.FORMAT2;

  constructor(lang = 'en') {
    this.translationsService = new TranslationService();
    this.currentUserTranslations =
      this.translationsService.getResources()[
        lang === 'pt-br' ? 'pt-BR' : lang
      ];
  }

  protected getErrors() {
    return this.currentUserTranslations.translation.import.errors;
  }

  protected checkIfNotFalsy(value: any) {
    return this.FALSY_VALUES.every((val) => val !== value);
  }

  protected addIfNotFalsy(target: AnyObject, sourse: AnyObject): void {
    const sourseKeys = Object.keys(sourse);
    const sourseValues = Object.values(sourse);
    for (let i = 0; i < sourseValues.length; i++)
      if (this.checkIfNotFalsy(sourseValues[i]))
        target[sourseKeys[i]] = sourseValues[i];
  }

  private findSubType(type: OperationType, category: any): string {
    let parsedCategory = category;
    if (typeof category !== 'string') {
      try {
        parsedCategory = category.toString();
      } catch (error) {
        console.error(error);
        parsedCategory = 'unknownCategory';
      }
    }

    const sample = SUBTYPE_MAP[type];
    const sampleKeys = Object.keys(sample);
    const lowerCategory = parsedCategory.toLowerCase();
    for (const key of sampleKeys) {
      const subType = sample[key].find(
        (v) => v.toLowerCase() === lowerCategory,
      );
      if (subType) return key;
    }
  }

  protected getSubType(
    type: OperationType,
    category: string | undefined,
  ): string {
    if (type === OperationType.TRANSFER) return;
    if (category) {
      const subType = this.findSubType(type, category);
      if (subType) return subType;
    }
    if (type === OperationType.INCOME) return 'sale';
    if (type === OperationType.CONSUMPTION) return 'supplier';
  }

  protected setAccountIDsByType(
    operation: AnyObject,
    currencyFrom,
    currencyTo,
  ): void {
    const to = operation.accountToId;
    const from = operation.accountFromId;
    delete operation.accountToId;
    delete operation.accountFromId;
    if (!operation.type)
      throw new Error('Call getAccountByType before get type');
    if (operation.type !== OperationType.CONSUMPTION && to) {
      const account = this.getAccountIdByName(
        this.acounts,
        to,
        currencyTo || currencyFrom,
      );
      if (typeof account !== 'string' && account?.name)
        operation.accountToObject = account;
      else operation.accountToId = account;
    }
    if (operation.type !== OperationType.INCOME && from) {
      const account = this.getAccountIdByName(
        this.acounts,
        from,
        currencyFrom || currencyTo,
      );
      if (typeof account !== 'string' && account?.name)
        operation.accountFromObject = account;
      else operation.accountFromId = account;
    }
  }

  protected setComments(operation: AnyObject, sourse: Array<any>): void {
    const comment = [];
    for (const s of sourse) if (s) comment.push(s);
    if (comment.length > 0)
      operation.comment = comment.join(this.COMMENT_DEVIDER);
  }

  protected dateToFormat2(date: Date): string {
    return moment(date).add(2, 'minute').format(this.FORMAT2); // 23.59.59
  }

  protected dateToFormat2WithTime(date: Date, timeStr: string) {
    const formatDate = moment(date),
      time = moment(timeStr, 'HH:mm:ss');

    formatDate.set({
      hour: time.get('hour'),
      minute: time.get('minute'),
      second: time.get('second'),
    });

    return formatDate.format(this.FORMAT2WithTime);
  }

  protected getAccountIdByName(accounts: any, name: string, currency) {
    let needAddImportName = false;

    const isExistingOperation = (item) => {
      const isSameName = item.normalizedLabel === normalize(name);
      const isSameСurrency = item.currency.code === currency;
      if (isSameName && currency && !isSameСurrency) {
        needAddImportName = true;
        return false;
      }
      return isSameName;
    };

    const account = accounts.filter(isExistingOperation);
    const result = account.length
      ? account[0]._id
      : {
          name: needAddImportName ? `${name}(${this.importName})` : name,
          currencyId: currency,
        };

    return result;
  }

  protected throwError(message: string, line: number): never {
    throw new Error(
      JSON.stringify({
        message,
        line,
      }),
    );
  }

  protected sumErrorer(sum) {
    if (!this.checkIfNotFalsy(sum))
      return (errors) => `${errors.sumIsRequired}`;
    const isNumber = typeof sum === 'number' && isFinite(sum) && !isNaN(sum);
    if (!isNumber) return (errors) => `${sum} - ${errors.sumNotValid}`;
  }

  protected dateNotValidErrorer(date) {
    const dateIsBrocken = date && !(date instanceof Date);
    if (dateIsBrocken) return (errors) => `${date} - ${errors.dateNotValid}`;
  }

  protected getAllColumns(operation: AnyObject, index: number): AnyObject {
    const errors = this.getErrors();
    const keys = Object.keys(this.COLUMNS_MAP);
    const resO = {};
    for (const key of keys) {
      const isFinal = key === 'final';
      const [, character, errorer] = this.COLUMNS_MAP[key];
      const value = !isFinal ? operation[character] : resO;
      const checked = errorer ? errorer.call(this, value) : null;
      if (checked) console.log(key, index);
      if (checked) this.throwError(checked.call(this, errors), index);
      if (!isFinal) resO[key] = value;
    }
    return resO;
  }

  protected transformOne(operation: AnyObject, index: number): AnyObject {
    return operation;
  }

  protected doBeforeTranform(): AnyObject[] {
    return this.sheetJson;
  }

  protected doAfterTranform(): AnyObject[] {
    return this.transformedData;
  }

  public setRawData(raw, account) {
    // : Account
    const wb = xlsx.read(raw, { type: 'buffer', cellDates: true });
    const wsName = wb.SheetNames[0];
    const sheet = wb.Sheets[wsName];

    const allSheet = xlsx.utils.sheet_to_json(sheet, { header: 'A' });

    this.headersJson = allSheet[0];
    this.sheetJson = allSheet.slice(1);
  }

  public import(acounts: AnyObject[]) {
    this.acounts = acounts;
    const beforeTransformed = this.doBeforeTranform();
    this.transformedData = beforeTransformed
      .map(this.transformOne.bind(this))
      .filter((res) => Object.keys(res).length);
    const result = this.doAfterTranform();
    // console.log(result);
    return result;
  }

  public dateParser(inputStr: any): Date {
    let parsedStr = inputStr;
    if (typeof inputStr !== 'string') {
      try {
        parsedStr = String(inputStr).trim();
      } catch (error) {
        console.log(error);
      }
    }
    parsedStr = parsedStr.replace(/ {2,}/g, ' ');

    const customFormats = [
      'D-M-YYYY',
      'D-M-YYYY H:m:s',
      'D-M-YYYY H/m/s',
      'D-M-YYYY H-m-s',
      'D-M-YYYY H:m',
      'D-M-YYYY H/m',
      'D-M-YYYY H-m',
      'D/M/YYYY',
      'D/M/YYYY H:m:s',
      'D/M/YYYY H/m/s',
      'D/M/YYYY H-m-s',
      'D/M/YYYY H:m',
      'D/M/YYYY H/m',
      'D/M/YYYY H-m',
      'D.M.YYYY',
      'D.M.YYYY H:m:s',
      'D.M.YYYYY H/m/s',
      'D.M.YYYY H-m-s',
      'D.M.YYYY H:m',
      'D.M.YYYYY H/m',
      'D.M.YYYY H-m',

      'D-M-YY',
      'D-M-YY H:m:s',
      'D-M-YY H/m/s',
      'D-M-YY H-m-s',
      'D-M-YY H:m',
      'D-M-YY H/m',
      'D-M-YY H-m',
      'D/M/YY',
      'D/M/YY H:m:s',
      'D/M/YY H/m/s',
      'D/M/YY H-m-s',
      'D/M/YY H:m',
      'D/M/YY H/m',
      'D/M/YY H-m',
      'D.M.YY',
      'D.M.YY H:m:s',
      'D.M.YY H/m/s',
      'D.M.YY H-m-s',
      'D.M.YY H:m',
      'D.M.YY H/m',
      'D.M.YY H-m',
    ];
    if (moment(parsedStr, customFormats, true).isValid())
      return moment(parsedStr, customFormats, true).toDate();

    return moment(parsedStr).toDate();
  }

  public parseSum(rawSum: any): number {
    let strArray = (JSON.stringify(rawSum) + '').split('');

    // for such keys: '28,800.00'
    const dotAndCommaPresent =
      strArray.find((s) => s === ',') && strArray.find((s) => s === '.');
    const commaPresentFirst =
      strArray.find((s) => s === ',' || s === '.') === ',';
    const commaShouldBeIgnored = dotAndCommaPresent && commaPresentFirst;
    if (commaShouldBeIgnored) strArray = strArray.filter((s) => s !== ',');

    const result = strArray.reduce((acc, cur) => {
      if (cur === ',' || cur === '.') {
        acc += '.';
        return acc;
      }
      const num = Number(cur);
      const curCode = cur.charCodeAt(0);
      if (!isNaN(num) && curCode !== 160 && cur !== ' ') acc += num;
      return acc;
    }, '');
    if (result.split('.').length > 2) return NaN;
    return parseFloat(result);
  }
}
