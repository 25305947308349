import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  Config,
} from '@finmap/import-parsers/base-import-parser-v3';

export class WayforpayParser extends BaseImportParserV3 {
  // public debug: boolean = true;
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.XLSX]: [
      {
        caseOptions: {
          defaultCurrency: 'UAH',
          withoutEmpty: true,
          proceedCaseConfig: {
            fields: {
              sum: {
                column: ['Сумма', 5],
              },
              dateAndTime: {
                column: ['Время запроса', 2],
              },
              comment: {
                column: ['Товары', 8],
              },
              counterparty: {
                column: ['Email', 12],
              },
            },
          },
        },
      },
    ],
  };
}
