import { AnyAction } from 'redux';

import { WITHOUT_ACCOUNT_NAME } from '../../constants';
import { initialContext } from '../constant';
import {
  ACCOUNTS_INIT,
  CREATE_ACCOUNT_SUCCESS,
  GET_FUTURE_PAYMENTS_BY_PERIOD_SUCCESS,
  GET_LOG_ACCOUNTS_SUCCESS,
  GET_LOG_ACCOUNTS_FOR_FILTERS_SUCCESS,
  GET_OPERATIONS_ACCOUNTS_SUCCESS,
  GET_SETTINGS_ACCOUNTS_SUCCESS,
  RESET_NEW_ACCOUNT,
  UPDATE_GROUP_ACCOUNTS_PENDING,
  UPDATE_GROUP_ACCOUNTS_SUCCESS,
  UPDATE_ONE_ACCOUNT,
} from './actions';
import { AccountsState, AccountValue } from './types';

const initialState: AccountsState = {
  accounts: {
    ...initialContext,
  },
  newAccount: null,
  futurePayments: [],
  loadingAccounts: false,
  loadingFuturePayments: false,
  editAccountInProgress: false,
};

// eslint-disable-next-line default-param-last
const accountReducer = (state = initialState, { type, payload }: AnyAction) => {
  switch (type) {
    case ACCOUNTS_INIT: {
      return {
        ...state,
        accounts: { ...initialContext },
      };
    }
    case UPDATE_GROUP_ACCOUNTS_PENDING: {
      return {
        ...state,
        editAccountInProgress: true,
      };
    }

    case UPDATE_GROUP_ACCOUNTS_SUCCESS: {
      return {
        ...state,
        editAccountInProgress: false,
      };
    }

    case UPDATE_ONE_ACCOUNT: {
      const { accountId, amount: balance } = payload;
      const accounts = state.accounts.log.map((el) => {
        if (el._id === accountId) {
          return { ...el, balance, companyCurrencyBalance: balance };
        }

        return el;
      });

      return {
        ...state,
        accounts: {
          ...state.accounts,
          log: accounts,
        },
      };
    }

    case GET_SETTINGS_ACCOUNTS_SUCCESS: {
      return {
        ...state,
        accounts: {
          ...state.accounts,
          settings: payload.data,
        },
        loadingAccounts: false,
      };
    }

    case GET_OPERATIONS_ACCOUNTS_SUCCESS: {
      return {
        ...state,
        accounts: {
          ...state.accounts,
          operation: payload.data,
        },
      };
    }

    case GET_LOG_ACCOUNTS_SUCCESS: {
      const accounts = payload.data.map((el: AccountValue) => {
        if (el.name === WITHOUT_ACCOUNT_NAME) {
          return {
            ...el,
            _id: WITHOUT_ACCOUNT_NAME,
          };
        }

        return el;
      });

      return {
        ...state,
        errors: {},
        isError: false,
        loading: false,
        accounts: {
          ...state.accounts,
          log: accounts,
        },
        loadingAccounts: false,
      };
    }

    case GET_LOG_ACCOUNTS_FOR_FILTERS_SUCCESS: {
      const accounts = payload.data.map((el: AccountValue) => {
        if (el.name === WITHOUT_ACCOUNT_NAME) {
          return {
            ...el,
            _id: WITHOUT_ACCOUNT_NAME,
          };
        }

        return el;
      });

      return {
        ...state,
        errors: {},
        isError: false,
        loading: false,
        accounts: {
          ...state.accounts,
          filters: accounts,
        },
        loadingAccounts: false,
      };
    }

    case RESET_NEW_ACCOUNT: {
      return {
        ...state,
        newAccount: null,
      };
    }
    case CREATE_ACCOUNT_SUCCESS: {
      const accounts = payload.data.accounts.map((el: AccountValue) => {
        if (el.name === WITHOUT_ACCOUNT_NAME) {
          return {
            ...el,
            _id: WITHOUT_ACCOUNT_NAME,
          };
        }

        return el;
      });

      return {
        ...state,
        errors: {},
        isError: false,
        loading: false,
        accounts: {
          ...state.accounts,
          log: accounts,
        },
        newAccount: payload.data.newAccount,
        loadingAccounts: false,
      };
    }

    case GET_FUTURE_PAYMENTS_BY_PERIOD_SUCCESS:
      return {
        ...state,
        futurePayments: payload.futurePayments,
        loadingFuturePayments: false,
      };

    default:
      return state;
  }
};

export default accountReducer;
