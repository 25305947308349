import {
  HistoryActionOperationPayload,
  HistoryUpdateOperationPayload,
} from './api.types';
import {
  SetCustomFilterPayload,
  SetOperationIdPayload,
  SetPeriodPayload,
} from './history.actions.types';

export const GET_DEBIT_HISTORY_DATA_PENDING = 'GET_DEBIT_HISTORY_DATA_PENDING';
export const GET_DEBIT_HISTORY_DATA_SUCCESS = 'GET_DEBIT_HISTORY_DATA_SUCCESS';

export const GET_CREDIT_HISTORY_DATA_PENDING =
  'GET_CREDIT_HISTORY_DATA_PENDING';
export const GET_CREDIT_HISTORY_DATA_SUCCESS =
  'GET_CREDIT_HISTORY_DATA_SUCCESS';

export const DELETE_HISTORY_OPERATION_PENDING =
  'DELETE_HISTORY_OPERATION_PENDING';

export const RESTORE_HISTORY_OPERATION_PENDING =
  'RESTORE_HISTORY_OPERATION_PENDING';

export const UPDATE_HISTORY_OPERATION_PENDING =
  'UPDATE_HISTORY_OPERATION_PENDING';

export const GET_HISTORY_JOURNAL_PENDING = 'GET_HISTORY_JOURNAL_PENDING';
export const GET_HISTORY_JOURNAL_SUCCESS = 'GET_HISTORY_JOURNAL_SUCCESS';

export const GET_HISTORY_POPUP_PENDING = 'GET_HISTORY_POPUP_PENDING';
export const GET_HISTORY_POPUP_SUCCESS = 'GET_HISTORY_POPUP_SUCCESS';

export const HISTORY_JOURNAL_LOAD_MORE_PENDING =
  'HISTORY_JOURNAL_LOAD_MORE_PENDING';
export const SET_CUSTOM_HISTORY_FILTER = 'SET_CUSTOM_HISTORY_FILTER';
export const SET_HISTORY_FILTERS_PERIOD = 'SET_HISTORY_FILTERS_PERIOD';
export const SET_HISTORY_OPERATION_ID = 'SET_HISTORY_OPERATION_ID';
export const RESET_HISTORY_FILTERS = 'RESET_HISTORY_FILTERS';
export const RESET_HISTORY_CUSTOM_FILTERS = 'RESET_HISTORY_CUSTOM_FILTERS';

const updateHistoryJournal = () => ({
  type: GET_HISTORY_JOURNAL_PENDING,
});

const setCustomFilter = (payload: SetCustomFilterPayload) => ({
  type: SET_CUSTOM_HISTORY_FILTER,
  payload: {
    data: payload.data,
    filterType: payload.filterType,
  },
});

const setOperationId = (data: SetOperationIdPayload) => ({
  type: SET_HISTORY_OPERATION_ID,
  payload: {
    operationId: data.operationId,
  },
});

const setPeriod = (data: SetPeriodPayload) => ({
  type: SET_HISTORY_FILTERS_PERIOD,
  payload: {
    startDate: data.startDate,
    endDate: data.endDate,
    id: data.id,
  },
});

const loadMore = (startIndex: number) => ({
  type: HISTORY_JOURNAL_LOAD_MORE_PENDING,
  payload: { startIndex },
});

const resetFilters = () => ({ type: RESET_HISTORY_FILTERS });

const resetCustomFilters = () => ({ type: RESET_HISTORY_CUSTOM_FILTERS });

const loadHistoryPopupData = () => ({ type: GET_HISTORY_POPUP_PENDING });

const loadDebitHistoryData = (date: number | null) => ({
  type: GET_DEBIT_HISTORY_DATA_PENDING,
  payload: { date },
});

const loadCreditHistoryData = (date: number | null) => ({
  type: GET_CREDIT_HISTORY_DATA_PENDING,
  payload: { date },
});

const deleteHistoryOperation = (data: HistoryActionOperationPayload) => ({
  type: DELETE_HISTORY_OPERATION_PENDING,
  payload: data,
});

const restoreHistoryOperation = (data: HistoryActionOperationPayload) => ({
  type: RESTORE_HISTORY_OPERATION_PENDING,
  payload: data,
});

const updateHistoryOperation = (data: HistoryUpdateOperationPayload) => ({
  type: UPDATE_HISTORY_OPERATION_PENDING,
  payload: data,
});

export default {
  loadMore,
  setPeriod,
  resetFilters,
  setCustomFilter,
  resetCustomFilters,
  updateHistoryJournal,
  loadHistoryPopupData,
  loadDebitHistoryData,
  loadCreditHistoryData,
  deleteHistoryOperation,
  updateHistoryOperation,
  restoreHistoryOperation,
  setOperationId,
};
